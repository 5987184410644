<template>
  <div class="mx-4">
    <v-row class="mb-4">
      <v-col cols="12" lg="3" xl="3">
        <Dialer :callTo="callTo" :class="$vuetify.breakpoint.lgAndUp ? '' : 'd-none'"/>
      </v-col>
      <v-col cols="12" lg="9" xl="9">
        <v-row class="mt-2">
          <v-tabs
            v-model="tab"
            center-active
            show-arrows
          >
            <v-tabs-slider :color="$vuetify.theme.dark ? $env.custom.appBarColor.dark : $env.custom.appBarColor.light"></v-tabs-slider>

            <v-tab key="0"
            >
              Mes messages
            </v-tab>
            <v-tab key="1"
            >
              Mes annonces vocales
            </v-tab>
          </v-tabs>
          
          <v-tabs-items v-model="tab" style="background-color: #ffffff00; width: 100%;" class="mt-4">
            <v-tab-item key="0">
              <VoicemailList />
            </v-tab-item>
            <v-tab-item key="1">
              <GreetingsList />
            </v-tab-item>
          </v-tabs-items>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Dialer from '@/components/Dialer'
import VoicemailList from './components/VoicemailList'
import GreetingsList from './components/GreetingsList'

export default {
  name: 'Voicemail',

  components: { Dialer, VoicemailList, GreetingsList },

  data: function() {
    return {
        tab: null,
        callTo: null
    }
  },
  methods: {
      
  },
  mounted: async function() {
  }
}
</script>
<style>

</style>
