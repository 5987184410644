<template>
  <div class="mx-4">
    <v-row class="mb-4">
      <v-col cols="12" lg="3" xl="3">
        <Dialer :callTo="callTo" :class="$vuetify.breakpoint.lgAndUp ? '' : 'd-none'"/>
      </v-col>
      <v-col cols="12" lg="9" xl="9">
        <PhoneBook :updateFavorite="updateFavorite" @call="onCallClick" @message="onMessageClick" @favorite="onFavoriteClick" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Dialer from '@/components/Dialer'
import PhoneBook from './components/PhoneBook'

export default {
  name: 'Home',

  components: { Dialer, PhoneBook },

  data: function() {
    return {
      callTo: null,
      updateFavorite: 0
    }
  },
  methods: {
    onCallClick(num) {
      this.callTo = num;
    },
    onMessageClick(user) {
      const user_id = user.shortNum + '_' + user.user_id;
      this.$router.push({ path: 'chat', query: { id: user_id } });
    },
    onFavoriteClick(user) {
      const me = this;
      this.$store.commit('app/mask');
      this.globalStore.api.Users.setFavorite(user.sipExtension, (user.favorite ? false : true))
        .then((response) => {
          if (response.success) {
            user.favorite = (user.favorite ? false : true);
            me.updateFavorite = new Date().getTime();
          }
          else { 
            throw new Error(); 
          }
        })
        .catch(() => { this.error = true; })
        .finally(() => { this.$store.commit('app/unmask'); });
    }
  }
}
</script>
<style>

  .v-text-field > .v-input__control > .v-input__slot:after {
    border-color: black !important;
    border-bottom-color: black !important;
  }
  
  div.v-input__slot > div > input,
  div.v-input__slot > div > label {
    font-size: 14px;
  }
</style>