<template>
    <v-dialog
        v-model="show"
        persistent
        width="500"
    >
        <v-card>
            <v-card-title class="headline">
                Choisir un raccourci pour lancer un appel
            </v-card-title>
            
            <div class="text-center">Vous avez choisi </div>
            <div class="text-center"><h1><i><b>{{ modifier ? modifier + ' + ' : '' }} {{ key ? key : '' }}</b></i></h1></div>
            <div class="text-center mb-4">comme raccourci pour lancer un appel</div>
            
            <v-divider></v-divider>

            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="error"
                text
                @click="close"
            >
                Annuler
            </v-btn>
            <v-btn
                :disabled="!key"
                color="primary"
                text
                @click="save"
            >
                Sauvegarder
            </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'Binding',
    props: {
        show: {
            default: false
        }
    },
    watch: {
        show: function(newV) {
            if (newV) {
                document.addEventListener('keydown', this.lisen, false);
            }
        }
    },
    data: function() {
        return {
            modifier: null,
            key: null
        }
    },
    methods: {
        close: function() {
            this.reset();
            this.$emit("close");
        },
        reset: function() {
            document.removeEventListener("keydown", this.lisen, false)
        },
        lisen: function(event) {
            event.preventDefault();
            event.stopPropagation();

            const key = event.key;
            if (key === 'Control' || key === 'Alt' || key === 'Shift') {
                // Pas d'alerte si seule la touche Control est pressée.
                return;
            }

            this.modifier = event.altKey ? 'ALT' : event.ctrlKey ? 'CTRL' : event.shiftKey ? 'SHIFT' : null;
            this.key = key;
        },
        save: function() {
            this.reset();
            this.$emit('save', { modifier: this.modifier, key: this.key});
            this.$emit("close");
        }
    }
}
</script>