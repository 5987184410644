<template>
<v-container>
  <v-row class="mt-2 px-2">
      <v-tabs
        v-model="tab"
        center-active
        show-arrows
      >
        <v-tabs-slider :color="$vuetify.theme.dark ? $env.custom.appBarColor.dark : $env.custom.appBarColor.light"></v-tabs-slider>

        <v-tab key="0"
        >
          Général
        </v-tab>

        <v-tab key="1" v-if="isCordova"
        >
          Importer mes contacts
        </v-tab>

        <v-tab key="2"
        >
          Diagnostic
        </v-tab>

        <v-tab key="3"
        >
          Support
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab" style="background-color: #ffffff00; width: 100%;" class="mt-4">
        <v-tab-item key="0">
          <General />
        </v-tab-item>
        <v-tab-item key="1" v-if="isCordova">
          <ImportContact />
        </v-tab-item>
        <v-tab-item key="2">
          <Diagnostic />
        </v-tab-item>
        <v-tab-item key="3">
          <Support />
        </v-tab-item>
      </v-tabs-items>
  </v-row>
  </v-container>
</template>

<script>

import General from './components/General';
import ImportContact from './components/ImportContact';
import Diagnostic from './components/Diagnostic';
import Support from './components/Support';

import { mapGetters } from 'vuex'

export default {
  name: 'Settings',

  computed: {
    ...mapGetters({
      isCordova: 'app/isCordova',
    })
  },

  components: { General, ImportContact, Diagnostic, Support },

  data: function() {
    return {
      tab: null
    }
  }
}
</script>
<style>
</style>