<template>
  <v-row class="mt-2">
    <v-tabs
      v-model="tab"
      center-active
      show-arrows
    >
      <v-tabs-slider :color="$vuetify.theme.dark ? $env.custom.appBarColor.dark : $env.custom.appBarColor.light"></v-tabs-slider>

      <v-tab
        v-for="group in groups"
        :key="group.name"
      >
        {{ group.name }}
      </v-tab>
    </v-tabs>

    <v-text-field
      v-model="search"
      append-icon="mdi-magnify"
      label="Rechercher un utilisateur"
      class="mx-4"
      hide-details
      single-line
    ></v-text-field>

    <v-tabs-items v-model="tab" style="background-color: #ffffff00; width: 100%;" class="mt-4">
      <v-tab-item
        v-for="group in groups"
        :key="group.name"
      >
        <v-row class="px-5 pb-10">
          <v-col cols="6" xs="4" sm="4" md="3" lg="3" xl="2" v-for="user in groupUser(group)" :key="user.shortNum + '_' + user.user_id">
            <UserCard :loading="false" :user="user" @call="onPhoneClick" @message="onMessageClick" @favorite="onFavoriteClick" />
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </v-row>
</template>

<script>
  import UserCard from './UserCard';

  import { mapGetters } from 'vuex'

  export default {
    name: 'PhoneBook',
    
    components: { UserCard },

    computed: {
      ...mapGetters({
        loading: 'contacts/getLoading',
        contacts: 'contacts/getAll',
        groups: 'contacts/getGroups',
      }),
      groupUser() {
        return group => {
          return group.users.filter(
            el => 
              el.name.toLowerCase().includes(this.search.toLowerCase())
              || el.shortNum.toLowerCase().includes(this.search.toLowerCase())
          );
        }
      }
    },

    props: {
      updateFavorite: {
        default: false
      }
    },
    data: function() {
      return {
        tab: null,
        search: ""
      }
    },
    watch: {
      'updateFavorite': function() {
        // Chargement des stores
        this.$store.dispatch('contacts/updateFavorite');
      }
    },
    methods: {
      onPhoneClick: function(shortNum) {
        this.$emit("call", shortNum);
        setTimeout(() => { this.$emit("call", null); }, 500) // Pour reset le fonctionnement du watch
      },

      onMessageClick: function(user) {
        this.$emit("message", user);
      },

      onFavoriteClick: function(user) {
        this.$emit("favorite", user);
      },
    }
  }
</script>
<style>
  .v-image__image--preload {
    filter: unset;
  }
</style>
