<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon :color="$vuetify.theme.dark ? $env.custom.appBarBtnColor.dark : $env.custom.appBarBtnColor.light" @click="onClick" v-bind="attrs" v-on="on">
        <v-icon>mdi-phone-log</v-icon>
      </v-btn>
    </template>
    <span>Journal</span>
  </v-tooltip>
</template>

<script>

export default {
  name: "LogButton",

  methods: {
    onClick() {
      this.$router.push({ path: 'log', query: { new: true } });
    }
  }
};
</script>