<template>
  <v-progress-linear
    v-if="getLoading"
    :height="5"
    indeterminate
    color="loading"
  ></v-progress-linear>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "LoadingBar",

  computed: {
      ...mapGetters({
          getLoading: 'app/getLoading'
      })
  },
};
</script>
<style>

</style>