<template>
<v-container fluid :style="$vuetify.breakpoint.mdAndUp ? 'display: grid; grid-template-columns: 1fr 1fr; grid-template-rows: 1fr 1fr;' : ''">
    <v-data-iterator v-if="$vuetify.breakpoint.mdAndUp || ($vuetify.breakpoint.smAndDown && view == 0)" class="mx-2"
      :items="data"
      :items-per-page="itemsPerPage"
      :page="page"
      :search="search"
      hide-default-footer
    >
      <template v-slot:header>
        <v-toolbar
          class="mb-1"
        >
          <v-text-field
            v-model="search"
            flat
            solo-inverted
            hide-details
            prepend-inner-icon="mdi-magnify"
            label="Rechercher un utilisateur"
          ></v-text-field>
        </v-toolbar>
      </template>

      <template v-slot:default="{items}">
          <v-list subheader style="width: -webkit-fill-available;">
            <v-list-item-group v-model="selectedListUser">
              <v-list-item
                v-for="item in items"
                :key="item.contactId"
                @click="onClickContact(item)"
              >
                <v-list-item-icon>
                  <v-icon>
                    mdi-card-account-details-outline
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ getName(item) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
      </template>
      <template v-slot:footer>
        <v-row
          class="mt-2"
          align="center"
          justify="center"
        >
          <v-spacer></v-spacer>

          <span
            class="mr-4
            grey--text"
          >
            Page {{ page }} sur {{ numberOfPages }}
          </span>
          <v-btn
            fab
            dark
            :disabled="page <= 1"
            class="mr-1"
            @click="formerPage"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn
            fab
            dark
            :disabled="page >= numberOfPages"
            class="ml-1"
            @click="nextPage"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-row>
        <v-row class="mt-4 mr-1">
          <v-spacer></v-spacer>
          <span>{{ totalContacts }} contact(s)</span>
        </v-row>
      </template>
    </v-data-iterator>
    
    <div class="mx-2">
      <DirectoryBlock
        v-if="view == 1 && edit == false"
        :user="selectedUser"
        @back="onClickBackBtn"
        @edit="onClickEditBtn"
        @call="onContactCallClick"
        @delete="onContactDeleteClick"
      />
      <DirectoryEditBlock v-else-if="view == 1 && edit"
        :user="editUser"
        @back="onClickEditBackBtn"
        @validate="onClickValidateBtn"
      />
    </div>
  </v-container>
</template>

<script>
import DirectoryBlock from './DirectoryBlock.vue'
import DirectoryEditBlock from './DirectoryEditBlock.vue'

import { mapGetters } from 'vuex'

import { EventBus } from '@/event-bus';

export default {
  components: { DirectoryBlock, DirectoryEditBlock },
  name: 'MobileDirectoryList',
  data: function() {
    return {
      view: 0,
      edit: false,
      itemsPerPage: 10,
      page: 1,
      search: "",
      selectedListUser: null,
      selectedUser: null
    }
  },
  
  computed: {
    numberOfPages () {
      const page = this.mobileDirectory.length / this.itemsPerPage;
      return Math.ceil(page <= 1 ? 1 : page);
    },
    ...mapGetters({
      mobileDirectory: 'directory/getMobileDirectory'
    }),
    totalContacts () {
      return this.mobileDirectory.length
    },
    data() {
      return this.mobileDirectory;      
    }
  },
  
  methods: {
    onClickContact(user) {
      this.view = 1;
      this.edit = false;
      this.selectedUser = user;
    },

    onClickBackBtn() {
      this.view = 0;
      this.edit = false;
      this.selectedUser = null;
    },

    onClickEditBtn() {
      this.view = 1;
      this.edit = true;
      this.editUser = {...this.selectedUser};
    },

    onClickEditBackBtn() {
      this.edit = false;
    },

    onContactCallClick(num) {
      EventBus.$emit('launchCall', num);
    },

    onContactDeleteClick() {
      console.log('delete');
    },

    onClickValidateBtn() {

    },

    getName(contact) {
      let name = "";

      if (contact.name) {
        name += contact.name;
      }

      if (contact.surname) {
        name += name == "" ? contact.surname : " " + contact.surname;
      }

      if (contact.company) {
        name += name == "" ? contact.company : " " + contact.company;
      }

      return name;
    },

    formerPage() {
      this.page = this.page <= 1 ? this.page : this.page - 1;
    },

    nextPage() {
      this.page = this.page >= this.numberOfPages ? this.page : this.page + 1;
    }
  }
}
</script>