<template>
  <div class="mx-4">
    <v-row class="mb-4">
      <v-col cols="12" lg="3" xl="3">
        <Dialer :callTo="callTo" :class="$vuetify.breakpoint.lgAndUp ? '' : 'd-none'"/>
      </v-col>
      <v-col cols="12" lg="9" xl="9" class="mt-4">
        <v-card v-for="dialGroup in dialGroups" :key="dialGroup.dialGroupId" :class="`elevation-6`" class="transition-swing my-4">
          <v-card-title class="pb-0" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
            <span style="max-width: 80%;">{{ dialGroup.companyName }} - {{ dialGroup.didName }}</span>
            <v-spacer ></v-spacer>
            <small>{{ dialGroup.members.length }} membre(s)</small>
          </v-card-title>
          <v-card-actions class="pt-0">
            <v-btn class="" :to="'/dialgroups/' + dialGroup.dialGroupId">
              <v-icon>mdi-cog</v-icon>
              Editer le groupe
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Dialer from '@/components/Dialer'
import { mapGetters } from 'vuex'

export default {
  name: 'DialGroups',

  components: { Dialer },
  
  computed: {
    ...mapGetters({
        dialGroups: 'dialGroups/getDialGroups'
    })
  },
  
  data: function() {
    return {
      callTo: null,
    }
  },
  
  methods: {
    
  },

  mounted() {
    // Chargement des groupes
    this.$store.dispatch('dialGroups/getAllDialGroups');
  }
  
}
</script>
<style>

</style>