<template>
  <v-card class="mx-auto">
    <v-card-title class="pb-0">
      <v-btn
        v-if="$vuetify.breakpoint.smAndDown"
        @click="onBackClick()"
        class="mb-4"
      >
        <v-icon color="primary">mdi-chevron-left</v-icon>
        Retour
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        v-if="user ? user.type == 'personal' : false"
        @click="onClickEdit"
      >
        Modifier
        <v-icon right color="primary">mdi-pencil</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-title class="center-x pt-0">
      <div class="text-h5">
        {{ user ? user.surname + " " + user.name : null }}
      </div>
    </v-card-title>
    <v-card-subtitle class="center-x">{{ user ? user.company : null }}</v-card-subtitle>
    <v-list two-line>
      <DirectoryBlockField 
        icon="mdi-phone"
        :value="getField('tel')"
        name="Téléphone"
        :callNumber="user.tel"
      />

      <DirectoryBlockField 
        icon="mdi-phone"
        :value="getField('mob')"
        name="Mobile"
        :callNumber="user.mob"
      />

      <DirectoryBlockField 
        icon="mdi-phone"
        :value="getField('otherTel')"
        name="Autres"
        :callNumber="user.otherTel"
      />

      <v-divider inset></v-divider>

      <DirectoryBlockField 
        icon="mdi-email"
        :value="getField('email')"
        name="E-Mail"
      />

      <v-divider inset></v-divider>

      <DirectoryBlockField 
        icon="mdi-information-outline"
        :value="getField('fax')"
        name="Fax"
      />

      <DirectoryBlockField 
        icon="mdi-information-outline"
        :value="getField('web')"
        name="Web"
      />
    </v-list>
  </v-card>
</template>

<script>
import { EventBus } from '@/event-bus';
import { mapGetters } from 'vuex'
import DirectoryBlockField from './DirectoryBlockField.vue';

export default {
  name: 'DirectoryBlock',
  computed: {
    ...mapGetters({
      getContactByShortNum: 'contacts/getContactByShortNum'
    })
  },
  components: { DirectoryBlockField },
  props: {
    user: {
      default: () => { 
        return {
          contactId: "",
          readOnly: "",
          extension: "",
          type: "",
          name: "",
          surname: "",
          company: "",
          email: "",
          web: "",
          url: "",
          tel: "",
          mob: "",
          home: "",
          otherTel: "",
          otherTel2: "",
          fax: ""
        }
      }
    }
  },
  watch: {
    user: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.w3telUser = this.getContactByShortNum(newVal.otherTel);
        }
      },
    }
  },
  data: function() {
    return {
      w3telUser: null
    }
  },
  methods: {
    getField(name) {
      if (this.user) {
        return this.user[name] != "" ? this.user[name] : "";
      }

      return "";
    },
    getW3TelUserStatus() {
      if (this.w3telUser) {
        return this.w3telUser.status;
      }

      return 0;
    },
    call(num) {
      EventBus.$emit('launchCall', num);
    },
    onBackClick() {
      this.$emit("back");      
    },
    onCallClick() {
      this.$emit("call", this.user.otherTel);      
    },
    onClickEdit() {
      this.$emit("edit", this.user);
    },
    onDeleteClick() {
      this.$emit("delete", this.user);
    }
  }
}
</script>