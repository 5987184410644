import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import fr from 'vuetify/es5/locale/fr'
import env from '../../config/env';

Vue.use(Vuetify);

Vue.component('v-data-table', {
  methods: {
    changeLocale () {
      this.$vuetify.lang.current = 'fr'
    },
  },
})

export default new Vuetify({
    lang: {
      locales: { fr },
      current: 'fr',
    },
    theme: {
      options: {
        customProperties: true
      },
      themes: env.config.themes,
    },
});
