<template>
  <v-hover>
    <template v-slot:default="{ hover }">
      <v-card :disabled="loading" :class="`elevation-${hover ? 12 : 6}`" class="transition-swing" :style="user.status == 2 ? 'border: 2px solid red;' : ''">
        <v-card-title v-if="$vuetify.breakpoint.mdAndUp" class="pb-0" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
          <span style="max-width: 80%;" :style="user.name.length > 20 ? 'font-size: 9px' : user.name.length > 14 ? 'font-size: 14px' : ''">{{ user.name }}</span>
          <v-spacer ></v-spacer>
          <small>{{ user.shortNum }}</small>
        </v-card-title>
        <v-card-title v-else class="pb-0" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
          <span style="width: 100%;" :style="user.name.length > 25 ? 'font-size: 9px' : user.name.length > 10 ? 'font-size: 14px' : ''">{{ user.name }}</span>
          <small>{{ user.shortNum }}</small>
        </v-card-title>
        <v-card-actions class="pt-0 pl-0">
          <v-btn icon class="ml-0 px-0 pl-2" style="width: 47px;" x-large @click="onPhoneClick()" :color="getContactStatusColor(user)">
            <v-icon>mdi-phone</v-icon>
          </v-btn>
          <v-btn v-if="isChatEnabled" icon class="ml-0 px-0" style="width: 47px;" x-large @click="onMessageClick()" :color="getContactChatStatusColor(user)">
            <v-icon>mdi-android-messages</v-icon>
          </v-btn>
          <v-btn icon class="ml-0 px-0" style="width: 47px;" x-large @click="onFavoriteClick()">
            <v-icon>{{ user.favorite ? "mdi-star" : "mdi-star-outline" }}</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-hover>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'UserCard',

    computed: {
      ...mapGetters({
        isChatEnabled: 'app/isChatEnabled',
        getContactStatusColor: 'contacts/getContactStatusColor',
        getContactChatStatusColor: 'contacts/getContactChatStatusColor'
      })
    },

    props: {
      user: {
        type: Object,
        default: () => { 
          return {
            name:"",
            shortNum: "",
            status: 0,
            chat_status: 0
          }
        }
      },
      loading: {
        type: Boolean,
        default: false
      }
    },
    
    methods: {
      onPhoneClick: function() {
        this.$emit("call", this.user.shortNum);
        setTimeout(() => { this.$emit("call", null); }, 500) // Pour reset le fonctionnement du watch
      },

      onMessageClick: function() {
        this.$emit("message", this.user);
      },

      onFavoriteClick: function() {
        this.$emit("favorite", this.user);
      },

      getStatusText: function() {
        switch (this.user.status) {
          case 2:
            return "En discussion";
          case 1:
            return "Disponible";
          case 0:
          default:
            return "Hors ligne";
        }
      },

      getChatStatusText: function() {
        return this.user.chat_status ? "En ligne" : "Hors ligne";
      }
    }
  }
</script>