import http from "@/log-http-common";

class LogServices {
  createLog(user_id, title, data) {
    return new Promise((resolve, reject) => {
      const url = 'log';

      return http.put(url, {
        user_id, title, data
      })
      .then((response) => {
        const { data } = response;
        
        if(data) {
          resolve(data);
        }
        else {
          reject(false);
        }
      })
      .catch((e) => {
        console.error(e);
        reject(e);
      });
    });
  }

  createUser(token, user_id, email, useOTP) {
    return new Promise((resolve, reject) => {
      const url = 'user';

      return http.put(url, {
        token, user_id, email, useOTP 
      })
      .then((response) => {
        const { data } = response;
        
        if(data) {
          resolve(data);
        }
        else {
          reject(false);
        }
      })
      .catch((e) => {
        console.error(e);
        reject(e);
      });
    });
  }

  updateUser(user_id, email, name, picture, token, api_url, company_logo, partner_logo, useOTP) {
    return new Promise((resolve, reject) => {
      const url = 'user/' + user_id;

      return http.post(url, {
        email, name, picture, token, api_url, company_logo, partner_logo, useOTP
      })
      .then((response) => {
        const { data } = response;
        
        if(data) {
          resolve(data);
        }
        else {
          reject(false);
        }
      })
      .catch((e) => {
        console.error(e);
        reject(e);
      });
    });
  }
}

export default new LogServices();