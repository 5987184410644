<template>
  <div class="mx-4">
    <v-row class="mb-4">
      <v-col cols="12" lg="3" xl="3">
        <Dialer :callTo="callTo" :class="$vuetify.breakpoint.lgAndUp ? '' : 'd-none'"/>
      </v-col>
      <v-col cols="12" lg="9" xl="9" class="mt-4">
        <v-card>
          <v-card-title>
            <v-btn to="/dialgroups" icon fab small class="mr-2">
              <v-icon color="primary">mdi-arrow-left</v-icon>
            </v-btn>
            {{ dialGroupTitle }}
          </v-card-title>
        </v-card>
        <v-row>
          <v-col cols="12" lg="3" xl="3">
            <v-card class="mt-4">
              <v-card-title>Membres du groupe ({{ dialGroupMember.length }})</v-card-title>
              <v-list>
                <v-list-item-group
                  v-model="selectedMemberItem"
                  color="primary"
                  style="height: 45vh; overflow: auto;"
                >
                  <v-list-item
                    v-for="(member, i) in dialGroupMember"
                    :key="i"
                  >
                    <v-list-item-content>
                      <v-list-item-title>{{ member.name }} ({{ member.shortNum}})</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn icon @click="onRemoveClick(member)">
                        <v-icon color="primary">mdi-account-multiple-remove</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-col>
          <v-col cols="12" lg="3" xl="3">
            <v-card class="mt-4">
              <v-card-title>Non-membres du groupe ({{ dialGroupNonMember.length }})</v-card-title>
              <v-list>
                <v-list-item-group
                  v-model="selectedNonMemberItem"
                  color="primary"
                  style="height: 45vh; overflow: auto;"
                >
                  <v-list-item
                    v-for="(member, i) in dialGroupNonMember"
                    :key="i"
                  >
                    <v-list-item-action>
                      <v-btn icon>
                        <v-icon color="primary" @click="onAddClick(member)">mdi-account-multiple-plus</v-icon>
                      </v-btn>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>{{ member.name }} ({{ member.shortNum}})</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Dialer from '@/components/Dialer'
import { mapGetters } from 'vuex'

export default {
  name: 'DialGroup',

  components: { Dialer },
  
  computed: {
    ...mapGetters({
        dialGroups: 'dialGroups/getDialGroups'
    }),
    dialGroup: function() {
      return this.dialGroups.find(dialGroup => dialGroup.dialGroupId == this.$route.params.uuid);
    },
    dialGroupTitle: function() {
      return this.dialGroup ? this.dialGroup.companyName + " - " + this.dialGroup.didName : "";
    },
    dialGroupMember: function () {
      return this.dialGroup ? this.dialGroup.members : [];
    },
    dialGroupNonMember: function () {
      return this.dialGroup ? this.dialGroup.nonMembers : [];
    }
  },
  
  data: function() {
    return {
      callTo: null,
      selectedMemberItem: null,
      selectedNonMemberItem: null
    }
  },
  
  methods: {
    onRemoveClick: function(member) {
      this.$store.dispatch('dialGroups/removeMember', { dialGroup: this.dialGroup, member});
    },

    onAddClick: function(member) {
      this.$store.dispatch('dialGroups/addMember', { dialGroup: this.dialGroup, member});
    },
  },

  mounted() {
    // Chargement des groupes si c'est vide
    if (this.dialGroups.length <= 0) {
      this.$store.dispatch('dialGroups/getAllDialGroups');
    }
  }
}
</script>
<style>

</style>