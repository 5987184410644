<template>
    <div class="mx-4">
        <v-row class="mb-4">
            <v-col cols="12" lg="12" xl="12">
                <v-card>
                    <v-card-title>Demande de support</v-card-title>
                    <v-card-subtitle>Vous souhaitez faire une demande de support car vous avez un problème avec Compagnon ?</v-card-subtitle>
                    <v-card-text>
                        <v-text-field
                            v-model="user_title"
                            label="Nom et prénom"
                            required
                        ></v-text-field>
                        <v-text-field
                            v-model="user_email"
                            label="E-mail"
                            required
                        ></v-text-field>
                        <v-text-field
                            v-model="user_phone"
                            type="tel"
                            label="Numéro de téléphone"
                            required
                        ></v-text-field>
                        <v-textarea
                            v-model="description"
                            auto-grow
                            outlined
                            name="Décrivez nous votre problème :"
                            label="Décrivez nous votre problème :"
                        ></v-textarea>
                        <v-btn color="green" dark block @click="send">Envoyer votre demande</v-btn>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
    name: 'Support',

    computed: {
        ...mapGetters({
            getUser: 'app/getUser',
        })
    },

    data: function() {
        return {
            user_email: "",
            user_phone: "",
            user_title: "",
            description: null,
            log: ""
        }
    },
    methods: {
        send: function() {
            if (this.user_email.length < 5 || !this.validateEmail(this.user_email)) {
                this.$notify({
                    group: 'app',
                    type: 'error',
                    title: 'Support',
                    text: 'L\'adresse e-mail n\'est pas valide.'
                });
                return;
            }
            else if (this.user_phone.length < 3) {
                this.$notify({
                    group: 'app',
                    type: 'error',
                    title: 'Support',
                    text: 'Le numéro de téléphone n\'est pas valide.'
                });
                return;
            }
            else if (this.user_title.length < 2) {
                this.$notify({
                    group: 'app',
                    type: 'error',
                    title: 'Support',
                    text: 'Votre nom/prénom n\'est pas valide.'
                });
                return;
            }
            else if (this.description.length < 20) {
                this.$notify({
                    group: 'app',
                    type: 'error',
                    title: 'Support',
                    text: 'La description doit faire minimum 20 caractères.'
                });
                return;
            }
            else {
                // Création de l'objet de log
                this.log = {
                    internet: window.navigator.onLine,
                    extranet: true,
                    authData: this.getUser
                };

                // Test de l'API
                this.$store.dispatch('contacts/getAllContacts')
                .then(response => {
                    if (response.success) {
                        this.log.api = {
                            success: true
                        }
                    }
                    else {
                        throw response;
                    }
                }).catch((error) => {
                    this.log.api = {
                        success: false,
                        error: error
                    }
                }).finally(() => {
                    this.$http.post(this.$env.mail.url + "support/", {
                        user_id: this.getUser.id,
                        sipExtension: this.getUser.preference.SIPAccount.sipExtension,
                        w3telToken: this.getUser.token,
                        user_email: this.user_email,
                        user_phone: this.user_phone,
                        user_title: this.user_title,
                        description: this.description.replaceAll("\n", "\r\n"),
                        log: JSON.stringify(this.log)
                    })
                    .then(() => {
                        this.$notify({
                            group: 'app',
                            type: 'success',
                            title: 'Support',
                            text: 'Votre demande de support est envoyée.'
                        });

                        this.description = null;
                        this.log = null;
                    })
                    .catch((error) => {
                        this.$notify({
                            group: 'app',
                            type: 'error',
                            title: 'Support',
                            text: 'Votre demande de support est en erreur.'
                        });
                        console.error(error);
                    });
                })
            }
        },
        validateEmail: function(email) {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        }
    },
    mounted: function() {
        this.user_email = this.getUser.info.email;
        this.user_title = this.getUser.info.name;
        this.user_phone = this.getUser.preference.SIPAccount.callerNum;
    }
}
</script>
<style>
</style>