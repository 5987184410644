import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login/Login.vue'
import OTP from '../views/Login/OTP.vue'
import Home from '../views/Home/Home.vue'
import Log from '../views/Log/Log.vue'
import Directory from '../views/Directory/Directory.vue'
import Chat from '../views/Chat/Chat.vue'
import Voicemail from '../views/Voicemail/Voicemail.vue'
import Callforwards from '../views/Callforwards/Callforwards.vue'
import DialGroups from '../views/DialGroups/DialGroups.vue'
import DialGroup from '../views/DialGroups/DialGroup.vue'
import Help from '../views/Help/Help.vue';
import Settings from '../views/Settings/Settings.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      guest: true
    }
  },
  {
    path: '/otp',
    name: 'otp',
    component: OTP,
    meta: {
      guest: true
    }
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    meta: {
      requiresAuth: true,
      requiresExperimental: true,
      requiresExperimentalMobileOnly: true
    }
  },
  {
    path: '/voicemail',
    name: 'voicemail',
    component: Voicemail,
    meta: {
      requiresAuth: true,
      requiresExperimental: false,
      requiresExperimentalMobileOnly: true
    }
  },
  {
    path: '/callforwards',
    name: 'callforwards',
    component: Callforwards,
    meta: {
      requiresAuth: true,
      requiresExperimental: true,
      requiresExperimentalMobileOnly: true
    }
  },
  {
    path: '/log',
    name: 'log',
    component: Log,
    meta: {
      requiresAuth: true,
      requiresExperimental: true,
      requiresExperimentalMobileOnly: true
    }
  },
  {
    path: '/directory',
    name: 'directory',
    component: Directory,
    meta: {
      requiresAuth: true,
      requiresExperimental: true,
      requiresExperimentalMobileOnly: true
    }
  },
  {
    path: '/chat',
    name: 'chat',
    component: Chat,
    meta: {
      requiresAuth: true,
      requiresExperimental: true,
      requiresExperimentalMobileOnly: true
    }
  },
  {
    path: '/dialgroups',
    name: 'dialgroups',
    component: DialGroups,
    meta: {
      requiresAuth: true,
      requiresExperimental: false,
      requiresExperimentalMobileOnly: false
    }
  },
  {
    path: '/dialgroups/:uuid',
    name: 'dialgroup',
    component: DialGroup,
    meta: {
      requiresAuth: true,
      requiresExperimental: false,
      requiresExperimentalMobileOnly: false
    }
  },
  {
    path: '/help',
    name: 'help',
    component: Help,
    meta: {
      requiresAuth: true,
      requiresExperimental: false,
      requiresExperimentalMobileOnly: false
    }
  },
  {
    path: '/settings',
    name: 'settings',
    component: Settings,
    meta: {
      requiresAuth: true,
      requiresExperimental: false,
      requiresExperimentalMobileOnly: false
    }
  }
]

const router = new VueRouter({
  routes
})

// Route par défaut
// Par exemple, si le mode experimental est désactivé sur mobile, ce n'est pas home mais voicemail
const isMobile = false; //!!window.cordova;
const isExperimental = localStorage.getItem('experimental') == "true";
//const defaultRoute = isExperimental ? 'home' : isMobile ? 'voicemail' : 'home';
const defaultRoute = localStorage.getItem('lastRoute') ? localStorage.getItem('lastRoute') == "settings" ? "home" : localStorage.getItem('lastRoute') : "home";

router.beforeEach((to, from, next) => {
  const user = JSON.parse(localStorage.getItem('user'));
  // Si la route a besoin d'authentification
  if(to.matched.some(record => record.meta.requiresAuth)) {
    localStorage.setItem('lastRoute', to.name);
    // Si l'user n'est pas auth
    if (user == null || user.token == null) {
      next({ name: 'login'})
    }
    else {
      // Si la route a besoin d'experimental mais que l'utilisateur n'as pas le bon mode
      if (to.meta.requiresExperimental && isExperimental != true) {
        if (to.meta.requiresExperimentalMobileOnly && isMobile != true) {
          next()
        }
        else {
          next({ name: defaultRoute });
        }
      }
      else {
        next()
      }
    }
  // Sinon, si la route est en mode guess
  } else if(to.matched.some(record => record.meta.guest)) {
    // Si l'utilisateur n'est pas auth c'est parfait
    if(user == null || user.token == null){
      next()
    }
    // Sinon il faut afficher la route par défaut
    else{
      next({ name: defaultRoute})
    }
  // Par défaut, on affiche la route par défaut
  } else {
    next({ name: defaultRoute})
  }
})

export default router
