<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="500"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="green"
        dark
        fab
        fixed
        bottom
        right
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>
          mdi-phone
        </v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>Lancer un appel</v-card-title>
      <v-card-text>
        <v-text-field
          v-model="phoneNumber"
          autofocus
          type="tel"
          label="Numéro de téléphone"
          outlined
        ></v-text-field>
        <v-row class="center-x">
          <v-btn
            class="ma-2"
            :class="$env.custom.societyBtnClass"
            outlined
            large
            fab
            @click="call"
          >
            <v-icon>mdi-phone</v-icon>
          </v-btn>
          <v-btn
            class="ma-2"
            :class="$env.custom.societyBtnClass"
            outlined
            large
            fab
            @click="close"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { EventBus } from '@/event-bus';

export default {
  name: 'Numerator',
  data: function() {
    return {
      dialog: false,
      phoneNumber: null
    }
  },
  methods: {
    // Demande de call vers le serveur
    call: function() {
      EventBus.$emit('launchCall', this.phoneNumber);
      this.close();
    },
    close: function() {
      this.phoneNumber = null;
      this.dialog = false;
    }
  }
}
</script>
<style>

</style>