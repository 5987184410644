import OccicomAPI from 'occicom-w3tel-api-wrapper'
import env from '../../../config/env';

const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {token: null, id: null};
const api = new OccicomAPI("v1", user.token, user.id, env.config.api.url + "voip/v1/", env.config.api.AppName, env.config.api.AppPlatform, env.config.api.AppKey, env.config.api.AppVersion);

// initial state
const state = () => ({
    dialGroups: [],
    routingTypes: []
});

// getters
const getters = {
    getDialGroups: (state) => {
        return state.dialGroups;
    },
    getRoutingTypes: (state) => {
        return state.routingTypes;
    },
}

// actions
const actions = {
    getAllDialGroups ({ commit }) {
        return new Promise((resolve, reject) => {
            try {
                api.DialGroups.getAll(user.preference.SIPAccount.sipExtension, 0, 1000)
                .then((response) => {
                    if (response.success) {
                        commit('setDialGroups', response.dialGroups);
                        commit('setRoutingTypes', response.routingTypes);
                        resolve({ success: true });
                    }
                }).catch((error) => {
                    reject({ success: false, error: error });
                });
            } catch (error) {
              reject({ success: false, error: error });
            }
        });
    },

    addMember ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            try {
                const { dialGroup, member } = payload;

                const members = [...dialGroup.members];
                members.push(member);

                api.DialGroups.update(user.preference.SIPAccount.sipExtension, dialGroup.dialGroupId, dialGroup.routingTypeId, members)
                .then((response) => {
                    if (response.success) {
                        commit('addMember', {dialGroupId: dialGroup.dialGroupId, member});
                        resolve({ success: true });
                    }
                }).catch((error) => {
                    reject({ success: false, error: error });
                });
            } catch (error) {
              reject({ success: false, error: error });
            }
        });
    },

    removeMember ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            try {
                const { dialGroup, member } = payload;

                let members = [...dialGroup.members];
                members = members.filter(_member => _member != member);

                api.DialGroups.update(user.preference.SIPAccount.sipExtension, dialGroup.dialGroupId, dialGroup.routingTypeId, members)
                .then((response) => {
                    if (response.success) {
                        commit('removeMember', {dialGroupId: dialGroup.dialGroupId, member});
                        resolve({ success: true });
                    }
                }).catch((error) => {
                    reject({ success: false, error: error });
                });
            } catch (error) {
              reject({ success: false, error: error });
            }
        });
    }
}

// mutations
const mutations = {
    setDialGroups: (state, data) => {
        state.dialGroups = data;
    },

    setRoutingTypes: (state, data) => {
        state.routingTypes = data;
    },

    addMember: (state, payload) => {
        const { dialGroupId, member } = payload;
        const dialGroup = state.dialGroups.find(dialGroup => dialGroup.dialGroupId == dialGroupId);
        
        if (dialGroup) {
            dialGroup.members.push(member);
            dialGroup.nonMembers = dialGroup.nonMembers.filter(_member => _member != member);
        }
    },

    removeMember: (state, payload) => {
        const { dialGroupId, member } = payload;
        
        const dialGroup = state.dialGroups.find(dialGroup => dialGroup.dialGroupId == dialGroupId);
        if (dialGroup) {
            dialGroup.members = dialGroup.members.filter(_member => _member != member);
            dialGroup.nonMembers.push(member);
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}