<template>
  <v-container>
    <v-card
      class="mx-auto mt-4 pt-4"
      max-width="400"
    >
      <v-img
        class="mx-4"
        src="@/assets/logo.png"
      >
      </v-img>
      <v-card-title>Recevoir mon mot de passe : </v-card-title>

      <v-card-text class="text--primary pb-2">
        <v-text-field
          v-model="email"
          outlined
          label="Adresse e-mail"
          prepend-inner-icon="mdi-account"
          hide-details=true
          @keyup="onEmailChanged"
        ></v-text-field>
        <v-btn outlined block class="mt-4" :class="$env.custom.societyBtnClass" @click="sendOTP">Recevoir mon mot de passe</v-btn>
        <a :href="$env.society.supportUrl" target="_blank" class="center-x mt-2">Voulez-vous contacter le support {{ $env.society.name }} ?</a>
      </v-card-text>
    </v-card>
    <v-card
      class="mx-auto mt-4"
      width="400"
    >
      <v-btn to="/login" outlined block :class="$env.custom.societyBtnClass">Revenir sur l'accueil</v-btn>
    </v-card>
    <!-- Popup success-->
    <v-dialog
        v-model="success"
        transition="dialog-top-transition"
        persistent
      >
        <v-card>
          <v-toolbar
            color="success"
            dark
          >Votre code vous a été envoye par e-mail</v-toolbar>
          <v-card-text class="pb-0 pt-2">
            <p class="mt-2 mb-0">Un e-mail a été envoyé à votre adresse électronique. L'e-mail contient votre code de vérification.</p>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
              text
              @click="closeSuccess"
            >Fermer</v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: 'Login',
  components: {

  },
  data: function() {
    return {
      email: localStorage.getItem('lastEmail') ? localStorage.getItem('lastEmail') : null,
      mask: false,
      error: false,
      success: false
    }
  },
  methods: {
    onEmailChanged: function() {
      localStorage.setItem('lastEmail', this.email);
    },
    // Demande d'envoi du mail qui contiendra le jeton OTP de l'utilisateur
    sendOTP: function() {
      this.$store.commit('app/mask');

      this.onEmailChanged();
      
      this.globalStore.api.AppData.sendOTP(this.email)
        .then((response) => { if (response.success) { this.success = true; } else { throw new Error(); }})
        .catch((err) => { 
          this.$store.commit('app/setError', {
            code: "0014",
            message: "sendOTP " + (err ? err.name : "")
          });
        })
        .finally(() => { this.$store.commit('app/unmask'); });
    },
    closeSuccess: function() {
      this.success = false;
      this.$router.push("login");
    },
    closeError: function() {
      this.error = false;
    }
  }
}
</script>
