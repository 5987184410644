<template>
    <div class="mx-4">
        <v-row class="mb-4">
            <v-col cols="12" lg="3" xl="3">
                <v-btn 
                    class="mb-2"
                    color="success"
                    block
                    :loading="running"
                    :disabled="running"
                    @click="run">
                        Lancer le diagnostic
                </v-btn>
                <v-stepper
                    v-model="actualStep"
                    vertical
                >
                    <v-stepper-step
                        step="1"
                        :complete="internet == 1"
                        :rules="[() => internet == -1 ? false : true]"
                    >
                        Internet
                    </v-stepper-step>
                    <v-stepper-step
                        step="2"
                        :complete="extranet == 1"
                        :rules="[() => extranet == -1 ? false : true]"
                    >
                        Extranet
                    </v-stepper-step>
                    <v-stepper-step
                        step="3"
                        :complete="token == 1"
                        :rules="[() => token == -1 ? false : true]"
                    >
                        Authentification
                    </v-stepper-step>
                    <v-stepper-step
                        step="4"
                        :complete="api == 1"
                        :rules="[() => api == -1 ? false : true]"
                    >
                        API
                    </v-stepper-step>
                </v-stepper>
            </v-col>
            <v-col cols="12" lg="9" xl="9" class="mt-4">
                <v-textarea
                    v-model="logs"
                    readonly
                    auto-grow
                    outlined
                    name="Logs"
                    label="Logs"
                ></v-textarea>
            </v-col>
        </v-row>
    </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'Diagnostic',

  computed: {
    ...mapGetters({
      getUser: 'app/getUser',
    })
  },

  data: function() {
    return {
        running: false,
        actualStep: 0,
        internet: 0,
        extranet: 0,
        token: 0,
        api: 0,
        logs: " "
    }
  },
  methods: {
    run: function() {
        this.running = true;
        this.actualStep = 0;
        this.internet = 0;
        this.extranet = 0;
        this.token = 0;
        this.api = 0;

        this.testInternet();
    },
    testInternet: function() {
        this.actualStep = 1;
        this.logs = "Ping https://www.google.com/...";
        
        if (window.navigator.onLine) {
            this.internet = 1;
            this.logs += "\r\n---- OK";
        }
        else {
            this.logs += "\r\n---- Erreur : " + JSON.stringify("HORS LIGNE");
            this.internet = -1;
        }

        this.testExtranet();
    },
    testExtranet: function() {
        this.actualStep = 2;
        this.logs += "\r\nPing l'extranet...";

        this.extranet = 1;
        this.logs += "\r\n---- OK";
        this.testAuth();
    },
    testAuth: function() {
        this.actualStep = 3;
        this.logs += "\r\nTest de l'authentification...";

        try {
            this.logs +="\r\n---- Récupération de l'utilisateur dans le localstorage...";
            const localStorageUser = localStorage.getItem('user');

            if (localStorageUser) {
                this.logs +="\r\n---- Parse de l'utilisateur...";
                const user = JSON.parse(localStorageUser);

                if (user) {
                    this.logs +="\r\n---- Vérification du token...";
                    if (user.token) {
                        if (this.getUser.token == user.token) {
                            this.token = 1;
                            this.logs +="\r\n---- OK";
                        }
                        else {
                            throw "Token de l'utilisateur local != token vuex";
                        }
                    }
                    else {
                        throw "Token de l'utilisateur introuvable";
                    }
                }
                else {
                    throw "JSON de l'utilisateur imparsable";
                }
            }
            else {
                throw 'Utilisateur introuvable dans le localstorage';
            }
        } catch (error) {
            this.logs += "\r\n---- Erreur : " + JSON.stringify(error);
            this.token = -1;
        }

        this.testAPI();
    },
    testAPI: function() {
        this.actualStep = 4;
        this.logs += "\r\nDemande de BLF à l'API";

        this.$store.dispatch('contacts/getAllContacts')
        .then(response => {
            if (response.success) {
                this.api = 1;
                this.logs += "\r\n---- OK";
            }
            else {
                throw response;
            }
        }).catch((error) => {
            this.logs += "\r\n---- Erreur : " + JSON.stringify(error);
            this.api = -1;
        }).finally(() => {
            this.end();
        })
    },
    end: function() {
        this.running = false;
    }
  }
}
</script>
<style>
</style>