<template>
  <v-dialog
      v-model="getError"
      transition="dialog-top-transition"
    >
      <v-card>
        <v-toolbar
          color="error"
          dark
        >Il y a eu une erreur</v-toolbar>
        <v-card-text class="pb-0 pt-2">
          <p class="mt-2 mb-0">Si le problème persiste, veuillez nous contacter.</p>
          <p v-if="getError.message">Message d'erreur : {{ getError.message }}</p>
          <p v-if="getError.code">Code erreur : {{ getError.code }}</p>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            text
            @click="close"
          >Fermer</v-btn>
        </v-card-actions>
      </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "Error_Popup",
  computed: {
    ...mapGetters({
      getError: 'app/getError',
    })
  },
  methods: {
    close: function() {
      this.$store.commit('app/setError', false);
    }
  },
};
</script>