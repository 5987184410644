<template>
  <v-app id="inspire">
    <AppToolbar v-if="getUser.token != null" :newMessageCounter="newMessageCounter"/>
    
    <LoginAppBar v-else />

    <Navigation :show="getMenu" v-if="getUser.token != null"/>

    <v-main :class="$vuetify.theme.dark ? '' : 'grey lighten-3'" :style="(isCordova || isElectron) ? '' : (isPlugin) ? 'min-width:800px; min-height:600px;' : '' ">
      <LoadingBar />
      <vue-page-transition name="fade-in-up">
        <router-view></router-view>
      </vue-page-transition>

      <v-row class="center-x"><p class="my-4">Version {{globalStore.version}}</p></v-row>
    </v-main>

    <v-dialog v-model="tokenExp" persistent transition="dialog-bottom-transition" max-width="600">
        <v-card>
          <v-toolbar>Votre session a expiré !</v-toolbar>
          <v-card-text>
            <div class="text-h4 mt-6">Veuillez vous reconnecter.</div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
              text
              @click="logout"
            >J'ai compris</v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>

    <Numerator v-if="isCordova && getUser.token != null" />

    <LoadingMask :overlay="getMask" />
    <ErrorPopup :overlay="getError" />
    <notifications position="top center" group="error" />
    <notifications position="bottom right" group="app" />
  </v-app>
</template>

<script>
/* global chrome, browser, FirebasePlugin, device */

import LoadingMask from '@/components/Mask.vue'
import ErrorPopup from '@/components/Error_Popup.vue'
import Navigation from '@/components/Navigation.vue'
import AppToolbar from '@/components/AppToolbar.vue'
import LoginAppBar from '@/components/Electron/Login_AppBar.vue'
import LoadingBar from '@/components/LoadingBar.vue'

import Numerator from '@/components/Numerator.vue';

import { EventBus } from '@/event-bus';

import { mapGetters } from 'vuex'

import * as Sentry from "@sentry/vue";
import Log from '@/services/log.services.js';

export default {
  name: 'App',

  components: { LoadingMask, ErrorPopup, Navigation, AppToolbar, LoginAppBar, LoadingBar, Numerator },

  computed: {
    ...mapGetters({
      getUser: 'app/getUser',
      getChat: 'app/getChat',
      isChatEnabled: 'app/isChatEnabled',
      isCordova: 'app/isCordova',
      isElectron: 'app/isElectron',
      isPlugin: 'app/isPlugin',
      isConverged: 'app/isConverged',
      getMask: 'app/getMask',
      getError: 'app/getError',
      getMenu: 'app/getMenu',
      getVisible: 'app/getVisible'
    })
  },

  sockets: {
      connect: async function () {
        // Seulement si le "chat" est activé dans le mode experimental
        if (this.isChatEnabled) {
          this.$store.commit('app/setChatSocketConnect', true);

          // Connexion entre le serveur de chat et de W3Tel
          await this.$store.dispatch('app/connectChat');

          // Send l'id client au serveur de chat pour faire le lien
          this.$socket.emit('identity', this.getChat.id);
          
          Log.createLog(JSON.parse(localStorage.getItem('user')).id, "connect_chat");
        }
      },
      new_message: async function(message) {
        // Seulement si le "chat" est activé dans le mode experimental
        if (this.isChatEnabled) {
          // Vérification que le message est pas de moi
          if ((this.getChat.id == message.message.postedByUser._id) == false) {
            // Si la route n'est pas "chat", il faut incrementer le nombre de message et dring dring
            if (this.$route.name != "chat") {
              this.newMessageCounter++;

              this.$notify({
                group: 'app',
                title: 'Nouveau Tchat !',
                text: 'Vous avez reçu un nouveau message.'
              });

              this.playChatNotificationSound();
            }

            EventBus.$emit('new_message', message);

            // Send to electron
            if (window.ipcRenderer) {
              window.ipcRenderer.send('new_message', message.message);
            }
          }
        }
      }
  },

  data: () => ({
    mask: false,
    newMessageCounter: 0,
    drawer: false,
    push: false,
    tokenExp: false
  }),

  methods: {
    callNumberFromShortcut: function(numberString) {
      let cleaned = ('' + numberString).replace(/\D/g, ''); // replace les lettres

      if (numberString.includes('+')) {
        cleaned = "+" + cleaned;
      }

      this.call(cleaned);
    },

    call: function(number) {
      if (number == null || number == "" || number == "compagnon_askForDefaultCallTo") { return; }

      this.$store.commit('app/mask');

      const token = this.getUser.token;

      if (token == null) {
        this.$store.commit('app/unmask');
        this.logout();
        return;
      }

      if (this.getUser.preference.SIPAccount == null || this.getUser.preference.SIPAccount.sipExtension == null) {
        this.$store.commit('app/unmask');
        this.noSIPSelected = true;
        return;
      }

      number = number.replace(/ |[.]|[ ]|[(]|[)]/g,"");
      
      // Si le plugin pour composer les numéros est disponible ET que la convergence est active
      if (window && window.plugins && window.plugins.CallNumber && this.isConverged) {
        window.plugins.CallNumber.callNumber(() => { console.log("ok") }, () => { console.error("nok", number) }, number, true);
        this.$store.commit('app/unmask');
      }
      else {
        this.globalStore.api.Calls.makeCall(this.getUser.preference.SIPAccount.sipExtension, number)
          .then((response) => { if (response.success) { console.log("call ok"); } else { console.error("nok", number); throw new Error(); } })
          .catch(() => { this.$store.commit('app/setError', {
              code: "0001",
              message: "makeCall to " + number
            });
          })
          .finally(() => { this.$store.commit('app/unmask'); });
      }

      this.$notify({
        group: 'app',
        type: 'success',
        title: 'Appel lancé',
        text: 'Vous avez lancé un appel vers ' + number
      });
      
      Log.createLog(JSON.parse(localStorage.getItem('user')).id, "call", { number });
    },

    onChatShowed: function() {
      this.newMessageCounter = 0;
    },

    playChatNotificationSound: function() {
      this.newMessageAudio.play();
      this.newMessageAudio.muted = false;
    },

    notificationRegister: function(requested) {
      const me = this;
      
      if (typeof FirebasePlugin != "undefined") {
        FirebasePlugin.hasPermission(function(hasPermission){
          if(hasPermission){
            FirebasePlugin.getToken(function(token){
              me.$store.commit('app/setUserFirebaseInfo', {
                token: token,
                deviceType: device.platform
              });
              me.$store.commit('app/updateUser');

              me.$http.post(me.$env.notification.url + "devices/", {
                  user_id: me.getUser.id,
                  sipExtension: me.getUser.preference.SIPAccount.sipExtension,
                  registrationId: token,
                  deviceType: device.platform, // "Android" "iOS" "Mac OS X"
                  application: me.$env.firebase.applicationName
              }).catch((error) => {
                console.error(error);
              });
            }, function(error) {
                console.log("Failed to get FCM token", error);
            });
          }
          else if(!requested){
            FirebasePlugin.grantPermission(me.notificationRegister.bind(me, true));
          }
        });

        //Register handlers
        FirebasePlugin.onMessageReceived(function(message) {
          try {
            console.log(message);
              if(message.messageType === "notification"){
                  me.handleNotificationMessage(message);
              } else{
                  me.handleDataMessage(message);
              }
          } catch(e){
              console.log("Exception in onMessageReceived callback: " + e.message);
          }
        }, function(error) {
            console.log("Failed receiving FirebasePlugin message", error);
        });

        FirebasePlugin.onTokenRefresh(function(token){
          me.$store.commit('app/setUserFirebaseInfo', {
            token: token,
            deviceType: device.platform
          });

          me.$store.commit('app/updateUser');

          me.$http.post(me.$env.notification.url + "devices/", {
              user_id: me.getUser.id,
              sipExtension: me.getUser.preference.SIPAccount.sipExtension,
              registrationId: token,
              deviceType: device.platform, // "Android" "iOS" "Mac OS X"
              application: me.$env.firebase.applicationName
          }).catch((error) => {
            Log.createLog(JSON.parse(localStorage.getItem('user')).id, "error_failToRegisterToken", error);
            console.error(error);
          });
        }, function(error) {
          Log.createLog(JSON.parse(localStorage.getItem('user')).id, "error_failToRefreshToken", error);
          console.log("Failed to refresh token", error);
        });
      }
    },

    notificationUnregister: function() {
      return new Promise((resolve, reject) => {
        this.$http.post(this.$env.notification.url + "devices/unregister", {
            user_id: this.getUser.id,
            sipExtension: this.getUser.preference.SIPAccount.sipExtension,
            registrationId: this.getUser.firebase.token,
            deviceType: this.getUser.firebase.deviceType,
            application: this.$env.firebase.applicationName
        })
        .then(() => {
          Log.createLog(JSON.parse(localStorage.getItem('user')).id, "notificationUnregister");
          resolve(true);
        })
        .catch((error) => {
          Log.createLog(JSON.parse(localStorage.getItem('user')).id, "error_notificationUnregister", error);
          console.error(error);
          reject(false);
        });
      });
    },

    handleNotificationMessage: function(message) {
      const payload = typeof message.payload == "string" ? JSON.parse(message.payload) : message.payload;

      Log.createLog(JSON.parse(localStorage.getItem('user')).id, "handleNotification", message);
      
      try {
        this.setApplicationIconBadgeNumber(payload.newMessage);
      } catch (error) {
        console.error(error);
      }

      switch (payload.source) {
        case "voicemail":
          EventBus.$emit('newVoicemail', payload);
          break;
      
        default:
          break;
      }
    },

    handleDataMessage: function(message) {
      console.log("handleDataMessage", message);
    },

    voicemailRegister: function() {
        if (typeof FirebasePlugin != "undefined" && this.getUser.id && this.getUser.preference.SIPAccount.sipExtension) {
          this.$http.post(this.$env.voicemail.url + "requests/", {
            user_id: this.getUser.id,
            sipExtension: this.getUser.preference.SIPAccount.sipExtension,
            w3telToken: this.getUser.token
          }).catch((error) => {
            Log.createLog(JSON.parse(localStorage.getItem('user')).id, "error_voicemailRegister", error);
            console.error(error);
          });
        }
    },

    setApplicationIconBadgeNumber: function(count) {
      if (typeof FirebasePlugin != "undefined") {
        FirebasePlugin.setBadgeNumber(count);
      }
    },

    decreaseApplicationIconBadgeNumber: function(count) {
      const me = this;

      if (typeof FirebasePlugin != "undefined") {
        FirebasePlugin.getBadgeNumber(function(n) {
          const number = n-count;
          me.setApplicationIconBadgeNumber((number < 0 ? 0 : number));
        });
      }
    },

    updateContacts: async function() {
      try {
        if (this.getVisible) {
          await this.getW3TelContact();

          if(this.isChatEnabled) {
            await this.getChatStatus();
          }
        }
      } catch (error) {
        Log.createLog(JSON.parse(localStorage.getItem('user')).id, "error_updateContacts", error);
        console.error(error);
      }
    },

    loadDirectory: async function() {
      try {        
        document.addEventListener('deviceready', this.loadMobileDirectory, false);
        if (this.isCordova) {
          this.loadMobileDirectory();
        }
      } catch (error) {
        Log.createLog(JSON.parse(localStorage.getItem('user')).id, "error_loadMobileDirectory", error);
        console.error(error);
      }
    },

    loadMobileDirectory: function() {
      const me = this;
      window.ContactsX.hasPermission(function(permission) {
        if (permission && permission.read) {
          me.$store.commit('app/mask');
          try {
            me.$store.dispatch('directory/getMobileDirectory');
            me.$store.commit('app/unmask');
          } catch (error) {
            console.error(error);
            this.$store.commit('app/unmask');
          }
        }
        else {
          window.ContactsX.requestPermission(function() {
            me.loadMobileDirectory();
          }, function (error) {
            console.error(error);
          });
        }
      }, function () {
        window.ContactsX.requestPermission(function() {
          me.loadMobileDirectory();
        }, function (error) {
          console.error(error);
        });
      });
    },

    getW3TelContact: async function() {
      // Chargement des stores
      await this.$store.dispatch('contacts/getAllContacts');
      return;
    },

    getChatStatus: async function() {
      // Chargement des stores
      await this.$store.dispatch('contacts/getChatStatus');
      return;
    },

    handleVisibilityChange: function() {
      this.$store.commit('app/setVisible', !document.hidden);
    },

    getExtensionsData: function() {
      if (this.getUser.preference.SIPAccount) {
        this.globalStore.api.Extension.getExtension(this.getUser.preference.SIPAccount.sipExtension)
          .then((response) => {
            if (response.success && response.extension && response.extension.length > 0) {
              this.$store.commit('app/setCompanySDANumber', response.extension[0].customPaid);
              this.$store.commit('app/setUserExtensions', response.extension[0]);
              this.$store.commit('app/updateUser');
            }
            else { 
              throw new Error(); 
            }
          });
      }
    },

    getSIPAccounts: function() {
      this.globalStore.api.PhoneSettings.getSIPAccounts(this.getUser.id)
        .then((response) => {
          if (response.success) {
            // Récupération des informations de l'utilisateur
            this.$store.commit('app/setUserInfo', response.user);

            Sentry.setTag("user-email", response.user.email);
            Sentry.setTag("user-name", response.user.name);

            // Récupération des comptes SIP
            this.$store.commit('app/setUserSIPAccounts', response.SIPAccounts);
            this.SIPAccounts = response.SIPAccounts;

            // Si aucun compte SIP n'est séléctionné
            if (this.getUser.preference.SIPAccount == null) {
              // Séléction du premier compte de la liste
              this.$store.commit('app/setUserPreferenceSIPAccounts', this.SIPAccounts[0]);
              this.$store.commit('app/updateUser');

              const extension = typeof chrome != "undefined" ? chrome : typeof browser != "undefined" ? browser : null;
              if (extension && extension.storage) {
                extension.storage.local.set({sipExtension: this.SIPAccounts[0].sipExtension}, function() {});
              }

              window.location.reload();
            }
            else {
              this.getExtensionsData();

              // Si un compte SIP est séléctionné et que c'est une apps, il faut s'enregistrer sur le serveur de notification et de voicemail
              document.addEventListener('deviceready', this.notificationRegister, false);
              this.notificationRegister(false);
              this.voicemailRegister();
            }
          }
          else { 
            throw new Error(); 
          }
        })
        .catch(() => { this.error = true; });
    },

    logout: async function() {
      if (this.isCordova) {
        try {
          await this.notificationUnregister();
        } catch (error) {
          Log.createLog(JSON.parse(localStorage.getItem('user')).id, "error_logout", error);
          console.error(error);
        }
      }
      
      localStorage.removeItem('user');
      
      const extension = typeof chrome != "undefined" ? chrome : typeof browser != "undefined" ? browser : null;

      if (extension && extension.storage) {
        extension.storage.local.remove("sipExtension");
        extension.storage.local.remove("token");
        extension.storage.local.remove("user_id");
      }

      try {
        Log.createLog(JSON.parse(localStorage.getItem('user')).id, "logout", null).finally(() => {
          window.location.reload();
        })
      } catch (error) {
        console.error(error);
        window.location.reload();
      }
    }
  },

  mounted: async function() {
    // Gestion du dark theme
    const dark = localStorage.getItem('dark');
    this.$vuetify.theme.dark = dark != null ? dark == 'false' ? false : true : false;

    if (this.isElectron) {
      window.ipcRenderer.send('ready');

      const askForDefaultCallTo = localStorage.getItem("askForDefaultCallTo");

      if (askForDefaultCallTo == null) {
        window.ipcRenderer.send('cmdHandler', {
            command: "start",
            args: "tel://compagnon_askForDefaultCallTo"
        });

        localStorage.setItem("askForDefaultCallTo", true);
      }
    }

    // Ajout des informations de l'utilisateur
    if (localStorage.getItem('user') != null) {
      const localUser = JSON.parse(localStorage.getItem('user'));
      Sentry.setTag("user-id", localUser.id);
    }

    // Si ce n'est pas la route login
    if (this.$route.name != "login" && this.$route.name != "otp") {
      // Si pas de data de connexion, logout
      if (this.getUser.token == null || this.getUser.id == null) {
        this.$store.commit('app/unmask');
        this.logout();

        this.$router.push("/");
        return;
      }

      const platform = this.isCordova ? 'Cordova' : this.isElectron ? 'Desktop' : 'Web';

      Log.createLog(JSON.parse(localStorage.getItem('user')).id, "appMounted", {
        language: navigator.language,
        userAgent: navigator.userAgent,
        vendor: navigator.vendor,
        mobile: typeof navigator.userAgentData != 'undefined' ? navigator.userAgentData.mobile : false,
        platform: platform,
        version: this.globalStore.version,
        permissions: navigator.permissions,
        deviceMemory: navigator.deviceMemory,
        hardwareConcurrency: navigator.hardwareConcurrency
      });

      // Ajout de l'event pour lancer un call depuis la hash
      window.addEventListener('hashchange', () => {
        if (window.location.hash.includes("call=")) {
          this.callNumberFromShortcut(window.location.hash);
        }
      })

      // Gestion des eventbus
      EventBus.$on('launchCall', this.call);
      EventBus.$on('chatShowed', this.onChatShowed);
      EventBus.$on('playChatNotificationSound', this.playChatNotificationSound);
      EventBus.$on('setApplicationIconBadgeNumber', this.setApplicationIconBadgeNumber);
      EventBus.$on('decreaseApplicationIconBadgeNumber', this.decreaseApplicationIconBadgeNumber);
      EventBus.$on('logout', this.logout);

      // Ecoute pour savoir si l'apps est en front ou non
      let visibilityChange;
      if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support
        visibilityChange = "visibilitychange";
      } else if (typeof document.msHidden !== "undefined") {
        visibilityChange = "msvisibilitychange";
      } else if (typeof document.webkitHidden !== "undefined") {
        visibilityChange = "webkitvisibilitychange";
      }

      document.addEventListener(visibilityChange, this.handleVisibilityChange, false);
      
      // Vérification de l'expiration du token
      const parseJwt = (token) => {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
      };

      const JWTData = parseJwt(this.getUser.token);
      if (JWTData.exp && (new Date(JWTData.exp*1000).getTime() < new Date().getTime())) {
        // Si le token est expiré
        this.tokenExp = true;
        return;
      }

      // Chargement des stores globaux
      try {
        this.updateContacts();
        this.loadDirectory();
      } catch (error) {
        console.error(error);
      }

      // Update status every 5 secondes
      this.worker = setInterval(this.updateContacts, 10000);

      // Création du son de notification de nouveau message
      this.newMessageAudio = new Audio(require('@/assets/notification_msg.mp3'));
      this.newMessageAudio.muted = true;
      
      // Récupération des comptes SIP de l'utilisateur
      this.getSIPAccounts();
    }
  }
};
</script>
<style>
  html {
    background-color: #121212 !important;
  }

  .center-x {    
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .occicom-btn {
    background-color: #ffed00;
    cursor: pointer;
    color: #df0e1d !important;
    border-width: 2px !important;
    font-weight: 600 !important;
  }

  button {
    -webkit-app-region: no-drag;
  }

  :root {
    --overlay-bg: #ffeb3b !important;
    --transition-duration: .35s;
  }
</style>
