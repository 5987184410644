<template>
  <div class="mx-4">
    <v-row class="mb-4">
      <v-col cols="12" lg="3" xl="3">
        <Dialer :callTo="callTo" :class="$vuetify.breakpoint.lgAndUp ? '' : 'd-none'"/>
      </v-col>
      <v-col cols="12" lg="9" xl="9" class="mt-4">
        
        <v-img
          class="mx-auto mb-4"
          width="400"
          src="@/assets/logo_square.png"
        >
        </v-img>
        <v-btn x-large block color="red" dark @click="onPDFClick">Telecharger le PDF d'aide</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
/* global cordova */
import Dialer from '@/components/Dialer'

export default {
  name: 'Help',

  components: { Dialer },
  
  data: function() {
    return {
      callTo: null,
    }
  },

  methods: {
    onPDFClick: function() {
      if (typeof cordova != 'undefined' && cordova.InAppBrowser) {
        cordova.InAppBrowser.open(encodeURI("https://web.compagnon.k2project.fr/help.pdf"), '_system');
      }
      else {
        window.open(encodeURI("https://web.compagnon.k2project.fr/help.pdf"), '_blank');
      }
    }
  },

  mounted() {
    
  }
  
}
</script>
<style>

</style>