<template>
    <v-list style="flex:1; max-width:330px;" shaped>
        <v-list-item-group
            v-model="selected"
            mandatory
            color="primary"
            ref="group"
        >
            <v-list-item v-for="conversation in recentConversation" :key="conversation._id" @click="selectConversation(conversation)">
                <v-list-item-icon>
                    <!-- badge telephone -->
                    <v-badge
                        bordered
                        bottom
                        left
                        :color="getW3TelStatus(conversation)"
                        offset-x="15"
                        offset-y="15"
                        icon="mdi-phone"
                    >
                        <!-- badge chat -->
                        <v-badge
                            bordered
                            bottom
                            right
                            :color="getChatStatus(conversation)"
                            offset-x="15"
                            offset-y="15"
                            icon="mdi-android-messages"
                        >
                            <v-avatar color="secondary">
                                <span class="white--text text-h5">{{ getConversationShortTitle(conversation) }}</span>
                            </v-avatar>
                        </v-badge>
                    </v-badge>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ getConversationTitle(conversation) }}</v-list-item-title>
                    <v-list-item-subtitle>{{ getConversationLastMessage(conversation) }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-list-item-group>
    </v-list>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        name: 'RecentConversation',
        props: {
            recentConversation: {
                default: []
            },
            setSelected: null
        },
        computed: {
            ...mapGetters({
                contacts: 'contacts/getAll',
                getContactStatusColor: 'contacts/getContactStatusColor',
                getContactChatStatusColor: 'contacts/getContactChatStatusColor',
                getChat: 'app/getChat'
            })
        },
        watch: { 
            recentConversation: function() {
                if (this.selected == null) {
                    return false;
                }
                if (this.selected.length <= 0) {
                    this.$emit('selectConversation', this.recentConversation[0]);
                }
            },
            setSelected: function(newValue) {
                const conv = this.recentConversation.find(conv => conv._id == newValue._id);
                if (conv !== false) {

                    const me = this;

                    // Obliger d'attendre pour selected que le models soit construit
                    setTimeout(() => {
                        // Maintenant que le model est construit, il faut fabriquer soit même sont index car il y a un bug dans vuetify
                        // https://github.com/vuetifyjs/vuetify/issues/11405
                        // key dans l'objet : me.$refs.group.items[0].$vnode.data.key
                        // il faut donc trouver l'index qui nous correspond
                        const index = me.$refs.group.items.findIndex(item => item.$vnode.data.key == conv._id);
                        me.selected = index;
                    }, 200);
                }
            }
        },
        data: () => ({
            selected: []
        }),
        methods: {
            getDest: function(conversation) {
                // Récupération de l'utilisateur qui n'est pas moi
                const me = this;
                const user = conversation.roomInfo.find((info) => {
                    return (info[0]._id != me.getChat.id) ? true : false;
                });

                return user[0];
            },
            getW3TelStatus: function(conversation) {
                let user = this.getDest(conversation);
                user = this.contacts.find((u) => u.user_id == user.user_id && u.shortNum == user.shortNum);

                if (user) {
                    return this.getContactStatusColor(user);
                }
                
                return "orange";
            },
            getChatStatus: function(conversation) {
                // Recherche de l'utilisateur
                let user = this.getDest(conversation);
                user = this.contacts.find((u) => u.user_id == user.user_id && u.shortNum == user.shortNum);

                if (user) {
                    return this.getContactChatStatusColor(user);
                }
                
                return "orange";
            },
            getConversationTitle: function(conversation) {
                const user = this.getDest(conversation);

                return user.name + "(" + user.shortNum + ")";
            },
            getConversationShortTitle: function(conversation) {
                // Récupération de l'utilisateur qui n'est pas moi
                const user = this.getDest(conversation);

                // Si pas d'espace, retourne les deux premières lettres sinon, première lettre du premier et dernier mot
                const name = user.name;
                if (name.includes(' ')) {
                    const names = name.split(' ');
                    return (names[0])[0] + (names[names.length-1])[0];
                }
                else {
                    return (name)[0] + (name)[1];
                }
            },
            getConversationLastMessage: function(conversation) {
                try {
                    const itsme = conversation.postedByUser._id === this.getChat.id;

                    const createdAt = new Date(conversation.createdAt);                    

                    return this.timeSince(createdAt) + " · " + (itsme ? "Vous : " : "") + conversation.message.messageText;
                } catch (error) {
                    return "";
                }
            },
            timeSince(date) {
                var seconds = Math.floor((new Date() - date) / 1000);

                var interval = Math.floor(seconds / 31536000);

                if (interval > 1) {
                    return interval + " ans";
                }
                interval = Math.floor(seconds / 2592000);
                if (interval > 1) {
                    return interval + " mois";
                }
                interval = Math.floor(seconds / 86400);
                if (interval > 1) {
                    return interval + " j";
                }
                interval = Math.floor(seconds / 3600);
                if (interval > 1) {
                    return interval + " h";
                }
                interval = Math.floor(seconds / 60);
                if (interval > 1) {
                    return interval + " min";
                }
                return Math.floor(seconds) + " sec";
            },
            selectConversation: function(conversation) {
                this.$emit('selectConversation', conversation);
            }
        },
        mounted: async function() {
        }
    }
</script>