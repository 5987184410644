<template>
    <v-container>
        <v-row class="my-2">
            <v-card class="mx-auto">
                <v-card-text>
                    <v-switch
                    v-if="isElectron"
                    v-model="isStartEnabled"
                    inset
                    label="Lancer au démarrage"
                    @change="onIsStartEnabledChange"
                    ></v-switch>
                    <v-switch
                    v-if="isElectron && isStartEnabled"
                    v-model="isMinimizedEnabled"
                    inset
                    label="Lancer au démarrage en réduit"
                    @change="onIsMinimizedEnabledChange"
                    ></v-switch>
                    <v-switch
                    v-model="isChatEnabled"
                    inset
                    label="Activer le Tchat"
                    @change="onIsChatEnabledChange"
                    ></v-switch>
                    <v-switch
                    v-model="$vuetify.theme.dark"
                    inset
                    label="Mode nuit"
                    @change="onIsDarkThemeChange"
                    ></v-switch>
                    <v-switch
                    v-model="isExperimental"
                    inset
                    label="Mode expérimentale (expert seulement)"
                    @change="onIsExperimentalChange"
                    ></v-switch>
                    <v-card-title v-if="isElectron" class="pb-2 pt-2">Le raccourci pour lancer une appel est : {{ callbinding }}</v-card-title>
                    <v-btn v-if="isElectron" color="info" @click="showBinding = true" block>Changer le raccourci</v-btn>
                    <v-btn v-if="isElectron" class="mt-2" color="info" @click="setDefaultCallTo" block>Faire de compagnon mon numéroteur par défaut</v-btn>
                </v-card-text>
            </v-card>
        </v-row>
        <Binding :show="showBinding" @save="onSaveCallBinding" @close="showBinding = false" />
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import Binding from './Binding.vue';

export default {
    name: 'General',

    components: { Binding },

    computed: {
        ...mapGetters({
            isElectron: 'app/isElectron'
        })
    },

    data: function() {
        return {
            isChatEnabled: localStorage.getItem('chatEnabled') == "true" ? true : false,
            isExperimental: localStorage.getItem('experimental') == "true" ? true : false,
            isStartEnabled: window.electronSettings ? window.electronSettings.start : true,
            isMinimizedEnabled: window.electronSettings ? window.electronSettings.minimized : false,
            callbinding: window.electronSettings ? window.electronSettings.callbinding : 'F6',
            showBinding: false
        }
    },
    methods: {
        onIsChatEnabledChange: function(value) {
            localStorage.setItem('chatEnabled', value);
            window.location.reload();
        },

        onIsDarkThemeChange: function(value) {
            this.$vuetify.theme.dark = value;
            localStorage.setItem('dark', value);
        },

        onIsExperimentalChange: function(value) {
            localStorage.setItem('experimental', value);
            window.location.reload();
        },

        onIsStartEnabledChange: function(value) {
            window.ipcRenderer.send('start_changed', value);
        },

        onIsMinimizedEnabledChange: function(value) {
            window.ipcRenderer.send('minimized_changed', value);
        },

        onSaveCallBinding: function(payload) {

            window.ipcRenderer.send('callbinding_changed', payload);
        },

        setDefaultCallTo: function() {
            window.ipcRenderer.send('cmdHandler', {
                command: "start",
                args: "tel://compagnon_askForDefaultCallTo"
            });

            localStorage.setItem("askForDefaultCallTo", true);
        }
    },

    mounted: function() {
        if (window.ipcRenderer) {
            window.ipcRenderer.on('callbinding_changed', (evt, message) => {
                const { success, binding } = message;

                if(success) {
                    this.callbinding = binding;
                    window.electronSettings.callbinding = this.callbinding;
                }
                else {
                    alert("Impossible de lier le raccourci, veuillez utiliser une autre touche.")
                }

                console.log(evt);
            });
        }
    }
}
</script>
<style>
</style>