import OccicomAPI from 'occicom-w3tel-api-wrapper'
import env from '../../../config/env';

const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {token: null, id: null};
const api = new OccicomAPI("v1", user.token, user.id, env.config.api.url + "voip/v1/", env.config.api.AppName, env.config.api.AppPlatform, env.config.api.AppKey, env.config.api.AppVersion);

// initial state
const state = () => ({
    mobileDirectory: []
});

// getters
const getters = {
    getMobileDirectory: (state) => {
        return state.mobileDirectory;
    }
}

// actions
const actions = {
    getContact({ commit }, payload) {
        return new Promise((resolve, reject) => {
            try {
                console.log(commit);
                const { type, page , itemsPerPage, search } = payload;
                
                api.Directory.getDirectory(user.preference.SIPAccount.sipExtension, type, page, itemsPerPage, search)
                .then((response) => {
                    if (response.success) {
                        resolve({ success: true, data: {
                            items: response.directory,
                            totalContacts: response.totalContacts
                        }});
                    }
                }).catch((error) => {
                    reject({ success: false, error: error });
                });
            } catch (error) {
              reject({ success: false, error: error });
            }
        });
    },

    getMobileDirectory ({ commit }) {
        return new Promise((resolve, reject) => {
            try {
                window.ContactsX.find(function(contacts) {
                    commit('setMobileDirectory', contacts);
                    resolve({ success: true });
                }, function (error) {
                    reject({ success: false, error: error });
                }, {
                    fields: {
                        firstName: true,
                        middleName: true,
                        familyName: true,
                        phoneNumbers: true,
                        emails: true
                    }
                });
            } catch (error) {
              reject({ success: false, error: error });
            }
        });
    },

    updateDirectory ({ commit }, contact) {
        return new Promise((resolve, reject) => {
            try {
                api.Directory.updateDirectory(
                    contact.contactId,
                    contact.type,
                    contact.name,
                    contact.surname,
                    contact.company,
                    contact.email,
                    contact.web,
                    contact.url,
                    contact.tel,
                    contact.mob,
                    contact.home,
                    contact.otherTel,
                    contact.otherTel2,
                    contact.fax
                )
                .then((response) => {
                    if (response.success) {
                        console.log(commit);
                        resolve({ success: true });
                    }
                }).catch((error) => {
                    reject({ success: false, error: error });
                });
            } catch (error) {
                reject({ success: false, error: error });
            }
        });
    }
}

// mutations
const mutations = {
    setMobileDirectory: (state, data) => {
        data.forEach(element => {
            const contact = {
                contactId: element.id,
                readOnly: true,
                extension: null,
                type: "mobile",
                name: element.familyName,
                surname: element.firstName + (element.middleName ? " " + element.middleName : ""),
                company: element.organizationName,
                email: null,
                web: element.urls,
                url: null,
                tel: null,
                mob: null,
                home: null,
                otherTel: null,
                otherTel2: null,
                fax: null
            };

            contact.printName = getName(contact);
            
            if (element.emails && element.emails.length > 0) {
                contact.email = element.emails[0].value;
            }
            
            if (element.phoneNumbers && element.phoneNumbers.length > 0) {
                element.phoneNumbers.forEach(number => {
                    if (number.type == "mobile") {
                        contact.mob = number.value;
                    }
                    else {
                        contact.tel = number.value;
                    }
                })
            }

            if (!state.mobileDirectory.find(u => u.contactId == contact.contactId)) { state.mobileDirectory.push(contact); }
        });

        sortAZ(state);
    }
}

function getName(contact) {
    let name = "";
    if (contact.name) {
        name += contact.name;
    }

    if (contact.surname) {
        name += name == "" ? contact.surname : " " + contact.surname;
    }

    if (contact.company) {
        name += name == "" ? contact.company : " " + contact.company;
    }

    return name;
}

function sortFn(a, b) {
    if (a && b) {
        let aName = getName(a);
        let bName = getName(b);    

        let textA = aName.toUpperCase(); 
        let textB = bName.toUpperCase();
        
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    }
    else {
        return -1;
    }
}

function sortAZ(state) {
    state.mobileDirectory.sort(sortFn);
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}