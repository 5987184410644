<template>
  <div class="mx-4">
    <v-row class="mb-4">
      <v-col cols="12" lg="3" xl="3">
        <Dialer :callTo="callTo" :class="$vuetify.breakpoint.lgAndUp ? '' : 'd-none'"/>
      </v-col>
      <v-col cols="12" lg="9" xl="9" class="mt-4">
        <v-card>
          <v-card-title>Gestion de vos renvois</v-card-title>
          <v-card-text v-if="oldParams.unconditional"><i style="color:green;">Le transfert d'appel inconditionnel est actif vers {{ oldParams.unconditionalNum }} ({{ oldParams.unconditionalNumInternal ? "numéro interne" : "numéro externe" }}).</i></v-card-text>
          <v-card-text v-else-if="isConditional(true)"><i style="color:green;">Le transfert d'appel conditionnel est actif.</i></v-card-text>
          <v-card-text v-else><i style="color:green;">Vous n'avez pas configuré de renvoi d'appel. Ils seront redirigés vers la boîte vocale.</i></v-card-text>
          
          <div class="px-2">
            <!-- COMBO -->
            <v-select
              v-model="callforward"
              :items="items"
              label="Type de renvoi"
              dense
              solo
            ></v-select>

            <!-- CONDITIONAL -->
            <div v-if="isConditional()">
              <!-- NON REPONSE -->
              <div style="border: solid 1px black; border-radius: 5px;" class="px-4 py-4 mb-2">
                <h4>Non réponse</h4>
                <div class="ml-2">
                  <v-row class="center-x mt-1">
                    <v-col cols="12">
                      <v-text-field
                        v-model="params.noAnswerTimer"
                        label="Délai de non réponse (en seconde)"
                        type="number"
                        filled
                        rounded
                        dense
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <RadioNumber
                    :default="new Date().getTime()"
                    :defaultRadio="params.noAnswer"
                    :defaultInternal="params.noAnswerNumInternal"
                    :defaultNum="params.noAnswerNum"
                    :internalList="internalList"
                    @onRadioChanged="noAnwserRadioChanged"
                    @onExternalNumChanged="noAnswerExternalNumChanged"
                    @onInternalNumChanged="noAnswerInternalNumChanged" />
                </div>
              </div>
              <!-- OCCUPE -->
              <div style="border: solid 1px black; border-radius: 5px;" class="px-4 py-4 mb-2">
                <h4>Occupé</h4>
                <div class="ml-2">
                  <v-row class="center-x mt-1">
                    <v-col cols="12">
                      <v-text-field
                        v-model="params.busyMaxCalls"
                        label="Appel max"
                        type="number"
                        filled
                        rounded
                        dense
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <RadioNumber
                    :default="new Date().getTime()"
                    :defaultRadio="params.busy"
                    :defaultInternal="params.busyNumInternal"
                    :defaultNum="params.busyNum"
                    :internalList="internalList"
                    @onRadioChanged="busyRadioChanged"
                    @onExternalNumChanged="busyExternalNumChanged"
                    @onInternalNumChanged="busyInternalNumChanged" />
                </div>
              </div>
              <!-- INDISPONIBLE -->
              <div style="border: solid 1px black; border-radius: 5px;" class="px-4 py-4">
                <h4>Indisponible</h4>
                <div class="ml-2">
                  <RadioNumber
                    :default="new Date().getTime()"
                    :defaultRadio="params.unavailable"
                    :defaultInternal="params.unavailableNumInternal"
                    :defaultNum="params.unavailableNum"
                    :internalList="internalList"
                    @onRadioChanged="unavailableRadioChanged"
                    @onExternalNumChanged="unavailableExternalNumChanged"
                    @onInternalNumChanged="unavailableInternalNumChanged" />
                </div>
              </div>
            </div>
            <div v-else-if="params.unconditional">
              
              <div style="border: solid 1px black; border-radius: 5px;" class="px-4 py-4 mb-2">
                <h4>Inconditionnel</h4>
                <div class="ml-2">
                  <RadioNumber
                    :default="new Date().getTime()"
                    :defaultRadio="params.unconditional"
                    :defaultInternal="params.unconditionalNumInternal"
                    :defaultNum="params.unconditionalNum"
                    :internalList="internalList"
                    @onRadioChanged="unconditionalRadioChanged"
                    @onExternalNumChanged="unconditionalExternalNumChanged"
                    @onInternalNumChanged="unconditionalInternalNumChanged" />
                </div>
              </div>
            </div>
            <div v-else>
              <v-row class="center-x mb-1">
                <v-col cols="12">
                  <v-text-field
                    v-model="params.noAnswerTimer"
                    label="Délai de non réponse (en seconde)"
                    type="number"
                    filled
                    rounded
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>

            <v-btn
            class="my-2"
              block
              :loading="loading"
              dark
              @click="save"
              color="green"
            >
              Valider
            </v-btn>
          </div>

          <v-divider />
          
          <v-card-subtitle>
            <span>
              <v-icon large color="primary">mdi-information</v-icon>
              Un transfert d'appel inconditionnel signifie que tous les appels entrants sont transférés vers une autre ligne de manière automatique
            </span>
          </v-card-subtitle>
          <v-card-subtitle class="pt-0">
            <span>
              <v-icon large color="primary">mdi-information</v-icon>
              Un transfert d'appel conditionnel signifie que les appels reçus pendant l'appel en cours, si vous êtes occupé ou si vous êtes indisponible sont automatiquement envoyés au numéro de renvoi.
            </span>
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Dialer from '@/components/Dialer'
import RadioNumber from './components/RadioNumber'

export default {
  name: 'CallForwards',

  components: { Dialer, RadioNumber },

  computed: {
    callforward: {
      // getter
      get: function () {
        if (this.params.unconditional) {
          return "Inconditionnel";
        }
        else if (this.isConditional()) {
          return "Conditionnel";
        }
        else {
          return "Aucun (Boîte vocale)";
        }
      },
      // setter
      set: function (newValue) {
        this.changed = true;
        this.params.busy = this.params.noAnswer = this.params.unavailable = this.params.unconditional = false;
        switch (newValue) {
          default:
          case "Aucun (Boîte vocale)":
            break;

          case "Conditionnel":
            this.params.busy = this.params.noAnswer = this.params.unavailable = true;
            break;
          case "Inconditionnel":
            this.params.unconditional = true;
            break;
        }
      }

    },
    conditionalNoAnswer: {
      // getter
      get: function () {
        if (this.params.noAnswer) {
          if (this.params.noAnswerNumInternal) {
            return "Interne";
          }
          else {
            return "Externe";
          }
        }
        else {
          return "Aucun (Boîte vocale)"
        }
      },
      // setter
      set: function (newValue) {
        switch (newValue) {
          default: break;
          case "Aucun (Boîte vocale)":
            this.params.noAnswer = false;
            break;
          case "Interne":
            this.params.noAnswerNumInternal = true;
            break;
          case "Externe":
            this.params.noAnswerNumInternal = false;            
            break;
        }
      }
    },
    internalList: {
      get: function() {
        const final = [];

        this.params.companyExtensions.forEach(company => {
          if (company.extensions.length > 0) {
            final.push({
              header: company.companyLocation
            });

            company.extensions.forEach(extension => {
              final.push({
                extName: extension.extName,
                shortNum: extension.shortNum,
                sipExtension: extension.sipExtension,
                company: company.companyLocation,
                all: extension.extName + " " + extension.shortNum + " " + company.companyLocation
              });
            });
          }
        });

        return final;
      },
      set: function(newValue) {
        console.log(newValue);
      }
    }
  },

  data: function() {
    return {
      loading: false,
      changed: false,
      items: ["Aucun (Boîte vocale)", "Conditionnel", "Inconditionnel"],
      params: {
        busy: false,
        busyMaxCalls: 3,
        busyNum: "",
        busyNumInternal: false,
        companyExtensions: [], //{companyLocation: "OCCICOM DEVELOPPEURS", extensions: Array(1) // {sipExtension: "8933524573800", shortNum: "421", extName: "Antoine R"} }
        extName: "",
        maxCalls: 3,
        noAnswer: false,
        noAnswerNum: "",
        noAnswerNumInternal: false,
        noAnswerTimer: "0",
        personal: true,
        shortNum: "",
        sipExtension: "",
        unavailable: false,
        unavailableNum: "",
        unavailableNumInternal: false,
        unconditional: false,
        unconditionalNum: "",
        unconditionalNumInternal: false
      },
      oldParams: {
        busy: false,
        busyMaxCalls: 3,
        busyNum: "",
        busyNumInternal: false,
        companyExtensions: [],
        extName: "",
        maxCalls: 3,
        noAnswer: false,
        noAnswerNum: "",
        noAnswerNumInternal: false,
        noAnswerTimer: "0",
        personal: true,
        shortNum: "",
        sipExtension: "",
        unavailable: false,
        unavailableNum: "",
        unavailableNumInternal: false,
        unconditional: false,
        unconditionalNum: "",
        unconditionalNumInternal: false
      },
      callTo: null
    }
  },
  methods: {
    isConditional: function(old) {
      if (old) {
        if (this.oldParams.busy || this.oldParams.noAnswer || this.oldParams.unavailable) {
          return true;
        }
        return false;
      }
      else if (this.params.busy || this.params.noAnswer || this.params.unavailable) {
        return true;
      }
      return false;
    },
    noAnwserRadioChanged: function(value) {
      switch (value) {
        case 0:
          this.params.noAnswer = false;
          break;
      
        default:
          this.params.noAnswer = true;
          break;
      }
    },
    noAnswerExternalNumChanged: function(value) {
      this.params.noAnswerNum = value;
      this.params.noAnswerNumInternal = false;
    },
    noAnswerInternalNumChanged: function(value) {
      this.params.noAnswerNum = value;
      this.params.noAnswerNumInternal = true;
    },
    busyRadioChanged: function(value) {
      switch (value) {
        case 0:
          this.params.busy = false;
          break;
      
        default:
          this.params.busy = true;
          break;
      }
    },
    busyExternalNumChanged: function(value) {
      this.params.busyNum = value;
      this.params.busyNumInternal = false;
    },
    busyInternalNumChanged: function(value) {
      this.params.busyNum = value;
      this.params.busyNumInternal = true;
    },
    unavailableRadioChanged: function(value) {
      switch (value) {
        case 0:
          this.params.unavailable = false;
          break;
      
        default:
          this.params.unavailable = true;
          break;
      }
    },
    unavailableExternalNumChanged: function(value) {
      this.params.unavailableNum = value;
      this.params.unavailableNumInternal = false;
    },
    unavailableInternalNumChanged: function(value) {
      this.params.unavailableNum = value;
      this.params.unavailableNumInternal = true;
    },
    unconditionalRadioChanged: function(value) {
      switch (value) {
        case 0:
          this.params.unconditional = false;
          break;
      
        default:
          this.params.unconditional = true;
          break;
      }
    },
    unconditionalExternalNumChanged: function(value) {
      this.params.unconditionalNum = value;
      this.params.unconditionalNumInternal = false;
    },
    unconditionalInternalNumChanged: function(value) {
      this.params.unconditionalNum = value;
      this.params.unconditionalNumInternal = true;
    },
    save: function() {
      this.loading = true;
      this.changed = false;
      this.globalStore.api.CallForwards.setCallForwards(
        this.globalStore.user.preference.SIPAccount.sipExtension, 
        this.params.busyMaxCalls,
        this.params.unconditional, 
        this.params.unconditionalNum, 
        this.params.unconditionalNumInternal,
        this.params.noAnswer,
        this.params.noAnswerNum,
        this.params.noAnswerNumInternal,
        parseInt(this.params.noAnswerTimer),
        this.params.busy,
        this.params.busyNum,
        this.params.busyNumInternal,
        this.params.unavailable,
        this.params.unavailableNum,
        this.params.unavailableNumInternal
    ).then((response) => {
        if (response.success) {
          this.oldParams = {...this.params};
          scroll(0,0)
        }
      })
      .catch(() => { this.error = true; })
      .finally(() => { this.loading = false; });
    }
  },
  mounted: async function() {
    this.$store.commit('app/mask');
    // Récupération des paramètres de callforwards
    this.globalStore.api.CallForwards.getCallForwards(this.globalStore.user.preference.SIPAccount.sipExtension).then((response) => {
      if (response.success) {
        this.params = response.callForwards[0];
        this.oldParams = {...this.params};
      }
    })
    .catch(() => { this.error = true; })
    .finally(() => { this.$store.commit('app/unmask'); });
  }
}
</script>
<style>

</style>