<template>
    <div>
        <v-btn @click="refreshData" class="ml-2" dark>
            <v-icon
                left
                dark
            >
                mdi-cloud-download
            </v-icon>
            Rafraîchir les données
        </v-btn>
        <div class="ml-2" ><i style="font-size: small;"> {{lastUpdate != null ? `(${lastUpdate.toLocaleString().replace(',', ' à')})` : ''}}</i></div>
        <v-row class="px-5 pb-10">
            <div v-if="loading" style="width: -webkit-fill-available;">
                <v-skeleton-loader v-for="i in 3"
                    :key="i"
                    class="py-2"
                    type="card"
                ></v-skeleton-loader>
            </div>
            <div style="width: -webkit-fill-available;" v-else>
                <div v-if="voicemails.length <= 0">
                    <h1 class="ml-4">Vous n'avez aucun message</h1>
                </div>
                <v-list two-line style="width: -webkit-fill-available; background-color: transparent;">
                    <v-list-item-group
                        v-model="selected"
                    >
                        <template v-for="voicemail in voicemails">
                            <Voicemail 
                                :voicemail="voicemail" 
                                :key="voicemail.messageId"
                                @listen="onVoicemailReadClick"
                                @callback="onVoicemailCallbackClick"
                                @delete="onVoicemailRemoveClick"
                            />
                        </template>
                    </v-list-item-group>
                </v-list>
                <Reader :show="read" :url="voicemailURL" @close="read = false" />
            </div>
        </v-row>
    </div>
</template>

<script>
import Reader from './Reader'
import Voicemail from './Voicemail'
import { EventBus } from '@/event-bus';

import { mapGetters } from 'vuex'

export default {
    name: 'VoicemailList',
    components: { Reader, Voicemail },
    computed: {
        ...mapGetters({
            getNewMsg: 'app/getNewMsg',
        })
    },
    data: function() {
        return {
            loading: true,
            lastUpdate: null,
            voicemails: [],
            worker: null,
            read: false,
            voicemailBlob: null,
            voicemailURL: "",
            selected: null
        }
    },
    methods: {
        refreshData: function() {
            this.updateVoicemail();
        },
        getTitle: function(voicemail) {
            if (voicemail.fromName == "" || voicemail.fromName.length <= 0) {
                return "De " + voicemail.From;
            }

            return "De " + voicemail.fromName + " (" + voicemail.From + ")";
        },
        getSubtitle: function(voicemail) {
            return voicemail.duration + ", le " + voicemail.date + " a " + voicemail.time.replace(':', 'h').split(':')[0]; 
        },
        onVoicemailReadClick: function(voicemail) {
            this.$store.commit('app/mask');
            
            this.globalStore.api.Voicemail.getMessage(voicemail.messageId)
                .then((response) => {
                    if (response) {
                        // set marked
                        this.globalStore.api.Voicemail.setMessageReaded(voicemail.messageId);
                        this.$store.commit('app/setNewMsg', parseInt(this.getNewMsg)-1);
                        EventBus.$emit('decreaseApplicationIconBadgeNumber', 1);
                        voicemail.msgRead = true;
                        this.voicemailBlob = response;
                        this.voicemailURL = URL.createObjectURL(this.voicemailBlob);
                        this.read = true;
                    }
                    else { 
                        throw new Error(); 
                    }
                })
                .catch(() => { this.error = true; })
                .finally(() => { this.$store.commit('app/unmask'); });
        },
        onVoicemailCallbackClick: function(voicemail) {
            EventBus.$emit('launchCall', voicemail.From);
        },
        onVoicemailRemoveClick: function(voicemail) {
            const me = this;
            this.$store.commit('app/mask');
            
            this.globalStore.api.Voicemail.deleteMessage(voicemail.messageId)
                .then((response) => {
                    if (response) {
                        // Si le message etait non lu, il faut decrease le badge
                        if (voicemail.msgRead == false) {
                            EventBus.$emit('decreaseApplicationIconBadgeNumber', 1);
                        }

                        // Il faut filtrer le tableau pour ne plus avoir la voicemail deleted
                        me.voicemails = me.voicemails.filter(el => el.messageId != voicemail.messageId);
                    }
                    else { 
                        throw new Error(); 
                    }
                })
                .catch(() => { this.error = true; })
                .finally(() => { this.$store.commit('app/unmask'); });
        },
        updateVoicemail: async function() {
            try {
                this.globalStore.api.Voicemail.getMessages(this.globalStore.user.preference.SIPAccount.sipExtension, 0, 1000)
                .then((response) => {
                    if (response.success) {
                        EventBus.$emit('setApplicationIconBadgeNumber', response.newMsg);
                        this.voicemails = response.voicemail;
                        this.lastUpdate = new Date();
                    }
                    else { 
                    throw new Error(); 
                    }
                })
                .catch(() => { this.error = true; })
                .finally(() => {
                    this.loading = false;
                    this.$store.commit('app/setLoading', false);
                });
            } catch (error) {
                this.loading = false;
                this.$store.commit('app/setLoading', false);

                console.error(error);
            }
        },
        onNewVoicemail: function(voicemail) {
            const exist = this.voicemails.find(el => el.messageId == voicemail.messageId);

            if (exist) {
                return;
            }
            else {
                this.voicemails.unshift(voicemail);
            }
        }
    },
    mounted: async function() {
        this.$store.commit('app/setLoading', true);
        
        this.updateVoicemail();
        
        // Si pas sur mobile, alors on fait l'update a la main
        if (typeof cordova == "undefined") {
            this.worker = setInterval(this.updateVoicemail, 15000);
        }
        
        EventBus.$on('newVoicemail', this.onNewVoicemail);
    },
    destroyed: function() {
        try {
            clearInterval(this.worker);
        } catch (error) {
            console.error(error);
        }
    }
}
</script>
