<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon :color="$vuetify.theme.dark ? $env.custom.appBarBtnColor.dark : $env.custom.appBarBtnColor.light" @click="onClick" v-bind="attrs" v-on="on">
          <v-icon>mdi-phone-forward</v-icon>
      </v-btn>
    </template>
    <span>Renvoi d'appel</span>
  </v-tooltip>
</template>

<script>

export default {
  name: "CallForwardButton",

  props: {
    
  },

  methods: {
    onClick() {
      this.$router.push({ path: 'callforwards' });
    }
  }
};
</script>