/**
 * Service HTTP pour faire des requêtes
 */
import axios from "axios";
import env from '../config/env';

const apiClient = axios.create({
  baseURL: env.config.log.url
});

/**
* Branchée sur le onerror
* Cela nous permet de facilement traiter les erreurs
* @param {*} messages 
* @returns 
*/
function parseError(error) {
  if (error) {
    return Promise.reject({ code: error.code, message: error.message });
  }
  else {
    return Promise.reject(false);
  }
}

/**
* Branchée sur le onsuccess
* Cela nous permet de facilement pré-traiter le body pour n'avoir plus qu'un objet dans la réponse du service.
* @param {*} response 
* @returns 
*/
function parseBody(response) {
  if (response.status >= 200 && response.status <= 299) {
    return response;
  } else {
    return this.parseError(response.data.messages);
  }
}
 
/**
* Un intercepteur de requête qui permet d'integrer le token JWT (ou autre token si jamais) directement dans chaque requête
* Il sera possible de faire plein d'autre chose avec, c'est juste pour montrer le principe d'intercepteur de req
*/
apiClient.interceptors.request.use(
  (config) => {
    const token = localStorage.access_token;

    if (token) {
      config.headers.common['Authorization'] = `${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
 
/**
* Un intercepteur de réponse cette fois-ci
* Globalement, on va juste branché les fonctions de parseBody/Error
*/
apiClient.interceptors.response.use(
  (response) => {
    try {
      return parseBody(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  (error) => {
    console.warn('Error status', error.response.status);
    
    if (error.response) {
      return parseError(error.response.data);
    } else {
      return Promise.reject(error);
    }
  }
);
 
 export default apiClient;