import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Axios from 'axios'
import OccicomAPI from 'occicom-w3tel-api-wrapper'
import VueSocketIO from 'vue-socket.io'
import socketIO from 'socket.io-client';

import VuePageTransition from 'vue-page-transition'
import Notifications from 'vue-notification'

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

import env from '../config/env';

Vue.prototype.$http = Axios;

Vue.config.productionTip = false

/**
 * 26/08/2021 : Début de la migration dans VUEX pour les informations user/chat
 * Ne devrait rester dans le GlobalStore que l'accès API avant de l'incorporer dans this.$api
 */
class GlobalStore {
  constructor() {
    this.user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {token: null, id: null},
    this.version = process.env.VERSION || '0',
    this.api = new OccicomAPI("v1", this.user.token, this.user.id, env.config.api.url + "voip/v1/", env.config.api.AppName, env.config.api.AppPlatform, env.config.api.AppKey, env.config.api.AppVersion)
  }

  goToExtranet() {
    const url = env.config.extranet.url;
    window.open(url,'_newtab');
  }
}

const globalStore = new GlobalStore();

if (window.ipcRenderer) {
  window.ipcRenderer.on('settings', function (evt, message) {
    window.electronSettings = message;
  });
}

Sentry.init({
  dsn: "https://82c70309d45446fa99d64340b37a40dc@sentry.production.k2project.fr/2",
  
  release: "compagnon@" + process.env.VERSION,

  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router)
    }),
  ],

  normalizeDepth: 8,

  attachStacktrace: true,
  
  tracesSampleRate: 1.0,
});

Vue.mixin({
  data() {
    return {
      globalStore
    }
  }
});

const socket = socketIO(env.config.chat.url, {
  transports: ['websocket', 'polling', 'flashsocket']
})

Vue.use(new VueSocketIO({
  debug: true,
  connection: socket
}));

Vue.use(Vuex);

Vue.use(VuePageTransition);
Vue.use(Notifications);

Vue.use(env);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');
