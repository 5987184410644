<template>
    <div ref="messageList" style="flex:1; display: flex; flex-direction: column; overflow:auto;" class="mb-2 container">
        <Message v-for="(message, index) in messageList" :key="message.postId" :message="message" :previous="messageList[index-1]" @loaded="scrollBottom" />
    </div>
</template>

<script>
    import Message from './Message';

    export default {
        name: 'MessageList',
        components: { Message },
        props: {
            messageList: {
                type: Array
            }
        },
        watch: { 
            messageList: function() {
                // MessageList change seulement quand il y a un nouveau message ou un changement de conv, il faut donc scroll down
                this.scrollBottom();
            }
        },
        data: () => ({ 
            
        }),
        methods: {
            scrollBottom: function() {
                this.$refs.messageList.scrollTop = this.$refs.messageList.scrollHeight;
            }
        },
        mounted: async function() {

        }
    }
</script>
<style>
    .message_container:first-child {
        margin-top: auto;
    }
</style>