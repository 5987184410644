<template>
  <div class="mx-4">
    <v-row class="mb-4">
      <v-col cols="12" lg="3" xl="3">
        <Dialer :callTo="callTo" :class="$vuetify.breakpoint.lgAndUp ? '' : 'd-none'"/>
      </v-col>
      <v-col cols="12" lg="9" xl="9" class="mt-4">
        <v-row class="mt-2">
          <v-tabs
            v-model="tab"
            center-active
            show-arrows
          >
            <v-tabs-slider :color="$vuetify.theme.dark ? $env.custom.appBarColor.dark : $env.custom.appBarColor.light"></v-tabs-slider>

            <v-tab key="0"
            >
              Tous {{ isCordova ? '(sauf Mobile)' : ''}}
            </v-tab>
            <v-tab v-if="isCordova" key="1"
            >
              Mobile
            </v-tab>
            <v-tab key="2"
            >
              Siège
            </v-tab>
            <v-tab key="3"
            >
              Société
            </v-tab>
            <v-tab key="4"
            >
              Département
            </v-tab>
            <v-tab key="5"
            >
              Personnel
            </v-tab>
            <v-tab key="6"
            >
              N° abrégés
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab" style="background-color: #ffffff00; width: 100%;">
            <v-tab-item key="0">
              <APIDirectoryList type="all" />
            </v-tab-item>
            <v-tab-item v-if="isCordova" key="1">
              <MobileDirectoryList />
            </v-tab-item>
            <v-tab-item key="2">
              <APIDirectoryList type="hQ" />
            </v-tab-item>
            <v-tab-item key="3">
              <APIDirectoryList type="company" />
            </v-tab-item>
            <v-tab-item key="4">
              <APIDirectoryList type="subsidiary" />
            </v-tab-item>
            <v-tab-item key="5">
              <APIDirectoryList type="personal" />
            </v-tab-item>
            <v-tab-item key="6">
              <APIDirectoryList type="shared" />
            </v-tab-item>
          </v-tabs-items>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Dialer from '@/components/Dialer'
import APIDirectoryList from './components/APIDirectoryList'
import MobileDirectoryList from './components/MobileDirectoryList'

import { mapGetters } from 'vuex'

export default {
  name: 'Directory',

  components: { Dialer, APIDirectoryList, MobileDirectoryList },

  computed: {
    ...mapGetters({
      isCordova: 'app/isCordova',
      mobileDirectory: 'directory/getMobileDirectory'
    })
  },

  data: function() {
    return {
      tab: 0,
      callTo: null
    }
  }
}
</script>
<style>

</style>