<template>
    <v-toolbar flat style="flex:0;">
        <v-text-field
            v-model="search"
            background-color="chatContent darken-1"
            dense
            flat
            hide-details
            rounded
            solo
            prepend-icon="mdi-magnify"
            placeholder="Rechercher une conversation"
            @keyup="searchFn"
        ></v-text-field>
        <v-menu
            v-model="menu"
            transition="slide-x-transition"
            bottom
            offset-x
            max-height="400"
            min-width="400"
            :close-on-content-click="false"
            >
            <template v-slot:activator="{ on, attrs }">                
                <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-chat-plus</v-icon>
                </v-btn>
            </template>
            <v-list shaped dense>
                <v-subheader>DEMARRER UNE CONVERSATION</v-subheader>
                <v-text-field
                    v-model="searchContact"
                    class="mx-4 mb-2"
                    background-color="chatContent"
                    dense
                    flat
                    hide-details
                    rounded
                    solo
                    placeholder="Rechercher un contact"
                    @keyup="searchContactFn"
                ></v-text-field>
                <v-list-item-group
                    v-model="selected"
                    color="primary"
                >
                    <v-list-item v-for="contact in contacts" :key="contact.user_id + '_' + contact.shortNum" @click="createRoom(contact)">
                        <v-list-item-icon>
                            <v-icon>mdi-account</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ contact.name }} ({{contact.shortNum}})</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-menu>
    </v-toolbar>
</template>

<script>
    import { mapGetters } from 'vuex'
    export default {
        name: 'Toolbar',
        props: {
            clearSearch: {
                default: false
            },
            urlId: {
                default: false
            }
        },
        computed: {
            ...mapGetters({
                getAllContacts: 'contacts/getAll',
                getChat: 'app/getChat',
            })
        },
        watch: {
            clearSearch: function(newValue) {
                if (newValue) {
                    this.search = null;
                    this.searchContact = null;
                }
            }
        },
        data: () => ({
            menu: false,
            search: null,
            searchContact: null,
            initialContacts: [],
            contacts: [],
            selected: []
        }),
        methods: {
            searchFn: function() {
                this.$emit("search", this.search);
            },
            searchContactFn: function() {
                const me = this;

                this.contacts = this.initialContacts;

                if (this.searchContact == null || this.searchContact.length < 0) {
                    return false;
                }

                this.contacts = this.contacts.filter((contact) => {
                    if ((contact.name + " " + contact.shortNum).toLowerCase().includes(me.searchContact.toLowerCase())) {
                        return true;
                    }

                    return false;
                })
            },
            createRoom: async function(contact) {
                try {
                    this.$store.commit('app/mask');

                    this.selected = []; // Pour ne pas avoir de selected dans la liste

                    // 1er étape : avoir l'id chat de l'utilisateur
                    const user_chat_id = await this.getUserChatID(contact);

                    // 2em étape : création de la room
                    const room = await this.initiateChatRoom(user_chat_id);

                    // 3em étape : création d'une fakeroom
                    const fakeroom = {
                        _id: room.chatRoomId,
                        roomInfo: [[contact]],
                        createdAt: new Date().toString()
                    };

                    fakeroom["roomInfo"][0][0]._id = user_chat_id;
                    
                    this.$emit('addRoom', fakeroom);
                    this.$emit('selectConversation', fakeroom);

                    this.menu = false;
                    this.$store.commit('app/unmask');
                } catch (error) {
                    this.$store.commit('app/unmask');
                }
            },
            getUserChatID: async function(contact) {
                return new Promise((resolve, reject) => {
                    try {
                        this.$http.get(this.$env.chat.url + "users/w3tel/" + contact.user_id + "/" + contact.shortNum, { headers: { 'Authorization': "Bearer " + this.getChat.authorization }})
                        .then(async (response) => {
                            if (response.data.success) {
                                let chatContact = response.data.user == false ? await this.createW3TelUser(contact) : response.data.user;

                                resolve(chatContact._id);
                            }
                            else {
                                throw Error();
                            }
                        }).catch((err) => {
                            this.$store.commit('app/setError', {
                                code: "0007",
                                message: "getUserChatID " + (err ? err.name : "")
                            });
                            console.error(err);

                            this.selected = [];
                        });
                    } catch (error) {
                        reject(false);
                    }
                });
            },
            createW3TelUser: async function(contact) {
                return new Promise((resolve, reject) => {
                    this.$http.post(this.$env.chat.url + "users/", {
                        email: contact.email,
                        favorite: false,
                        name: contact.name,
                        picture: contact.picture,
                        user_id: contact.user_id,
                        shortNum: contact.shortNum 
                    }, { headers: { 'Authorization': "Bearer " + this.getChat.authorization }})
                    .then(response => {
                        if (response.data.success) {
                            resolve(response.data.user);
                        }
                        else {
                            reject(false);
                        }
                    }).catch((err) => {
                        this.$store.commit('app/setError', {
                            code: "0010",
                            message: "createW3TelUser " + (err ? err.name : "")
                        });
                        console.error(err);
                        reject(false);
                    });
                });
            },
            initiateChatRoom: async function(chat_id) {
                return new Promise((resolve, reject) => {
                    this.$http.post(this.$env.chat.url + "room/initiate", {
                        userIds: [chat_id]
                    }, { headers: { 'Authorization': "Bearer " + this.getChat.authorization }})
                    .then(response => {
                        if (response.data.success) {
                            /**
                             * Une nouvelle chat room :
                                chatRoomId: "xxxxxxxxx"
                                isNew: true
                                message: "Création du salon de discussion"
                             * Récupération d'une room qui existe déjà
                                chatRoomId: "xxxxxxxxx"
                                isNew: false
                                message: "Récupération d'un ancien salon de discussion"
                             */
                            resolve(response.data.chatRoom);
                        }
                        else {
                            reject(false);
                        }
                    }).catch((err) => {
                        this.$store.commit('app/setError', {
                            code: "0011",
                            message: "initiateChatRoom " + (err ? err.name : "")
                        });
                        console.error(err);
                        reject(false);
                    });
                });
            }
        },
        mounted: async function() {
            try {
                this.$store.commit('app/mask');

                // Récupération de mes contacts
                await this.$store.dispatch('contacts/getAllContacts');
                const users = this.getAllContacts;

                this.$store.commit('app/unmask');

                if (users) {
                    // Récupération de chaque utilisateur et surtout tri dans dossier
                    this.initialContacts = users;
                    this.contacts = this.initialContacts;
                    
                    // Si on doit focus un utilisateur (bouton message depuis l'accueil)
                    if (this.urlId) {
                        // recherche de l'utilisateur a ajouter
                        const contact = this.contacts.find(el => (el.shortNum + "_" + el.user_id) === this.urlId);
                        if (contact) {
                            this.createRoom(contact);
                        }
                    }
                }
                else {
                    throw Error();
                }
            } catch (error) {
                this.$store.commit('app/unmask');
            }
        }
    }
</script>