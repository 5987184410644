<template>
    <v-app-bar 
      app
      v-if="isElectron"
      dense
      :color="$vuetify.theme.dark ? $env.custom.appBarColor.dark : $env.custom.appBarColor.light"
      style="-webkit-app-region: drag"
    >
      <v-toolbar-title></v-toolbar-title>
      <v-spacer></v-spacer>
      <ElectronMinimizeButton />
      <ElectronMaximizeButton />
      <ElectronCloseButton />
    </v-app-bar>
</template>

<script>

import { mapGetters } from 'vuex'

import ElectronMinimizeButton from './Minimize_Button.vue'
import ElectronMaximizeButton from './Maximize_Button.vue'
import ElectronCloseButton from './Close_Button.vue'

export default {
  name: "Login_AppBar",

  computed: {
      ...mapGetters({
          isElectron: 'app/isElectron'
      })
  },

  components: { ElectronMinimizeButton, ElectronMaximizeButton, ElectronCloseButton }
};
</script>