<template>
<v-dialog
      v-model="show"
      persistent
      width="500"
    >
        <v-card>
            <v-card-title class="headline grey lighten-2">
                Enregistrer votre annonce vocal
            </v-card-title>
            <div class="center-x w-100 my-2">
                <v-btn
                    :loading="recording"
                    :disabled="recording"
                    class="ma-2"
                    :class="$env.custom.societyBtnClass"
                    outlined
                    large
                    fab
                    @click="startCapture"
                >
                    <v-icon dark>
                        mdi-microphone 
                    </v-icon>
                </v-btn>
                <v-btn
                    :disabled="!recording"
                    class="ma-2"
                    :class="$env.custom.societyBtnClass"
                    outlined
                    large
                    fab
                    @click="stopCapture"
                >
                    <v-icon dark>
                        mdi-stop-circle
                    </v-icon>
                </v-btn>
            </div>

            <div class="center-x mt-2 mb-4" :class="recordingSourceAsRAW != null ? '' : 'd-none'">
                <audio id="player" controls></audio>
            </div>
            <div class="center-x px-4" :class="recordingSourceAsRAW != null ? '' : 'd-none'">
                <v-text-field
                    v-model="description"
                    label="Description"
                    placeholder="Mon annonce vocale"
                    outlined
                ></v-text-field>
            </div>

            <v-divider></v-divider>

            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                :disabled="recording"
                color="error"
                text
                @click="close"
            >
                Annuler
            </v-btn>
            <v-btn
                :disabled="recording || recordingSourceAsRAW == null"
                color="primary"
                text
                @click="save"
            >
                Sauvegarder
            </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
/* global cordova, LocalFileSystem, Media */

export default {
    name: 'CordovaRecorder',
    props: {
        show: {
            default: false
        }
    },
    data: function() {
        return {
            recordingMedia: null,
            recordingSource: null,
            recordingSourceAsRAW: null,
            recording: false,
            description: null
        }
    },
    methods: {
        startCapture: function () {
            const me = this;
            window.requestFileSystem(LocalFileSystem.PERSISTENT, 0, (fs) => {
                console.log('file system open: ' + fs.name);
                fs.root.getFile("temp.wav", { create: true, exclusive: false }, (fileEntry) => {
                    me.recordingMedia = new Media(fileEntry.nativeURL.replace(/^file:\/\//, ''), me.captureOk, me.captureNOk, (a,b,c,d) => { console.log("st", a,b,c,d); });
                    me.recordingMedia.startRecord();
                    
                    me.recording = true;
                }, (a,b,c,d) => { console.log("err create", a,b,c,d); });
            }, (a,b,c,d) => { console.log("err load", a,b,c,d); });
        },

        captureOk: function() {
            const me = this;
            window.resolveLocalFileSystemURL("file://" + me.recordingMedia.src, function (tempFile) {
                tempFile.file(function (tempWav) {
                    var reader = new FileReader();	    
                    reader.onloadend = function() {
                        var blob = new Blob([new Uint8Array(this.result)], { type: "audio/wav" });
                        me.recordingSourceAsRAW = blob;
                        me.recordingSource = URL.createObjectURL(me.recordingSourceAsRAW);
                        document.getElementById("player").src = me.recordingSource;

                        me.recording = false;
                    }
                    reader.readAsArrayBuffer(tempWav);
                });
            }, function(e) {
                me.recording = false;
                console.log("Could not resolveLocalFileSystemURL: " + e.message);
            });
        },

        captureNOk: function(a,b,c,d) {
            console.log("error capture", a,b,c,d);
        },

        stopCapture: function () {
            const me = this;
            me.recording = false;
            me.recordingMedia.stopRecord();
        },
        close: function() {
            this.reset();
            this.$emit("close");
        },
        reset: function() {
            this.recorder = null;
            this.recorderStream = null;
            this.recordingSource = null;
        },
        save: function() {
            this.$emit('save', this.recordingSourceAsRAW, this.description);
            this.reset();
        }
    },
    mounted: async function() {
        const captureCfg = {
            sampleRate: 16000,
            bufferSize: 8192,
            channels: 1,
            format: window.audioinput.FORMAT.PCM_16BIT,
            audioSourceType: window.audioinput.AUDIOSOURCE_TYPE.DEFAULT,
            fileUrl: cordova.file.cacheDirectory
        };
        
        // Initialize the window.audioinput plugin.
        window.window.audioinput.initialize(captureCfg, function() {	
            // Now check whether we already have permission to access the microphone.
            window.window.audioinput.checkMicrophonePermission(function(hasPermission) {
                if (hasPermission) {
                    console.log("Already have permission to record.");
                } 
                else {	        
                    // Ask the user for permission to access the microphone
                    window.window.audioinput.getMicrophonePermission(function(hasPermission) {
                        if (hasPermission) {
                            console.log("User granted permission to record.");
                        } else {
                            console.warn("User denied permission to record.");
                        }
                    });
                }
            });
        });
    },
    destroyed: function() {

    }
}
</script>