<template>
  <div class="mx-4">
    <v-row class="mb-4">
      <v-col cols="12" lg="3" xl="3">
        <Dialer :callTo="callTo" :class="$vuetify.breakpoint.lgAndUp ? '' : 'd-none'" />
      </v-col>
      <v-col cols="12" lg="9" xl="9" class="mt-4">
        <v-row class="mb-0 p-0">
          <v-col class="pl-0" cols="6" xl="4" lg="4" md="4" sm="6" xs="6">
            <v-select v-model="type" :items="types" item-text="label" item-value="value" label="Solo field" solo
              hide-details @change="() => { data = []; getData() }"></v-select>
          </v-col>
        </v-row>
        <v-row class="mt-2">
          <v-card>
            <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12" v-for="(item, index) in data"
              :key="item.date.getTime()">
              <v-card-title v-if="getDate(data, item, index) != ''">{{ getDate(data, item,
                index).charAt(0).toUpperCase() + getDate(data, item, index).slice(1) }}</v-card-title>
              <v-row>
                <v-icon large
                  :color="(item.type == 'Sent' ? 'blue' : item.duration > 0 ? 'green' : 'red') + ' darken-2'"
                  class="mr-2">
                  {{ item.type == "Sent" ? "mdi-call-made" : item.duration > 0 ? "mdi-call-received" : "mdi-call-missed"
                  }}
                </v-icon>
                <v-btn dark color="green" rounded class="ma-2" @click="launchCall(item.to)">
                  {{ item.type == "Sent" ? item.to : getFromTitle(item) }}
                </v-btn>
                <v-spacer></v-spacer>
                <div class="align-self-center flex mx-4" style="text-align: end;">{{ item.date.toLocaleTimeString() }}
                </div>
                <v-chip class="ma-2" :color="item.state ? 'green' : 'red'" text-color="white">{{
                  getDurationString(item.duration) }}</v-chip>
              </v-row>
            </v-col>
            <div class="px-4">
              <v-btn dark block color="green" class="mb-2 mt-2" @click="() => { page += 1; getData() }">Voir
                plus</v-btn>
            </div>
          </v-card>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import parsePhoneNumber from 'libphonenumber-js'
import { EventBus } from '@/event-bus';

import Dialer from '@/components/Dialer'

export default {
  name: 'Log',

  components: { Dialer },

  computed: {
    ...mapGetters({
      mobileDirectory: 'directory/getMobileDirectory'
    })
  },

  data: function () {
    return {
      types: [{ label: "Tous", value: "all" }, { label: "Emis", value: "sent" }, { label: "Reçus", value: "incoming" }, { label: "Répondus", value: "answered" }, { label: "Manqués", value: "missed" }],
      type: "all", // all sent incoming answered missed
      data: [],
      page: 0,
      perPage: 24,
      callTo: null
    }
  },
  methods: {
    getDate(all, item, index) {
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
      return all[index - 1] ? all[index - 1].date.toLocaleDateString() == item.date.toLocaleDateString() ? '' : item.date.toLocaleDateString('fr-Fr', options) : item.date.toLocaleDateString('fr-Fr', options);
    },
    getData() {
      if (this.globalStore.user.preference.SIPAccount) {
        this.$store.commit('app/mask');
        this.globalStore.api.Calls.getCallLog(this.globalStore.user.preference.SIPAccount.sipExtension, this.type, this.page, this.perPage)
          .then((response) => {
            if (response.success) {
              // Modelage des datas
              response.callLogs.forEach(log => {
                console.log(log);
                try {
                  let logDate;

                  if (log.date == "Today") {
                    logDate = new Date();
                    logDate.setHours(log.time.split(':')[0], log.time.split(':')[1], log.time.split(':')[2]);
                  }
                  else if (log.date == "Yesterday") {
                    logDate = new Date();
                    logDate.setDate(logDate.getDate() - 1);
                    logDate.setHours(log.time.split(':')[0], log.time.split(':')[1], log.time.split(':')[2]);
                  }
                  else {
                    const [day, month, yearTime] = (log.date + " " + log.time).split('/');
                    const [year, time] = yearTime.split(' ');
                    const formattedDate = `${year}-${month}-${day}T${time}`;

                    logDate = new Date(formattedDate);
                  }

                  this.data.push({
                    type: log.type,
                    from: log.fromName != "" ? log.from + " (" + log.fromName + ")" : log.from,
                    to: log.toName != "" ? log.to + " (" + log.toName + ")" : log.to,
                    date: logDate,
                    date_string: logDate.toLocaleString(),
                    duration: log.duration,
                    state: log.duration > 0 ? true : false
                  })
                } catch (error) {
                  console.error(error);
                }
              });
            }
            else {
              throw new Error();
            }
          })
          .catch((e) => { console.error(e); this.error = true; })
          .finally(() => { this.$store.commit('app/unmask'); });
      }
    },

    getContact(num) {
      if (num) {
        return this.mobileDirectory.find(contact => {
          // La seule information que nous retourne W3Tel c'est le numéro en From
          // Il va donc falloir faire une recherche sachant que le numéro est soit :
          // 06 xx xx xx xx
          // +336 xx xx xx xx
          // 421
          let number = parsePhoneNumber(num, "FR");
          if (number) {
            // Transformation des numéros du contact
            let tel = contact.tel;
            if (tel) { tel = parsePhoneNumber(tel, "FR") }

            let mob = contact.mob;
            if (mob) { mob = parsePhoneNumber(mob, "FR") }

            let otherTel = contact.otherTel;
            if (otherTel) { otherTel = parsePhoneNumber(otherTel, "FR") }

            if (tel && tel.nationalNumber == number.nationalNumber) {
              return contact;
            }

            if (mob && mob.nationalNumber == number.nationalNumber) {
              return contact;
            }

            if (otherTel && otherTel.nationalNumber == number.nationalNumber) {
              return contact;
            }
          }
        });
      }
      else {
        return null;
      }
    },

    getFromTitle(element) {
      const exist = this.getContact(element.from);
      if (exist) {
        return exist.printName;
      }
      else {
        return element.from;
      }
    },

    getDurationString(duration) {
      var hrs = ~~(duration / 3600);
      var mins = ~~((duration % 3600) / 60);
      var secs = ~~duration % 60;

      var ret = "";

      if (hrs > 0) {
        ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
      }

      ret += "" + mins + ":" + (secs < 10 ? "0" : "");
      ret += "" + secs;

      return ret;
    },

    launchCall(number) {
      // Conversion en numéro de téléphone autant que possible
      let cleanNumber = number.split('(')[0].replaceAll(' ', '');

      // Si le numéro fait moins de 5 char, c'est un numéro court, il ne faut pas le parser
      if (cleanNumber.length > 5) {
        cleanNumber = parsePhoneNumber(cleanNumber, "FR");
      }

      EventBus.$emit('launchCall', cleanNumber.number ? cleanNumber.number : cleanNumber);
    }
  },
  mounted: function () {
    this.getData();
  }
}
</script>
<style></style>