<template>
  <v-list-item v-if='value != ""'>
    <v-list-item-icon>
      <v-icon color="primary">
        {{ icon }}
      </v-icon>
    </v-list-item-icon>

    <v-list-item-content>
      <v-list-item-title>{{ value }}</v-list-item-title>
      <v-list-item-subtitle>{{ name }}</v-list-item-subtitle>
    </v-list-item-content>

    <v-list-item-icon v-if="callNumber != null" class="mb-0">
      <v-btn
        :class="$env.custom.societyBtnClass"
        outlined
        x-small
        fab
        @click="call(callNumber)"
      >
        <v-icon>mdi-phone</v-icon>
      </v-btn>
    </v-list-item-icon>
  </v-list-item>
</template>

<script>
import { EventBus } from '@/event-bus';

export default {
  name: 'DirectoryBlockField',
  props: {
    icon: {
      default: "",
      type: String
    },
    value: {
      default: "",
      type: String
    },
    name: {
      default: "",
      type: String
    },
    callNumber: {
      default: null,
      type: String
    }
  },
  data: function() {
    return {
      
    }
  },
  methods: {
    call(num) {
      EventBus.$emit('launchCall', num);
    }
  }
}
</script>