import OccicomAPI from 'occicom-w3tel-api-wrapper'
import Axios from 'axios'
import env from '../../../config/env';

const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {token: null, id: null};
const api = new OccicomAPI("v1", user.token, user.id, env.config.api.url + "voip/v1/", env.config.api.AppName, env.config.api.AppPlatform, env.config.api.AppKey, env.config.api.AppVersion);

// initial state
const state = () => ({
    loading: false,
    all: [],
    groups: [{
        name: "Favoris",
        users: []
        },
        {
        name: "Tous",
        users: []
        }
    ],
});

// getters
const getters = {
    getLoading: (state) => {
        return state.loading;
    },
    getAll: (state) => {
        return state.all;
    },
    getGroups: (state) => {
        return state.groups;
    },
    getContactStatusColor: (state) => (user) => {
        if (state.loading) {
            return "grey";
        }

        switch (user.status) {
            case 2:
              return "red";
            case 1:
              return "green";
            case 0:
            default:
              return "black";
        }
    },
    getContactChatStatusColor: (state) => (user) => {
        if (state.loading) {
            return "grey";
        }

        return user.chat_status ? "green" : "black";
    },
    getContactByShortNum: (state) => (shortNum) => {
        const user = state.all.find((u) => u.shortNum == shortNum);

        return user;
    }
}

// actions
const actions = {
    getAllContacts ({ commit }) {
        return new Promise((resolve, reject) => {
            try {
                commit('setLoading', true);
                
                if (user.preference.SIPAccount) {
                    api.Users.getAll(user.preference.SIPAccount.sipExtension, 0, 1000)
                    .then((response) => {
                        commit('setLoading', false);
                        if (response.success) {
                            commit('setContacts', response.users);
                            
                            commit('app/setNewMsg', response.header.voicemail.newMsg, {root: true});
                            resolve({ success: true });
                        }
                    }).catch((error) => {
                        if (error && error.response && error.response.data && error.response.data.includes('"success":true')) {
                            try {
                                const _ = JSON.parse(error.response.data.replace('"Something went wrong!"', ''));
                                commit('setLoading', false);
                                commit('setContacts', _.users);
                                resolve({ success: true });
                            } catch (error) {
                                commit('setLoading', false);
                                reject({ success: false, error: error });                                
                            }
                        }
                        else {
                            commit('setLoading', false);
                            reject({ success: false, error: error });
                        }
                    });
                }
                else {
                    commit('setLoading', false);
                    reject({ success: false, error: null });
                }
            } catch (error) {
                commit('setLoading', false);
                reject({ success: false, error: error });
            }
        });
    },
    getChatStatus ({ state, rootGetters }) {
        return new Promise((resolve, reject) => {
            try {
                Axios.get(env.config.chat.url + "users/connected", { headers: { 'Authorization': "Bearer " + rootGetters['app/getChat'].authorization }})
                .then(async (response) => {
                    if (response.data) {
                        if (response.data.users && response.data.users.length > 0) {
                            // Pour chaque contact, il faut voir si il est dans la liste des connected
                            state.all.forEach(contact => {
                                // recherche dans le liste des connected
                                const existingContact = response.data.users.find((u) => u.shortNum == contact.shortNum && u.user_id == contact.user_id);
        
                                if (existingContact) {
                                    contact.chat_status = true;
                                }
                                else {
                                    contact.chat_status = false;
                                }
                            });

                            resolve({ success: true });
                        }
                    }
                    else {
                        throw Error();
                    }
                }).catch((error) => {
                    reject({ success: false, error: error });
                });
            } catch (error) {
                reject({ success: false, error: error });
            }
        });
    },
    updateFavorite ({ state, commit }) {
        commit('resetFavorite');

        state.all.forEach(contact => {
            try {
                if (contact.favorite) {
                    commit('addFavorite', contact);
                }
            } catch (error) {
                console.error("add_error", error);
            }
        });

        // Empile les "Hors ligne" a la fin puis garde le filtre par défaut
        state.groups.forEach(group => {
            group.users.sort(function(x, y) {
                if (x.status == 0 && y.status != 0) {
                  return 1;
                }
                
                if (x.status != 0 && y.status == 0) {
                  return -1;
                }
        
                return 0;
            });
        });
    }
}

// mutations
const mutations = {
    setLoading (state, loading) {
        state.loading = loading;
    },
    setContacts (state, contacts) {
        state.all = contacts;

        const allGroups = state.groups.find((g) => g.name == "Tous");
        const favGroups = state.groups.find((g) => g.name == "Favoris");

        // Récupération de chaque utilisateur
        state.all.forEach(contact => {
            const existingContact = allGroups.users.find((u) => u.shortNum == contact.shortNum && u.user_id == contact.user_id);

            if (existingContact) {
                if (existingContact.status != contact.status) {
                    console.log(contact.user_id + " " + contact.name + " status changed " + existingContact.status + " to " + contact.status);
                    existingContact.status = contact.status;
                }
            }
        });

        // Récupération de chaque utilisateur et surtout tri dans dossier
        state.all.forEach(contact => {
            try {
                if (!allGroups.users.find(u => u.user_id == contact.user_id && u.shortNum == contact.shortNum)) {
                    allGroups.users.push(contact);
                }
                if (contact.favorite) {
                    if (!favGroups.users.find(u => u.user_id == contact.user_id && u.shortNum == contact.shortNum)) {
                        favGroups.users.push(contact);
                    }
                }

                // Recherche du groupe de l'utilisateur
                const userGroups = state.groups.find((g) => g.name == contact.subsidiary);
                if (userGroups) {
                    if (!userGroups.users.find(u => u.user_id == contact.user_id && u.shortNum == contact.shortNum)) {
                        userGroups.users.push(contact);
                    }
                }
                else {
                    state.groups.push({
                        name: contact.subsidiary,
                        users: [contact]
                    });
                }
            } catch (error) {
                console.error("add_error", error);
            }
        });

        // sort les tableaux
        state.groups.forEach(group => {
            group.users.sort(function(x, y) {
                if (x.status == 0 && y.status != 0) {
                  return 1;
                }
                
                if (x.status != 0 && y.status == 0) {
                  return -1;
                }
        
                return 0;
            });
        });
    },
    resetFavorite (state) {
        const favGroups = state.groups.find((g) => g.name == "Favoris");
        favGroups.users = [];
    },
    addFavorite (state, contact) {
        const favGroups = state.groups.find((g) => g.name == "Favoris");
        favGroups.users.push(contact);
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}