<template>
    <v-card class="messages_list" style="height: inherit;">
        <!-- Detail de la room -->
        <Toolbar :urlId="urlId" :clearSearch="clearSearch" @addRoom="addRoom" @selectConversation="selectConversation" @search="search"/>

        <v-divider></v-divider>

        <!-- Liste des messages -->
        <RecentConversation :setSelected="setSelected" :recentConversation="chatRooms" @selectConversation="selectConversation" />
    </v-card>
</template>

<script>
    import { mapGetters } from 'vuex'
    import Toolbar from './ConversationList/Toolbar.vue'
    import RecentConversation from "./ConversationList/RecentConversation.vue"

    export default {
        name: 'ConversationList',
        components: { RecentConversation, Toolbar },
        props: {
            newMessage: null,
            urlId: null
        },
        computed: {
            ...mapGetters({
                getChat: 'app/getChat'
            })
        },
        watch: {
            newMessage: function(newValue) {
                const msgRoom = this.chatRooms.find(room => room._id == newValue.chatRoomId);

                // Si la room existe déjà
                if (msgRoom) {
                    // Modification de la date du dernier message
                    msgRoom.createdAt = newValue.createdAt;

                    // Modification de l'expediteur du dernier message
                    msgRoom.postedByUser = newValue.postedByUser;

                    // Modification du message
                    msgRoom.message = newValue.message;

                    // Il faut re trier la liste
                    this.sortList();
                }
                else {
                    // Si la room n'existe pas déjà, il faut reinitialiser la liste, ce sera plus simple
                    this.init();
                }
            }
        },
        data: () => ({
            initialChatRooms: [],
            chatRooms: [],
            setSelected: null,
            clearSearch: false
        }),
        methods: {      
            init: async function() {
                this.initialChatRooms = await this.getRecentConversation();
                this.chatRooms = this.initialChatRooms;
                this.sortList();
            },
            search: function(search) {
                this.chatRooms = this.initialChatRooms;
                this.sortList();

                if (search == null || search.length < 0) {
                    return false;
                }

                this.chatRooms = this.chatRooms.filter((conversation) => {
                    const me = this;
                    // Récupération du titre
                    const user = conversation.roomInfo.find((info) => {
                        return (info[0]._id != me.getChat.id) ? true : false;
                    });

                    const title = user[0].name + "(" + user[0].shortNum + ")";

                    if (title.toLowerCase().includes(search.toLowerCase())) {
                        return true;
                    }

                    return false;
                })

                this.sortList();
            }, 
            addRoom: function(room) {
                // Clear la recherche
                this.clearSearch = new Date().getTime();

                // Ne pas ajouter si existe déjà
                if (!this.initialChatRooms.find(_room => _room._id == room._id)) {
                    this.initialChatRooms.push(room);
                }

                this.chatRooms = this.initialChatRooms;
                
                this.sortList();
            },
            selectConversation: function(conversation) {
                this.setSelected = conversation;
                this.$emit('selectConversation', conversation);
            },
            getRecentConversation: async function() {
                return new Promise((resolve, reject) => {
                    try {
                        this.$http.get(this.$env.chat.url + "room/", { headers: { 'Authorization': "Bearer " + this.getChat.authorization }})
                        .then(async (response) => {
                            if (response.data.success) {
                                resolve(response.data.conversation);
                            }
                            else {
                                throw Error();
                            }
                        }).catch((err) => {
                            this.$store.commit('app/setError', {
                                code: "0006",
                                message: "getRecentConversation " + (err ? err.name : "")
                            });
                            console.error(err);
                        });
                    } catch (error) {
                        reject(false);
                    }
                });
            },
            sortList: function() {
                this.chatRooms.sort((messageA, messageB) => {
                    const dateA = new Date(messageA.createdAt).getTime();
                    const dateB = new Date(messageB.createdAt).getTime();
                    
                    if (dateA == dateB) { return 0; }
                    else if (dateA > dateB) { return -1; }
                    else { return 1; }
                });
            }
        },
        mounted: async function() {
            this.init();
        }
    }
</script>