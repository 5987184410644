<template>
    <v-toolbar class="send_toolbar" flat bottom style="flex:0;" height="inherit">
        <v-textarea
            v-model="messageText"
            dense
            flat
            hide-details
            rounded
            solo
            placeholder="Aa"
            rows="1"
            auto-grow
            @keyup.enter.exact="send"
        ></v-textarea>

        <v-btn icon @click="send">
            <v-icon>mdi-send</v-icon>
        </v-btn>

        <v-btn icon>
            <v-icon>mdi-vibrate</v-icon>
        </v-btn>
    </v-toolbar>
</template>

<script>
    export default {
        name: 'Footer',
        props: {
            
        },
        data: () => ({ 
            messageText: null
        }),
        methods: {
            send: function() {
                this.$emit('sendMessage', this.messageText);
                this.messageText = null;
            }
        },
        mounted: async function() {

        }
    }
</script>