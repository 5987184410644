<template>
    <div class="mx-4">
        <v-stepper v-model="step" vertical>
            <v-stepper-step :complete="step > 1" step="1" color="secondary"> Information </v-stepper-step>

            <v-stepper-content step="1">
                <v-card class="mb-6 py-4 px-4">
                    Vous allez lancer l'import des contacts de votre serveur téléphonique sur votre téléphone.
                    <br>En continuant, nous allons d'abord chercher la liste de vos contacts, vous demandez de confirmer que le nombre de contacts à importer vous semble correct puis lancer l'importation.
                    <br>Cette procédure peut prendre plusieurs minutes. Nous vous demandons de ne pas fermer l'application pendant l'import.
                    <br>Les contacts importez seront "catégoriser" avec le nom de l'entreprise pour pouvoir les supprimer facilement ensuite.
                    <br>Si vous avez des questions, n'hésitez pas à contacter notre support.
                    <v-select
                        v-model="getContactFromServerType"
                        :items="directoryType"
                        class="mt-2"
                        item-text="text"
                        item-value="type"
                        label="Choisir le répertoire"
                    ></v-select>
                </v-card>
                <v-btn color="blue" :disabled="getContactFromServerType == null" @click="step = 2; getContactFromServer()">
                    Commencer l'import
                </v-btn>
            </v-stepper-content>

            <v-stepper-step :complete="step > 2" step="2" color="secondary" >
                Récupération depuis le serveur
            </v-stepper-step>

            <v-stepper-content step="2">
                <v-card class="mb-6 py-4 px-4">
                    Nous récupérons les contacts depuis votre serveur téléphonique.
                    <br>Veuillez patienter...
                    <v-card-text v-html="getContactFromServerLogs"></v-card-text>
                </v-card>
                <v-btn :disabled="!getContactFromServerOk" color="blue" @click="step = 3 ; importToPhone()">
                    Importer
                </v-btn>
                <v-btn text @click="init() ; step = 1">
                    Annuler
                </v-btn>
            </v-stepper-content>

            <v-stepper-step :complete="step > 3" step="3" color="secondary" >
                Import sur le téléphone
            </v-stepper-step>

            <v-stepper-content step="3">
                <v-card class="mb-6 py-4 px-4">
                    Nous importons les contacts dans votre téléphone.
                    <br>Veuillez patienter...
                    <v-card-text v-html="importContactLogs"></v-card-text>
                </v-card>
                <v-btn :disabled="!importContactOk" color="green" @click="init() ; step = 1">
                    Terminer
                </v-btn>
            </v-stepper-content>
        </v-stepper>
        <v-dialog v-model="doublon" persistent>
            <v-card>
                <v-card-title class="text-h5">
                    Il y a un doublon
                </v-card-title>

                <v-card-text>
                    Attention, vous allez importer un contact qui semble déjà exister dans votre répertoire.
                    Serveur : {{ getDoublonServerTxt() }}
                    Téléphone : {{ getDoublonInternalTxt() }}
                </v-card-text>

                <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                    color="red"
                    @click="refuseDoublon"
                >
                    Refuser
                </v-btn>

                <v-btn
                    color="green"
                    @click="acceptDoublon"
                >
                    Accepter
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>

import parsePhoneNumber from 'libphonenumber-js'

import { mapGetters } from 'vuex'

export default {
  name: 'ImportContact',

  computed: {
    ...mapGetters({
      getUser: 'app/getUser',
    })
  },

  data: function() {
    return {
        step: 1,
        directoryType: [
            { text: 'Tous', type: 'all'},
            { text: 'Siège', type: 'hQ'},
            { text: 'Société', type: 'company'},
            { text: 'Département', type: 'subsidiary'},
            { text: 'Personnel', type: 'personal'},
            { text: 'N° abrégés', type: 'shared'}
        ],
        getContactFromServerType: null,
        getContactFromServerLogs: "",
        getContactFromServerPage: 1,
        getContactFromServerItemsPerPage: 10,
        getContactFromServerOk: false,
        contacts: [],
        importContactLogs: "",
        importContactOk: false,
        doublon: false,
        doublonData: null
    }
  },
  methods: {
    init: function() {
        this.contacts = [];
        this.getContactFromServerLogs = null;
        this.getContactFromServerPage = 1;
        this.getContactFromServerOk = false;
        this.importContactLogs = null;
        this.importContactOk = false;
    },
    getPermissions: async function() {
        return new Promise((resolve, reject) => {
            window.ContactsX.requestWritePermission(function(success) {
                resolve(success);
            }, function (error) {
                console.error(error);
                reject(error);
            });
        });
    },
    getContactFromServer: async function() {        
        // Il me faut la première req pour avoir le total
        const totalContacts = parseInt(await this.getTotalContact());
        
        // Nombre de page
        const pageNumbers = Math.ceil(totalContacts/this.getContactFromServerItemsPerPage <= 1 ? 1 : totalContacts/this.getContactFromServerItemsPerPage);

        this.getContactFromServerLogs = "Nombre de page : " + pageNumbers;

        for (let index = 1; index <= pageNumbers; index++) {
            this.getContactFromServerLogs += "<br/>Récupération de la page numéro " + this.getContactFromServerPage + "/" + pageNumbers;
            await this.getContact();
            this.getContactFromServerPage++;
        }
        console.log(this.contacts);
        this.getContactFromServerLogs += "<br/>Vous allez importer " + this.contacts.length + " contact(s), êtes vous sur ?";
        this.getContactFromServerOk = true;
    },
    getTotalContact: async function() {
        return new Promise((resolve, reject) => {
            this.$store.dispatch('directory/getContact', {
                type: this.getContactFromServerType,
                page: 1,
                itemsPerPage: 1,
                search: null
            }).then((data) => {
                resolve(data.data.totalContacts);
            }).catch((err) => {
                console.error(err);
                reject(err);
            });
        });
    },
    getContact: async function() {
        return new Promise((resolve, reject) => {
            this.$store.dispatch('directory/getContact', {
                type: this.getContactFromServerType,
                page: this.getContactFromServerPage,
                itemsPerPage: this.getContactFromServerItemsPerPage,
                search: null
            }).then((data) => {
                this.contacts.push(...data.data.items);
                resolve(true);
            }).catch((err) => {
                console.error(err);
                reject(err);
            });
        });
    },
    getMobileContact: async function() {
        return new Promise((resolve, reject) => {
            try {
                window.ContactsX.find(function(contacts) {
                    resolve(contacts);
                }, function (error) {
                    reject(error);
                }, {
                    fields: {
                        firstName: true,
                        middleName: true,
                        familyName: true,
                        phoneNumbers: true,
                        emails: true
                    }
                });
            } catch (error) {
                reject(error);
            }
        });
    },
    importToPhone: async function() {
        // Demande de droit
        await this.getPermissions();

        // Récupération des contacts du téléphone pour les doublons
        const mobile = await this.getMobileContact();
        
        this.import(this.contacts, 0, this.contacts.length, { mobile, passed: false});
    },
    import: async function(list, index, iMax, payload) {
        const { mobile, passed } = payload;

        if (index >= iMax) {
            this.importContactOk = true;
            return;
        }

        if (passed) {
            index += 1;
            this.import(this.contacts, index, iMax, { mobile, passed: false});
            return;
        }

        const contact = list[index];

        try {
            // Le contact existe déjà ?
            const exist = mobile.find(el => {
                return el.firstName == this.getContactTitle(contact) && el.organizationName == ("Occicom - " + this.getContactFromServerType)
            });

            if (exist) {
                // update
                await this.saveContact(contact, exist);
                this.importContactLogs += "<br/>MAJ de " + this.getContactTitle(contact) + " ok.";

                index += 1;
                this.import(this.contacts, index, iMax, { mobile, passed: false});
            }
            else {
                // Si le contact n'existe pas en tag Occicom, il existe peux être en tag normal, il faut rechercher avec déjà le même num
                let existPerso = mobile.find(el => {
                    return el.phoneNumbers.find(num => [contact.home, contact.mob, contact.otherTel, contact.tel].includes(num.value))
                });
                
                if (!existPerso) {
                    existPerso = mobile.find(el => {
                        return this.numberExist(el.phoneNumbers, [contact.home, contact.mob, contact.otherTel, contact.tel]) 
                    });
                }

                if (existPerso) {
                    // print une popup pour être sur que le doublon est voulu
                    this.doublonData = {};
                    this.doublonData.list = list;
                    this.doublonData.index = index;
                    this.doublonData.iMax = iMax;
                    this.doublonData.payload = payload;
                    this.doublonData.contact = contact;
                    this.doublonData.exist = existPerso;

                    this.doublon = true;
                }
                else {
                    // create
                    await this.saveContact(contact);
                    this.importContactLogs += "<br/>Création de " + this.getContactTitle(contact) + " ok.";
                    index += 1;
                    this.import(this.contacts, index, iMax, { mobile, passed: false});
                }
            }
        } catch (error) {
            this.importContactLogs += "<br/>Erreur lors de la création/MAJ de " + this.getContactTitle(contact);
            this.importContactLogs += "<br/>DEBUG : " + error.toString();
            console.error(error);
            index += 1;
            this.import(this.contacts, index, iMax, { mobile, ignore: false});
        }
    },
    numberExist: function(number, array) {
        number = Array.isArray(number) ? number : [number];
        
        for (let index = 0; index < number.length; index++) {
            const element = number[index];

            if (element && element.value) {
                const parsedNumber = parsePhoneNumber(element.value);

                if (parsedNumber) {
                    const home = parsePhoneNumber(array[0], "FR");

                    if (home) {
                        if(home.number == parsedNumber.number) {
                            return true;
                        }
                    }

                    const mob = parsePhoneNumber(array[1], "FR");

                    if (mob) {
                        if(mob.number == parsedNumber.number) {
                            return true;
                        }
                    }

                    const otherTel = parsePhoneNumber(array[2], "FR");

                    if (otherTel) {
                        if(otherTel.number == parsedNumber.number) {
                            return true;
                        }
                    }

                    const tel = parsePhoneNumber(array[3], "FR");

                    if (tel) {
                        if(tel.number == parsedNumber.number) {
                            return true;
                        }
                    }
                }
            }
        }

        return false;
    },
    saveContact: async function(contact, exist) {
        return new Promise((resolve, reject) => {
            const phoneNumbers = [];

            if (contact.tel != "" && contact.tel != null) {
                phoneNumbers.push({
                    type: "other",
                    value: contact.tel
                })
            }

            if (contact.home != "" && contact.home != null) {
                phoneNumbers.push({
                    type: "home",
                    value: contact.home
                })
            }

            if (contact.mob != "" && contact.mob != null) {
                phoneNumbers.push({
                    type: "mobile",
                    value: contact.mob
                })
            }

            if (contact.otherTel != "" && contact.otherTel != null) {
                phoneNumbers.push({
                    type: "work",
                    value: contact.otherTel
                })
            }

            const newContact = {
                firstName: this.getContactTitle(contact),
                familyName: "",
                organizationName: ("Occicom - " + this.getContactFromServerType),
                phoneNumbers: phoneNumbers,
            };

            if (exist && exist.id) {
                newContact.id = exist.id;
                newContact.rawId = exist.id;
            }

            window.ContactsX.save(newContact,
                function(success) {
                    resolve(success);
                },
                function (error) {
                    reject(error);
                }
            );
        });
    },
    getContactTitle: function(contact) {
        let title = "";

        if (contact && contact.name) { title += contact.name }
        if (contact && contact.surname) { title += " " + contact.surname }
        if (contact && contact.company) { title += " " + contact.company }

        return title;
    },
    getDoublonServerTxt: function() {
        if (this.doublonData && this.doublonData.contact) {
            return `${this.getContactTitle(this.doublonData.contact)} (${this.doublonData.contact.home} ${this.doublonData.contact.mob} ${this.doublonData.contact.otherTel})`
        }

        return "";
    },
    getDoublonInternalTxt: function() {
        let txt = "";

        if (this.doublonData && this.doublonData.exist) {
            txt += `${this.doublonData.exist.displayName} `;

            if (this.doublonData.exist.phoneNumbers) {
                this.doublonData.exist.phoneNumbers.forEach(element => {
                    txt += element.value + " ";
                });
            }
        }

        return txt;
    },
    refuseDoublon: function() {
        const payload = this.doublonData.payload;
        payload.passed = true;

        this.doublon = false;

        this.import(this.doublonData.list, this.doublonData.index, this.doublonData.iMax, payload);
    },
    acceptDoublon: async function() {
        const payload = this.doublonData.payload;
        payload.passed = false;
        
        let index = this.doublonData.index;
        index += 1;

        await this.saveContact(this.doublonData.contact);
        this.importContactLogs += "<br/>Création de " + this.doublonData.contact.name + " ok.";

        this.doublon = false;
        
        this.import(this.doublonData.list, index, this.doublonData.iMax, payload);
    }
  }
}
</script>
<style>
</style>