<template>
    <v-btn v-if="isElectron" icon :color="$vuetify.theme.dark ? $env.custom.appBarBtnColor.dark : $env.custom.appBarBtnColor.light" @click="onClick">
        <v-icon>mdi-window-minimize</v-icon>
    </v-btn>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: "Minimize_Button",

  computed: {
      ...mapGetters({
          isElectron: 'app/isElectron'
      })
  },

  data: function() {
    return {
        
    }
  },
  methods: {
      onClick() {
        window.ipcRenderer.send('minimize_event');
      }      
  },
  mounted: async function() {
      
  }
};
</script>