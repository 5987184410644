<template>
    <div>
        <v-radio-group v-model="radioGroup" hide-details @change="radioChanged">
            <v-radio
                label="Vers la boite vocale"
                :value="0"
            ></v-radio>
            <v-radio
                label="Numéro externe"
                :value="1"
            ></v-radio>
            <div v-if="radioGroup == 1">
                <v-text-field
                    v-model="externalNum"
                    name="tel"
                    autocomplete="on"
                    type="tel"
                    filled
                    rounded
                    dense
                    hide-details
                    class="mt-1 mb-2"
                    placeholder="+33 x xx xx xx xx"
                    @keyup="externalNumChanged"
                ></v-text-field>
            </div>
            <v-radio
                label="Numéro interne"
                :value="2"
            ></v-radio>
            <div v-if="radioGroup == 2">
                <v-autocomplete
                    v-model="internalNum"
                    :items="internalList"
                    class="pl-2"
                    item-text="all"
                    item-value="sipExtension"
                    label="Numéro interne"
                    @change="internalNumChanged"
                >
                <template v-slot:item="data">
                    <template>
                    <v-list-item-content>
                        <v-list-item-title v-html="data.item.extName"></v-list-item-title>
                        <v-list-item-subtitle>{{ data.item.shortNum + " - " + data.item.company}}</v-list-item-subtitle>
                    </v-list-item-content>
                    </template>
                </template>
                </v-autocomplete>
            </div>
        </v-radio-group>
    </div>
</template>

<script>

export default {
    name: 'RadioNumber',

    props: {
        internalList: {
            type: Array,
            default: () => { 
                return [];
            }
        },
        default: null,
        defaultRadio: { default: 0 },
        defaultInternal: { default: false },
        defaultNum: { default: null}
    },

    mounted() {
        if (this.defaultInternal) {
            this.radioGroup = 2;
            this.internalNum = this.defaultNum;
        }
        else if (this.defaultRadio) {
            this.radioGroup = 1;
            this.externalNum = this.defaultNum;
        }
        else {
            this.radioGroup = 0;
        }
    },

    data: function() {
        return {
            radioGroup: 0,
            externalNum: null,
            internalNum: null,
        }
    },
    methods: {
        radioChanged: function() {
            this.$emit("onRadioChanged", this.radioGroup);
            
            switch (this.radioGroup) {
                case 1:
                    this.externalNumChanged();
                    break;
                case 2:
                    this.internalNumChanged();
                    break;
            
                default:
                    break;
            }
        },
        externalNumChanged: function() {
            this.$emit("onExternalNumChanged", this.externalNum);
        },
        internalNumChanged: function() {
            console.log(this.internalNum);
            this.$emit("onInternalNumChanged", this.internalNum);
        }
    }
}
</script>
<style>

</style>