<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon :color="$vuetify.theme.dark ? $env.custom.appBarBtnColor.dark : $env.custom.appBarBtnColor.light" @click="onClick" v-bind="attrs" v-on="on">
        <v-badge
          :content="newMessageCounter.toString()"
          :value="newMessageCounter"
          :color="newMessageCounter > 0 ? 'green': 'red'"
          overlap
        >
          <v-icon>mdi-chat-processing</v-icon>
        </v-badge>
      </v-btn>
    </template>
    <span>Tchat</span>
  </v-tooltip>
</template>

<script>

export default {
  name: "NewMessageButton",

  components: {  },

  props: {
    newMessageCounter: {
      default: 0
    }
  },

  data: function() {
    return {
        
    }
  },
  methods: {
    onClick() {
      this.$router.push({ path: 'chat', query: { new: true } });
    }
  },
  mounted: async function() {
      
  }
};
</script>