<template>
    <div>
        <!-- Affichage de la date -->
        <div style="text-align: center; font-size: smaller;">{{ getReadableDate() }}</div>
        <div style="display: flex;" class="message_container">
            <div v-if="message.postedByUser._id === getChat.id" style="flex:1;"></div>
            <div class="message mx-4" :class="message.postedByUser._id === getChat.id ? 'own' : ''">
                <!--<div class="username" v-if="message.postedByUser._id != previous.postedByUser._id">{{ message.postedByUser.name + "(" + message.postedByUser.shortNum + ")" }}</div>-->
                <div style="margin-top: 5px"></div>
                <div class="content px-4 py-2">
                    <div v-html="message.message.messageText.replace(/\n/g, '<br />')"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    export default {
        name: 'Message',
        props: {
            message: {
                type: Object,
                default: () => { 
                    return {
                        _id:"",
                        chatRoomId:"",
                        createdAt:"",
                        message: {
                            messageText: ""
                        },
                        postedByUser: {
                            _id:"",
                            createdAt:"",
                            email:"",
                            favorite:false,
                            name:"",
                            picture:"",
                            shortNum:"",
                            updatedAt:"",
                            user_id:""
                        },
                        readByRecipients: [],
                        type:"text",
                        updatedAt:""
                    }
                }
            },
            previous: {
                type: Object,
                default: () => { 
                    return {
                        createdAt:"",
                        postedByUser: {
                            _id:"",
                            name:"",
                            shortNum:"",
                            user_id:""
                        }
                    }
                }
            },
        },
        computed: {
            ...mapGetters({
                getChat: 'app/getChat'
            })
        },
        data: () => ({
        }),
        methods: {
            getReadableDate: function() {
                const messageDate = new Date(this.message.createdAt);
                // Si il y a plus de 2 minutes de diff entre 2 message DE LA MEME PERSONNE, il faut afficher la date
                if (this.message.postedByUser._id === this.previous.postedByUser._id) {
                    const previous = new Date(this.previous.createdAt);
                    
                    var diffMs = (messageDate - previous);
                    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);
                    
                    if (diffMins < 2) {
                        return;
                    }
                }
                
                return messageDate.toLocaleDateString() + " à " + messageDate.toLocaleTimeString().replace(':', "h").split(':')[0]
            }
        },
        mounted: async function() {
            this.$emit('loaded');
        }
    }
</script>
<style scoped>
    .message {
        max-width: 80%;
    }
    .content {
        background-color: var(--v-chatContent-base);
        border-radius: 20px;
        width: fit-content;
    }
    .own .content {
        background-color: var(--v-chatContentOwn-base) !important;
        margin-left: auto;
    }
</style>