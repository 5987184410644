<template>
    <v-toolbar flat style="flex:0;">
        <v-btn v-if="isMobile" fab outlined small color="primary" class="mr-3" @click="onMenuClick">
            <v-icon>mdi-menu</v-icon>
        </v-btn>

        <v-toolbar-title>
            @ {{ title }}
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon @click="onCallClick">
            <v-icon>mdi-phone</v-icon>
        </v-btn>

        <v-btn icon disabled>
            <v-icon>mdi-information</v-icon>
        </v-btn>
    </v-toolbar>
</template>

<script>
    export default {
        name: 'Toolbar',
        props: {
            title: {
                type: String
            },
            user: {
                default: null
            },
            isMobile: {
                default: false
            }
        },
        data: () => ({
            
        }),
        methods: {
            onCallClick: function() {
                this.$emit("call", this.user);
            },
            onMenuClick: function() {
                this.$emit("showMobileMenu");
            }
        },
        mounted: async function() {

        }
    }
</script>