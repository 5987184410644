<template>
    <v-dialog
        v-model="show"
        persistent
        width="500"
    >
        <v-card>
            <v-card-title class="headline">
                Enregistrer votre annonce vocal
            </v-card-title>
            <div class="center-x w-100 my-2">
                <v-btn
                    :loading="recording"
                    :disabled="recording"
                    class="ma-2"
                    :class="$env.custom.societyBtnClass"
                    outlined
                    large
                    fab
                    @click="start"
                >
                    <v-icon dark>
                        mdi-microphone 
                    </v-icon>
                </v-btn>
                <v-btn
                    :disabled="!recording"
                    class="ma-2"
                    :class="$env.custom.societyBtnClass"
                    outlined
                    large
                    fab
                    @click="stop"
                >
                    <v-icon dark>
                        mdi-stop-circle
                    </v-icon>
                </v-btn>
            </div>

            <div class="center-x mt-2 mb-4" :class="recordingSource != null ? '' : 'd-none'">
                <audio id="player" controls></audio>
            </div>
            <div class="center-x px-4" :class="recordingSource != null ? '' : 'd-none'">
                <v-text-field
                    v-model="description"
                    label="Description"
                    placeholder="Mon annonce vocale"
                    outlined
                ></v-text-field>
            </div>

            <v-divider></v-divider>

            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                :disabled="recording"
                color="error"
                text
                @click="close"
            >
                Annuler
            </v-btn>
            <v-btn
                :disabled="recording || recordingSource == null"
                color="primary"
                text
                @click="save"
            >
                Sauvegarder
            </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { MediaRecorder, register } from 'extendable-media-recorder';
import { connect } from 'extendable-media-recorder-wav-encoder';

export default {
    name: 'Recorder',
    props: {
        show: {
            default: false
        }
    },
    data: function() {
        return {
            recorder: null,
            recorderStream: null,
            recording: false,
            recordingSource: null,
            recordingSourceAsRAW: null,
            description: null,
            worker: null
        }
    },
    methods: {
        start: async function() {
            const me = this;
            this.recording = true;
            this.recorder = null;
            this.recorderStream = null;
            this.recordingSource = null;
            this.recordingSourceAsRAW = null;

            this.recorderStream = await navigator.mediaDevices.getUserMedia({ audio: true });
            
            this.recorder = new MediaRecorder(this.recorderStream, { mimeType: 'audio/wav', audioBitsPerSecond: 12800 });

            this.recorder.ondataavailable = (e) => {
                me.recordingSourceAsRAW = e.data;
                me.recordingSource = URL.createObjectURL(me.recordingSourceAsRAW);
                document.getElementById("player").src = me.recordingSource;
            };
            
            this.recorder.start();
        },
        stop: function() {
            this.recorder.stop();
            this.recorderStream.getAudioTracks()[0].stop();

            this.recording = false;
        },
        close: function() {
            this.reset();
            this.$emit("close");
        },
        reset: function() {
            this.recorder = null;
            this.recorderStream = null;
            this.recordingSource = null;
        },
        save: function() {
            this.$emit('save', this.recordingSourceAsRAW, this.description);
            this.reset();
        },
        onGreetingsClick: function(greetings) {
            console.log(greetings);
        }
    },
    mounted: async function() {
        await register(await connect());
    },
    destroyed: function() {

    }
}
</script>