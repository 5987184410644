<template>
    <v-card
      class="mx-auto"
      :class="mini ? 'pt-3 mb-4' : 'mt-4'"
      :style="mini ? 'box-shadow: unset;' : ''"
      max-width="400"
    >
      <div v-if="!mini" class="pt-4">
        <v-img
          class="mx-auto"
          width="200"
          src="@/assets/logo_square.png"
        >
        </v-img>
      </div>
      <v-card-title v-if="!mini">Bonjour {{ getUser.info.name }}</v-card-title>
      <v-card-subtitle v-if="!mini">{{ getUser.info.accessLevelDescription }}</v-card-subtitle>

      <v-card-text>
        <p v-if="noSIPSelected" style="color:red;">Veuillez sélectionner une ligne.</p>
        <v-select
          class="pt-0 mt-0"
          v-model="SIPAccount"
          :items="SIPAccounts"
          item-value="shortNum"
          label="Votre ligne :"
          return-object
          hide-details
          @change="onSIPAccountChanged"
        >
          <template slot="selection" slot-scope="data">
            {{ data.item.shortNum }} - {{ data.item.extName }} ({{ data.item.didNumbers[0] }})
          </template>
          <template slot="item" slot-scope="data">
            {{ data.item.shortNum }} - {{ data.item.extName }} ({{ data.item.didNumbers[0] }})
          </template>
        </v-select>
        <div>
          <v-select
            class="mt-4"
            v-model="didNumber"
            :items="didNumbers"
            return-object
            label="Numéro présenté :"
            hide-details
            :value-comparator="findDidNumbers"
            @change="onDidNumbersChanged"
          ></v-select>
          <p class="mb-0">{{ getUserLastDidNumberChanged ? getUserLastDidNumberChanged.toLocaleString() : null }}</p>
          <v-switch
            class="mt-0"
            v-model="didNumberPrivacy"
            label="Numéro privé"
            color="success"
            hide-details
          ></v-switch>
        </div>

        <v-text-field
          v-if="!mini"
          v-model="phoneNumber"
          :class="mini ? '' : 'mt-4'"
          label="Lancer un appel sur le numéro de téléphone"
          outlined
          dense
          hide-details
          @input="onPhoneNumberChanged"
          v-on:keyup.native.enter="call"
        ></v-text-field>
        <i class="center-x text-center" v-if="!isCordova">Vous pouvez également selectionner le numéro puis taper sur F6 sur votre clavier pour lancer l'appel</i>
        <v-switch
          v-if="isCordova"
          class="mt-0"
          v-model="convergence"
          label="Utiliser la convergence"
          color="success"
          hide-details
          @change="setConvergenceState"
        ></v-switch>
        <v-row class="center-x mt-4" v-if="!mini">
          <v-btn
            class="ma-2 mt-4"
            :class="$env.custom.societyBtnClass"
            outlined
            large
            fab
            @click="call"
          >
            <v-icon>mdi-phone</v-icon>
          </v-btn>
        </v-row>
      </v-card-text>
    </v-card>
</template>

<script>
import { EventBus } from '@/event-bus';
import { mapGetters } from 'vuex'

import Log from '@/services/log.services.js';

import parsePhoneNumber from 'libphonenumber-js'

/* global chrome, browser */
export default {
  name: 'Dialer',
  props: {
    mini: {
      default: false
    },
    callTo: String // Props pour passer un event call
  },
  computed: {
    ...mapGetters({
      getUser: 'app/getUser',
      getUserLastDidNumberChanged: 'app/getUserLastDidNumberChanged',
      getUserExtension: 'app/getUserExtension',
      getCompanySDANumber: 'app/getCompanySDANumber',
      isCordova: 'app/isCordova',
      isConverged: 'app/isConverged'
    }),
    didNumberPrivacy: {
      // getter
      get: function () {
        return this.getUser.preference.extensions ? this.getUser.preference.extensions.privacy : false;
      },
      // setter
      set: function (newValue) {
        this.onDidNumbersPrivacyChanged(newValue);
      }
    }
  },
  data: function() {
    return {
      SIPAccount: null,
      SIPAccounts: [],
      phoneNumber: null,
      finalNumber: null,
      called: false,
      noSIPSelected: false,
      convergence: true,
      didNumbers: null,
      didNumber: null,
      isExperimental: localStorage.getItem('experimental') == "true" ? true : false,
    }
  },
  methods: {
    // Stockage du compte SIP dans le localstorage pour qu'il puisse être séléctionné automatiquement lors de la réouverture du plugin
    onSIPAccountChanged: function(SIPAccount) {
      this.noSIPSelected = false;

      this.SIPAccount = SIPAccount;
      
      this.$store.commit('app/setUserPreferenceSIPAccounts', SIPAccount);
      this.$store.commit('app/updateUser');

      const extension = typeof chrome != "undefined" ? chrome : typeof browser != "undefined" ? browser : null;
      if (extension && extension.storage) {
        extension.storage.local.set({sipExtension: SIPAccount.sipExtension}, function() {});
      }

      window.location.reload();
    },
    findDidNumbers: function(a, b) {
      a = typeof a == "object" ? a.value : a;
      b = typeof b == "object" ? b.value : b;
      
      const exist = this.didNumbers.find(el => el.value == a);
      
      if (exist) {
        const transformedValue = parsePhoneNumber(exist.value, "FR").number.replace("+", "");
        const transformedB = parsePhoneNumber(b, "FR").number.replace("+", "");
        if (transformedValue == transformedB) {
          return true;
        }
        else {
          return false;
        }
      }
      else {
        return false;
      }
    },
    onDidNumbersPrivacyChanged: function(privacy) {
      this.onDidNumbersChanged(this.didNumber, privacy);
    },
    onDidNumbersChanged: function(didNumber, privacy) {
      this.globalStore.api.Extension.setExtension(
        this.getUser.preference.SIPAccount.sipExtension,
        (this.isCordova && this.isConverged) ? this.getUser.preference.extensions.secondaryPstnNumber : didNumber.value,
        typeof privacy == "undefined" ? this.getUser.preference.extensions ? this.getUser.preference.extensions.privacy : false : privacy,
        true,
        didNumber.value
      ).then(() => {
        const now = new Date();
        
        this.$store.commit('app/setUserLastDidNumberChanged', now.getTime());
        this.$store.commit('app/updateUser');

        Log.createLog(JSON.parse(localStorage.getItem('user')).id, "didNumberChanged", {
          to: didNumber.value,
          privacy: this.didNumberPrivacy
        });
      })
    },
    // Les deux champs sont liés pour n'en faire qu'un, finalNumber. Donc chaque changement fait changer finalNumber
    onPhoneNumberChanged: function(value) {
      this.finalNumber = value;
    },
    // Demande de call vers le serveur
    call: function() {
      EventBus.$emit('launchCall', this.finalNumber);
    },
    setConvergenceState: function() {
      this.$store.commit('app/setUserPreferenceConvergence', this.convergence);
    },
    initDidNumber: function() {
      const num = this.getUser.preference.extensions ? this.getUser.preference.extensions.mobileAni : null;
      this.didNumber = this.getUser.preference.extensions ? (this.isCordova && this.isConverged) ? num : this.getUser.preference.extensions.secondaryPstnNumber : null;
      
      if (this.didNumber) {
        this.didNumber = {
          text: parsePhoneNumber(this.didNumber, "FR").number,
          value: this.didNumber
        }

        this.didNumbers = [];
        this.SIPAccount.didNumbers.forEach(didNumber => {
          this.didNumbers.push({
            text: parsePhoneNumber(didNumber, "FR").number,
            value: didNumber
          })
        });

        this.didNumbers.push({
          text: parsePhoneNumber(this.getCompanySDANumber, "FR").number,
          value: this.getCompanySDANumber
        });
      }
    }
  },
  watch: {
    'callTo': function(num) {
      this.finalNumber = num;
      this.call();
    },
    getUserExtension: function() {
      this.initDidNumber();
    }
  },
  mounted: async function() {
    // Je dois faire la récupération des postes de l'utilisateur
    this.SIPAccount = this.getUser.preference.SIPAccount;
    this.convergence = this.isConverged;

    this.initDidNumber();

    if (this.getUser.token == null || this.getUser.id == null) {
      EventBus.$emit('logout');

      this.$router.push("/");
      return;
    }

      
    this.globalStore.api.PhoneSettings.getSIPAccounts(this.getUser.id)
      .then((response) => {
        if (response.success) { 
          // Récupération des informations de l'utilisateur
          this.getUser.info = response.user;

          // Récupération des comptes SIP
          this.$store.commit('app/setUserSIPAccounts', response.SIPAccounts);
          this.SIPAccounts = response.SIPAccounts;
        }
        else { 
          throw new Error(); 
        }
      })
      .catch((err) => { 
        this.$store.commit('app/setError', {
          code: "0003",
          message: "getSIPAccounts " + (err ? err.name : "")
        });
      });
  }
}
</script>
<style>
  .v-text-field > .v-input__control > .v-input__slot:after {
    border-color: black !important;
    border-bottom-color: black !important;
  }

  div.v-input__slot > div > input,
  div.v-input__slot > div > label {
    font-size: 14px;
  }
</style>