<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon :color="$vuetify.theme.dark ? $env.custom.appBarBtnColor.dark : $env.custom.appBarBtnColor.light" @click="onClick" v-bind="attrs" v-on="on">
        <v-badge
          :content="getNewMsg"
          :value="parseInt(getNewMsg)"
          :color="parseInt(getNewMsg) > 0 ? 'green': 'red'"
          overlap
        >
          <v-icon>mdi-voicemail</v-icon>
        </v-badge>
      </v-btn>
    </template>
    <span>Messagerie vocale</span>
  </v-tooltip>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: "VoiceMailButton",

  props: {
    newMessageCounter: {
      default: 0
    }
  },
  
  computed: {
    ...mapGetters({
      getNewMsg: 'app/getNewMsg',
    })
  },

  methods: {
    onClick() {
      this.$router.push({ path: 'voicemail', query: { new: true } });
    }
  }
};
</script>