<template>
  <v-app-bar 
    app
    dense
    :color="$vuetify.theme.dark ? $env.custom.appBarColor.dark : $env.custom.appBarColor.light"
    style="-webkit-app-region: drag"
  >
    <v-app-bar-nav-icon v-if="!isCordova" :color="$vuetify.theme.dark ? $env.custom.appBarBtnColor.dark : $env.custom.appBarBtnColor.light" @click="$store.commit('app/setMenu', !getMenu)"></v-app-bar-nav-icon>
    <HomeButton />
    <NewMessageButton v-if="isChatEnabled" :newMessageCounter="newMessageCounter" />
    <VoiceMailButton />
    <CallForwardButton />
    <LogButton />
    <DirectoryButton />

    <v-toolbar-title></v-toolbar-title>
    <v-spacer></v-spacer>
    <v-app-bar-nav-icon v-if="isCordova" :color="$vuetify.theme.dark ? $env.custom.appBarBtnColor.dark : $env.custom.appBarBtnColor.light" @click="$store.commit('app/setMenu', !getMenu)"></v-app-bar-nav-icon>
    <ElectronMinimizeButton />
    <ElectronMaximizeButton />
    <ElectronCloseButton />
  </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex'

import HomeButton from './AppToolbar/HomeButton';
import NewMessageButton from './AppToolbar/NewMessageButton';
import VoiceMailButton from './AppToolbar/VoiceMailButton';
import CallForwardButton from './AppToolbar/CallForwardButton';
import LogButton from './AppToolbar/LogButton';
import DirectoryButton from './AppToolbar/DirectoryButton';
import ElectronMinimizeButton from '@/components/Electron/Minimize_Button'
import ElectronMaximizeButton from '@/components/Electron/Maximize_Button'
import ElectronCloseButton from '@/components/Electron/Close_Button'

export default {
  name: "AppToolbar",

  components: { 
    HomeButton, NewMessageButton, VoiceMailButton, CallForwardButton, LogButton, DirectoryButton,
    ElectronMinimizeButton, ElectronMaximizeButton, ElectronCloseButton
  },

  props: {
    newMessageCounter: {
      default: 0
    },
    newVoiceMailCounter: {
      default: 0
    }
  },

  computed: {
      ...mapGetters({
          isChatEnabled: 'app/isChatEnabled',
          isCordova: 'app/isCordova',
          getMenu: 'app/getMenu'
      })
  },
};
</script>