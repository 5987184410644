<template>
    <div class="flex-container mx-4 py-4">
        <ConversationList :style="'height:' + getChatHeight() + 'px;'"
            class=" flex-conversationList" 
            :class="(isMobile && !mobileMenuShowed) ? 'd-none' : ''"
            :newMessage="newMessage"
            :urlId="urlId"
            @selectConversation="selectConversation" />
        <Conversation :style="'height:' + getChatHeight() + 'px;'"
            class=" flex-conversation ml-4"
            :isMobile="isMobile"
            :selectedConversation="selectedConversation"
            :newIncMessage="newIncMessage"
            @newMessage="onNewMessagePosted"
            @showMobileMenu="toggleMobileMenu" />
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import Conversation from './components/Conversation.vue';
    import ConversationList from './components/ConversationList.vue';

    import { EventBus } from '@/event-bus';

    export default {
        name: "Chat",
        components: { ConversationList, Conversation },
        data: () => ({
            selectedConversation: null,
            newMessage: null,
            newIncMessage: null,
            urlId: null,
            mobileMenuShowed: false
        }),
        computed: {
            ...mapGetters({
                getChatSocketConnect: 'app/getChatSocketConnect',
                getChat: 'app/getChat'
            }),
            isMobile: function () {
                return this.$vuetify.breakpoint.width <= 800;
            }
        },
        methods: {
            selectConversation: function(conversation) {
                this.selectedConversation = conversation;
            },
            onNewMessagePosted: function(newMessage) {
                this.newMessage = newMessage;
            },
            onNewMessage: function(newMessage) {
                this.newIncMessage = newMessage;
            },
            getChatHeight: function() {
                return window.innerHeight-(48*2)-32;
            },
            toggleMobileMenu: function() {
                if (this.mobileMenuShowed == false) {
                    this.mobileMenuShowed = true;
                }
                else {
                    this.mobileMenuShowed = false;
                }
            }
        },
        mounted: function() {
            EventBus.$on('new_message', this.onNewMessage);

            // si il y a un ?id= il faut determiner l'utilisateur et afficher son chat si il existe ou le créer
            if (this.$route.query.id) {                
                // Il faut demander a conversationList/Toolbar de faire la récupération/création de l'utilisateur (et un jour le mettre dans une API globalisé surtout..)
                this.urlId = this.$route.query.id;
            }

            EventBus.$emit('chatShowed');
        }
    }
</script>
<style scoped>
    .h100 {
        height: 100%;
    }

    .flex-container {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-align-content: stretch;
        -ms-flex-line-pack: stretch;
        align-content: stretch;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start;
    }

    .flex-conversationList {
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0;
        -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
        -webkit-align-self: auto;
        -ms-flex-item-align: auto;
        align-self: auto;
    }

    .flex-conversation {
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0;
        -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        -webkit-align-self: auto;
        -ms-flex-item-align: auto;
        align-self: auto;
    }

    @media screen and (max-width: 1600px) {
        .flex-conversationList {
            -webkit-flex: 0 1 auto;
            -ms-flex: 0 1 auto;
            flex: 0 1 auto;
        }

        .flex-conversation {
            -webkit-flex: 2 1 auto;
            -ms-flex: 2 1 auto;
            flex: 2 1 auto;
        }
    }

    .messages_list {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .send_toolbar {
        height: inherit !important;
    }
</style>