<template>
  <v-card class="mx-auto">
    <v-card-title class="pb-0">
      <v-btn
        icon
        @click="onBackClick()"
      >
        <v-icon color="primary">mdi-chevron-left</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        icon
        @click="onValidateClick()"
      >
        <v-icon color="primary">mdi-account-check</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-title class="center-x pt-0">
      <div class="text-h5">
        Modification de l'utilisateur
      </div>
    </v-card-title>
    <v-list two-line>
      <v-list-item>
        <v-list-item-icon>
          <v-icon color="primary">
            mdi-account
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-text-field
            v-model="user.name"
            label="Nom"
            clearable
            hide-details
          ></v-text-field>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-icon>
          <v-icon color="primary">
            mdi-account
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-text-field
            v-model="user.surname"
            label="Prénom"
            clearable
            hide-details
          ></v-text-field>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-icon>
          <v-icon color="primary">
            mdi-office-building
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-text-field
            v-model="user.company"
            label="Société"
            clearable
            hide-details
          ></v-text-field>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-icon>
          <v-icon color="primary">
            mdi-phone
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-text-field
            v-model="user.tel"
            label="Téléphone"
            clearable
            hide-details
          ></v-text-field>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-icon>
          <v-icon color="primary">
            mdi-phone
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-text-field
            v-model="user.mob"
            label="Mobile"
            clearable
            hide-details
          ></v-text-field>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-icon>
          <v-icon color="primary">
            mdi-phone
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-text-field
            v-model="user.otherTel"
            label="Autres"
            clearable
            hide-details
          ></v-text-field>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-icon>
          <v-icon color="primary">
            mdi-email
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-text-field
            v-model="user.email"
            label="E-Mail"
            clearable
            hide-details
          ></v-text-field>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-icon>
          <v-icon color="primary">
            mdi-information-outline
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-text-field
            v-model="user.fax"
            label="Fax"
            clearable
            hide-details
          ></v-text-field>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-icon>
          <v-icon color="primary">
            mdi-information-outline
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-text-field
            v-model="user.web"
            label="Web"
            clearable
            hide-details
          ></v-text-field>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
export default {
  name: 'DirectoryEditBlock',
  props: {
    user: {
      default: () => { 
        return {
          contactId: "",
          readOnly: "",
          extension: "",
          type: "",
          name: "",
          surname: "",
          company: "",
          email: "",
          web: "",
          url: "",
          tel: "",
          mob: "",
          home: "",
          otherTel: "",
          otherTel2: "",
          fax: ""
        }
      }
    }
  },
  data: function() {
    return {
      
    }
  },
  methods: {
    onBackClick() {
      this.$emit("back");      
    },
    onValidateClick() {
      this.$emit("validate", this.user);
    }
  }
}
</script>