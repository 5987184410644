import Vue from 'vue'
import Vuex from 'vuex'

import contacts from './modules/contacts'
import app from './modules/app'
import directory from './modules/directory'
import dialGroups from './modules/dialGroups'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    app,
    contacts,
    directory,
    dialGroups
  }
});