<template>
    <v-card
        class="mx-auto my-2"
    >
        <v-card-text>
            <p class="text-h5 text--primary mb-0">
                {{ greeting.description }}
            </p>
            <p class="mb-0">{{ new Date(greeting.date + " " + greeting.time).toLocaleString() }}</p>
        </v-card-text>
        <v-card-actions>
            <v-btn
                :class="$env.custom.societyBtnClass"
                outlined
                large
                @click="onListenClick"
            >
                Ecouter
            </v-btn>
            <v-btn
                :color="greeting.active ? 'red' : 'green'"
                outlined
                large
                @click="onActiveClick"
            >
                {{ greeting.active ? 'Desactiver' : 'Activer' }}
            </v-btn>
            <v-btn
                color="red"
                outlined
                large
                @click="onDeleteClick"
            >
                Supprimer
            </v-btn>
        </v-card-actions>
  </v-card>
</template>

<script>
export default {
    name: 'Greeting',
    props: {
        greeting: {
            type: Object,
            default: () => { 
                return {
                    messageId: 0,
                    description: false,
                    active:"",
                    date:"",
                    time:"",
                }
            }
        }
    },
    methods: {
        onListenClick: function() {
            this.$emit('play', this.greeting);
        },
        onActiveClick: function() {
            this.$emit('active', this.greeting);
        },
        onDeleteClick: function() {
            this.$emit('delete', this.greeting);
        }
    }
}
</script>
