<template>
    <v-card class="messages_list" style="height: inherit;">
        <!-- Detail de la room -->
        <Toolbar :title="userName" :user="user" :isMobile="isMobile" @call="onCallClick" @showMobileMenu="showMobileMenu"/>
        
        <v-divider></v-divider>

        <!-- Liste des messages -->
        <MessageList :messageList="messageList" />

        <v-divider></v-divider>

        <!-- -->
        <Footer @sendMessage="sendMessage"/>
    </v-card>
</template>

<script>
    import { mapGetters } from 'vuex'
    import Toolbar from './Conversation/Toolbar.vue'
    import MessageList from "./Conversation/MessageList.vue"
    import Footer from "./Conversation/Footer.vue"
    import { EventBus } from '@/event-bus';

    export default {
        name: 'Conversation',
        components: { Toolbar, MessageList, Footer },
        props: {
            selectedConversation: {
                type: Object,
                default: null
            },
            newIncMessage: null,
            isMobile: {
                default: false
            }
        },
        computed: {
            ...mapGetters({
                getChat: 'app/getChat'
            })
        },
        data: () => ({ 
            messageList: [],
            user: null,
            userName: null
        }),
        watch: { 
            selectedConversation: async function() {
                // Récupération des informations de l'utilisateur
                this.user = this.selectedConversation.roomInfo.find(info => info[0]._id != this.getChat.id);
                this.userName = this.user[0].name + "(" + this.user[0].shortNum + ")";
                
                // Récupération des messages
                this.messageList = await this.getMessages();

                // Sub a la room (room_id et id de l'autre utilisateur pour le join au cas où)
                this.$socket.emit('subscribe', this.selectedConversation._id, this.user[0]._id);
            },
            newIncMessage: function(message) {
                message = message.message;
                
                // Si c'est la même conv que la selected
                if (this.selectedConversation.chatRoomId == message.chatRoomId) {
                    // Vérification que le message existe
                    if (!this.messageList.find(mes => mes.postId == message.postId && mes.message.messageText == message.message.messageText)) {
                        this.messageList.push(message);
                    }
                }
                // Si c'est pas la même conv, dring dring
                else {
                    EventBus.$emit('playChatNotificationSound');
                }
                
                this.$emit('newMessage', message);
            }
        },
        methods: {
            sendMessage: async function(messageText) {
                const message = await this._sendMessage(messageText);
                
                this.messageList.push(message);
                this.$emit('newMessage', message);
            },
            _sendMessage: function(messageText) {
                return new Promise((resolve, reject) => {
                    this.$http.post(this.$env.chat.url + "room/" + this.selectedConversation._id + "/message", {
                        messageText: messageText
                    }, { headers: { 'Authorization': "Bearer " + this.getChat.authorization }})
                    .then(response => {
                        if (response.data.success) {
                            // Ex de post :
                            /**
                                chatRoomId: "a1b45bf55b054c7493cac7b571dac945"
                                chatRoomInfo: (2) [{…}, {…}]
                                createdAt: "2021-05-08T13:03:15.066Z"
                                message: {messageText: "hfghfhgffgh"}
                                postId: "73c2f7d8ad934785a9439b280994a2f5"
                                postedByUser: {_id: "15b2815bf5dc442bab925d9aa77205a6", email: "blanchonenzo@gmail.com", favorite: false, name: "Enzo BLANCHON", picture: "https://extranet.occicom.fr/api/voip/v1/media/default.png", …}
                                readByRecipients: [{…}]
                                type: "text"
                                updatedAt: "2021-05-08T13:03:15.066Z"
                                _id: "a1b45bf55b054c7493cac7b571dac945"
                             */
                            resolve(response.data.post);
                        }
                        else {
                            reject(false);
                        }
                    }).catch((err) => {
                        this.$store.commit('app/setError', {
                            code: "0004",
                            message: "_sendMessage " + (err ? err.name : "")
                        });
                        console.error(err);
                        reject(false);
                    });
                });
            },
            getMessages: function() {
                return new Promise((resolve, reject) => {
                    this.$http.get(this.$env.chat.url + "room/" + this.selectedConversation._id, { headers: { 'Authorization': "Bearer " + this.getChat.authorization }})
                    .then(response => {
                        if (response.data.success) {
                            // Ex de conversation :
                            /**
                                chatRoomId: "a1b45bf55b054c7493cac7b571dac945"
                                createdAt: "2021-05-08T13:03:15.066Z"
                                message: {messageText: "hfghfhgffgh"}
                                postedByUser: {_id: "15b2815bf5dc442bab925d9aa77205a6", email: "blanchonenzo@gmail.com", favorite: false, name: "Enzo BLANCHON", picture: "https://extranet.occicom.fr/api/voip/v1/media/default.png", …}
                                readByRecipients: [{…}]
                                type: "text"
                                updatedAt: "2021-05-08T13:03:15.066Z"
                                __v: 0
                                _id: "73c2f7d8ad934785a9439b280994a2f5"
                             */
                            resolve(response.data.conversation);
                        }
                        else {
                            reject(false);
                        }
                    }).catch((err) => {
                        this.$store.commit('app/setError', {
                            code: "0005",
                            message: "getMessages " + (err ? err.name : "")
                        });
                        console.error(err);
                        reject(false);
                    });
                });
            },
            onCallClick: function(user) {
                if (user && user[0]) {
                    EventBus.$emit('launchCall', user[0].shortNum);
                }
            },
            showMobileMenu: function() {
                this.$emit('showMobileMenu');
            }
        },
        mounted: async function() {
        }
    }
</script>