<template>
    <v-row class="px-5 pb-10">
        <div v-if="greetings.length <= 0">
            <h1 class="ml-4">Vous n'avez aucune annonce vocale</h1>
        </div>
        <v-card class="center-x pt-3" style="width: -webkit-fill-available;" height="100">
            <i v-if="greetings.length >= 5">Vous ne pouvez pas avoir plus de 5 annonces vocales.</i>
            <v-btn
                v-else
                :disabled="greetings.length >= 5"
                class="ma-2"
                :class="$env.custom.societyBtnClass"
                outlined
                large
                fab
                @click="record = true"
            >
                <v-icon dark>
                    mdi-plus
                </v-icon>
            </v-btn>
        </v-card>
        <v-list two-line style="width: -webkit-fill-available; background-color: transparent;">
            <v-list-item-group
                v-model="selected"
            >
                <template v-for="greeting in greetings">
                    <Greeting 
                        :greeting="greeting" 
                        :key="greeting.messageId"
                        @active="onGreetingsActiveClick"
                        @play="onGreetingsPlayClick"
                        @delete="onGreetingsDeleteClick"
                    />
                </template>
            </v-list-item-group>
        </v-list>
            <CordovaRecorder v-if="isCordova" :show="record" @close="record = false" @save="onNewGreetingSave" />
            <Recorder v-else :show="record" @close="record = false" @save="onNewGreetingSave" />
            <Reader :show="read" :url="greetingURL" @close="read = false" />
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex'

import Greeting from './Greeting'
import Reader from './Reader'
import Recorder from './Recorder'
import CordovaRecorder from './CordovaRecorder'

export default {
    name: 'GreetingsList',

    components: { Greeting, Reader, Recorder, CordovaRecorder },

    computed: {
        ...mapGetters({
            isCordova: 'app/isCordova'
        })
    },

    data: function() {
        return {
            greetings: [],
            record: false,
            hover: false,
            greetingBlob: null,
            greetingURL: null,
            read: false,
            selected: null
        }
    },
    methods: {
        onNewGreetingSave: function(blob, description) {
            const me = this;

            this.$store.commit('app/mask');
            
            let formData = new FormData();
            formData.append("sipExtension", this.globalStore.user.preference.SIPAccount.sipExtension);
            formData.append("w3telToken",  this.globalStore.user.token);
            formData.append("wav", blob, new Date().getTime()+".wav");
            formData.append("description", description);

            let headers = { 
                headers: { 
                    'Content-Type': 'multipart/form-data'
                }
            };

            this.$http.post(this.$env.voicemail.url + "wav/", formData, headers).then(response => {
                if (JSON.parse(response.data).success) {
                    me.updateGreetings(false);
                }

                this.record = false;
            }).catch((error) => {
                console.log(error);
            }).finally(() => {
                this.$store.commit('app/unmask');
            });
        },
        onGreetingsActiveClick: function(greeting) {
            this.$store.commit('app/mask');
            try {
                this.globalStore.api.Voicemail.setActiveGreeting(greeting.greetingId)
                .then((response) => {
                    if (response.success) {
                        const updated_greetings = this.greetings.filter((e) => e.greetingId == greeting.greetingId);
                        const nonupdated_greetings = this.greetings.filter((e) => e.greetingId != greeting.greetingId);

                        if (updated_greetings) {
                            updated_greetings.forEach(updated_greeting => {
                                updated_greeting.active = true;
                            });
                        }

                        if (nonupdated_greetings) {
                            nonupdated_greetings.forEach(nonupdated_greeting => {
                                nonupdated_greeting.active = false;
                            });
                        }

                        this.sortGreetings();
                    }
                    else {
                        throw new Error(); 
                    }
                })
                .catch(() => { this.error = true; })
                .finally(() => { this.$store.commit('app/unmask'); });
            } catch (error) {
                this.$store.commit('app/unmask');
                console.error(error);
            }
        },
        onGreetingsPlayClick: function(greeting) {
            this.$store.commit('app/mask');
            try {
                this.globalStore.api.Voicemail.getGreeting(greeting.greetingId)
                .then((response) => {
                    if (response) {
                        this.greetingBlob = response;
                        this.greetingURL = URL.createObjectURL(this.greetingBlob);
                        this.read = true;
                    }
                    else {
                        throw new Error(); 
                    }
                })
                .catch(() => { this.error = true; })
                .finally(() => { this.$store.commit('app/unmask'); });
            } catch (error) {
                this.$store.commit('app/unmask');
                console.error(error);
            }
        },

        onGreetingsDeleteClick: function(greeting) {
            this.$store.commit('app/mask');
            try {
                this.globalStore.api.Voicemail.deleteGreeting(greeting.greetingId)
                .then((response) => {
                    if (response.success) {
                        this.greetings = this.greetings.filter((e) => e.greetingId != greeting.greetingId);
                    }
                    else { 
                        throw new Error(); 
                    }
                })
                .catch(() => { this.error = true; })
                .finally(() => { this.$store.commit('app/unmask'); });
            } catch (error) {
                this.$store.commit('app/unmask');
                console.error(error);
            }
        },

        updateGreetings: async function(mask) {
            try {
                this.globalStore.api.Voicemail.getGreetings(this.globalStore.user.preference.SIPAccount.sipExtension, 0, 1000)
                .then((response) => {
                    if (response.success) {
                        this.greetings = response.greetings;
                        this.sortGreetings();
                    }
                    else { 
                    throw new Error(); 
                    }
                })
                .catch(() => { this.error = true; })
                .finally(() => { if (mask) { this.$store.commit('app/unmask'); } });
            } catch (error) {
                if (mask) {
                    this.$store.commit('app/unmask');
                }
            }
        },

        sortGreetings: function() {
            this.greetings = this.greetings.sort((a, b) => { if (a.active && !b.active) { return -1; } else if (!a.active && b.active) { return 1; } return 0; })
        }
    },
    mounted: async function() {
        this.$store.commit('app/mask');
        
        this.updateGreetings(true);
    }
}
</script>