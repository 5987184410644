/* eslint-disable */
import Axios from 'axios'
import Vue from 'vue'
import env from '../../../config/env';

import Log from '@/services/log.services.js';

// initial state
const state = () => ({
  user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {token: null, id: null},
  chat: localStorage.getItem('chat') ? JSON.parse(localStorage.getItem('chat')) : {authorization: null, id: null},
  mask: false,
  loading: false,
  menu: false,
  error: false,
  chatSocketConnect: false,
  visible: true,
  newMsg: localStorage.getItem('newMsg') ? localStorage.getItem('newMsg') : 0,
  convergence: localStorage.getItem('convergence') ? localStorage.getItem('convergence') == "true" ? true : false : true
});

// getters
const getters = {
    getUser: (state) => {
      return state.user;
    },
    getChat: (state) => {
      return state.chat;
    },
    getChatSocketConnect: (state) => {
      return state.chatSocketConnect;
    },
    getNewMsg: (state) => {
      return state.newMsg;
    },
    isElectron: () => {
      const userAgent = navigator.userAgent.toLowerCase();
      if (userAgent.indexOf(' electron/') > -1) {
        return true;
      }
  
      return false;
    },
    isCordova: () => {
      try {
        return !!window.cordova;
      } catch (error) {
        return false;
      }
    },
    isPlugin: () => {
      try {
        return (window.chrome && chrome.runtime && chrome.runtime.id);
      } catch (error) {
        return false;
      }
    },
    isExperimental: (state, getters) => (onlyMobile) => {
      try {
        if (onlyMobile) {
          // Il faut d'abord savoir si nous sommes sur mobile
          if (getters.isCordova) {
            return localStorage.getItem('experimental') == "true" ? true : false;
          }
          else {
            // Si nous ne sommes pas en mode mobile, on considère que le mode experimental est actif
            return true;
          }
        }
        else {
          return localStorage.getItem('experimental') == "true" ? true : false;
        }
      } catch (error) {
        return false;
      }
    },
    isChatEnabled: (state, getters) => {
      try {
        return localStorage.getItem('chatEnabled') == "true" ? true : false;
      } catch (error) {
        return false;
      }
    },
    isConverged: (state) => {
      return state.convergence
    },
    getMask: (state) => {
      return state.mask;
    },
    getLoading: (state) => {
      return state.loading;
    },
    getError: (state) => {
      return state.error;
    },
    getMenu: (state) => {
      return state.menu;
    },
    getVisible: (state) => {
      return state.visible;
    },
    getUserExtension: (state) => {
      return state.user.preference.extensions ? state.user.preference.extensions.secondaryPstnNumber : null;
    },
    getUserLastDidNumberChanged: (state) => {
      return state.user.preference.lastDidNumberChanged ? new Date(state.user.preference.lastDidNumberChanged) : null;
    },
    getCompanySDANumber(state) {
      return state.user.preference.companySDANumber;
    },
}

// actions
const actions = {
  connectChat({ commit, state }) {        
    return new Promise((resolve, reject) => {
      try {
        Axios.post(env.config.chat.url + "login", { userId: state.user.id, shortNum: state.user.preference.SIPAccount.shortNum, w3telToken: state.user.token}).then(response => {
          if (response.data.success) {
            commit('updateChat', {
              authorization: response.data.authorization,
              id: response.data._id
            });

            resolve({ success: true });
          }
          else {
            throw Error();
          }
        }).catch((error) => {
          reject({ success: false, error: error });
        });
      } catch (error) {
        reject({ success: false, error: error });
      }
    });
  }
}

// mutations
const mutations = {
  updateUser (state, user) {
    if (typeof user == "undefined") {
      localStorage.setItem('user', JSON.stringify(state.user));
    }
    else {
      localStorage.setItem('user', JSON.stringify(user));
    }

    state.user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {token: null, id: null};

    const useOTP = state.user.credential ? state.user.credential.useOTP : false;

    Log.updateUser(state.user.id, state.user.info.email, state.user.info.name, state.user.info.picture, state.user.token, useOTP).catch((err) => {
      // C'est que l'utilisateur n'existe pas, il faut le créer
      if (err.code == 3) {
        Log.createUser(state.user.token, state.user.id, state.user.info.email, useOTP).then(() => {
          Log.updateUser(state.user.id, state.user.info.email, state.user.info.name, state.user.info.picture, state.user.token, useOTP);
        })
      }
    })
  },
  updateChat(state, chat) {
    if (typeof chat == "undefined") {
      localStorage.setItem('chat', JSON.stringify(state.chat));
    }
    else {
      localStorage.setItem('chat', JSON.stringify(chat));
    }
    
    state.chat = localStorage.getItem('chat') ? JSON.parse(localStorage.getItem('chat')) : {authorization: null, id: null};
  },
  setNewMsg(state, newMsg) {
    state.newMsg = newMsg;
  },
  setUserInfo(state, info) {
    state.user.info = info;
  },
  setUserFirebaseInfo(state, info) {
    state.user.firebase = info;
  },
  setUserSIPAccounts(state, SIPAccounts) {
    state.user.SIPAccounts = SIPAccounts;
  },
  setUserPreferenceSIPAccounts(state, SIPAccount) {
    state.user.preference.SIPAccount = SIPAccount;
  },
  setUserExtensions(state, extensions) {
    state.user.preference.extensions = extensions;
  },
  setChatSocketConnect(state, chatSocketConnect) {
    state.chatSocketConnect = chatSocketConnect;
  },
  setUserPreferenceConvergence(state, convergence) {
    state.convergence = convergence;
    localStorage.setItem('convergence', convergence);
  },
  setUserLastDidNumberChanged(state, lastDidNumberChanged) {
    state.user.preference.lastDidNumberChanged = lastDidNumberChanged;
  },
  setCompanySDANumber(state, companySDANumber) {
    state.user.preference.companySDANumber = companySDANumber;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  mask(state) {
    state.mask = true;
  },
  unmask(state) {
    state.mask = false;
  },
  setError(state, error) {
    let text = "";

    if (error && error.message) {
      text += error.message + " - ";
    }

    if (error && error.code) {
      text += "Code : " + error.code + " - ";
    }

    text += "Si le problème persiste, veuillez nous contacter.";

    Vue.notify({
      group: 'error',
      type: 'error',
      title: 'Il y a eu une erreur',
      text: text
    });

    state.error = false;
  },
  setMenu(state, menu) {
    state.menu = menu;
  },
  setVisible(state, visible) {
    state.visible = visible;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}