<template>
  <v-container>
    <v-card
      class="mx-auto mt-4 pt-4"
      max-width="400"
    >
      <v-img
        class="mx-4"
        src="@/assets/logo.png"
      >
      </v-img>
      <v-card-title>Connectez-vous : </v-card-title>

      <v-card-text class="text--primary">
        <v-text-field
          v-model="email"
          outlined
          label="Adresse e-mail"
          prepend-inner-icon="mdi-account"
          hide-details=true
          class="mb-4"
          @keyup="onEmailChanged"
          v-on:keyup.native.enter="login"
        ></v-text-field>
        <v-text-field
          v-model="password"
          type="password"
          outlined
          :label="useOTP ? 'Code OTP' : 'Mot de passe'"
          prepend-inner-icon="mdi-lock"
          hide-details=true
          v-on:keyup.native.enter="login"
        ></v-text-field>
        <v-switch
          class="mt-0 mb-4"
          v-model="useOTP"
          label="Utiliser le code OTP"
          color="success"
          hide-details
        ></v-switch>
        <v-btn outlined block :class="$env.custom.societyBtnClass" @click="login">Connexion</v-btn>
      </v-card-text>
    </v-card>
    <v-card
      class="mx-auto mt-4"
      width="400"
    >
      <v-btn to="/otp" outlined block :class="$env.custom.societyBtnClass">Recevoir le code OTP</v-btn>
    </v-card>
  </v-container>
</template>

<script>
/* global chrome, browser */

import {  } from 'vuex'
import Log from '@/services/log.services.js';

export default {
  name: 'Login',
  components: {  },
  data: function() {
    return {
      email: localStorage.getItem('lastEmail') ? localStorage.getItem('lastEmail') : null,
      password: null,
      mask: false,
      error: false,
      useOTP: false
    }
  },
  methods: {
    onEmailChanged: function() {
      localStorage.setItem('lastEmail', this.email);
    },
    // Demande de token d'authentification pour pouvoir utiliser l'application
    login: function() {
      this.$store.commit('app/mask');

      this.onEmailChanged();

      this.globalStore.api.login(this.email, this.useOTP ? this.password : '', this.useOTP ? '' : this.password)
        .then((response) => {
          if (response && response.success == true) {
            const user = {};
            
            user.token = response.token;

            const parseJwt = (token) => {
              var base64Url = token.split('.')[1];
              var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
              var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
                  return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
              }).join(''));

              return JSON.parse(jsonPayload);
            };

            const JWTData = parseJwt(user.token);
            const user_id = JWTData && JWTData.context ? JWTData.context.user.user_id : 0;
            user.id = user_id;

            this.globalStore.api.API.token = user.token;
            this.globalStore.api.API.user_id = user.id;

            this.globalStore.api.PhoneSettings.getSIPAccounts(user.id)
            .then((response) => {
              if (response.success) {
                // Récupération des informations de l'utilisateur
                this.$store.commit('app/setUserInfo', response.user);                

                user.info = {};
                user.info.name = response.user.name
                user.info.email = response.user.name

                user.preference = {};
                user.preference.SIPAccount = null;
                user.credential = {
                  user: this.email,
                  useOTP: this.useOTP
                };
                
                this.$store.commit('app/updateUser', user);
                
                const extension = typeof chrome != "undefined" ? chrome : typeof browser != "undefined" ? browser : null;

                if (extension && extension.storage) {
                  extension.storage.local.remove("sipExtension");
                  extension.storage.local.set({token: response.w3tel_token}, function() {});
                  extension.storage.local.set({user_id: response.user_id}, function() {});
                  extension.storage.local.set({occicom_token: response.token}, function() {});
                  extension.storage.local.set({occicom_refresh_token: response.refresh_token}, function() {});
                }

                Log.createUser(response.token, user_id, this.email, this.useOTP).finally(() => {
                  Log.createLog(user_id, "login", null).finally(() => {
                    window.location.reload();
                  })
                })
              }
              else { 
                throw new Error(); 
              }
            })
            .catch(() => { this.error = true; });
          }
          else {
            throw new Error();
          }
        })
        .catch((err) => { 
          this.$store.commit('app/setError', {
            code: "0013",
            message: "login " + (err ? err.name : "")
          });
        })
        .finally(() => { this.$store.commit('app/unmask'); });
    }
  }
}
</script>
