<template>
<v-dialog
      v-model="show"
      persistent
      width="500"
    >
        <v-card>
            <div class="center-x pt-4 mb-4">
                <audio ref="player" :src="url" controls></audio>
            </div>

            <v-divider></v-divider>

            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="error"
                text
                @click="close"
            >
                Fermer
            </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'Reader',
    props: {
        show: {
            default: false
        },
        url: {
            default: false
        }
    },
    watch: {
        url: {
            handler: function() {
                const me = this; 
                
                setTimeout(() => {
                    const player = me.$refs.player;
                    player.play();
                    player.onended = function() {
                        me.close();
                    };
                }, 500);
            }
        }
    },
    data: function() {
        return {
            
        }
    },
    methods: {
        close: function() {
            this.$refs.player.pause();
            this.$emit("close");
        }
    },
    mounted: async function() {

    },
    destroyed: function() {

    }
}
</script>