<template>
    <v-card
        class="mx-auto my-2"
        :style="voicemail.msgRead ? '' : 'border: 2px solid #4CAF50;'"
    >
        <v-card-text>
            <div>{{ voicemail.msgRead ? "Lu" : "Non lu" }}</div>
            <p class="text-h5 text--primary mb-0">
                {{ getTitle() }}
            </p>
            <p class="mb-0">{{ getSubtitle() }}</p>
        </v-card-text>
        <v-card-actions>
            <v-btn
                :class="$env.custom.societyBtnClass"
                outlined
                large
                @click="onListenClick"
            >
                Ecouter
            </v-btn>
            <v-btn
                color="green"
                outlined
                large
                @click="onCallbackClick"
            >
                Rappeler
            </v-btn>
            <v-btn
                color="red"
                outlined
                large
                @click="onDeleteClick"
            >
                Supprimer
            </v-btn>
        </v-card-actions>
  </v-card>
</template>

<script>

import { mapGetters } from 'vuex'
import parsePhoneNumber from 'libphonenumber-js'

export default {
    name: 'Voicemail',
    props: {
        voicemail: {
            type: Object,
            default: () => { 
                return {
                    messageId: 0,
                    msgRead: false,
                    fromName:"",
                    From:"",
                    duration:"",
                    date:"",
                    time:"",
                }
            }
        }
    },
    computed: {
        ...mapGetters({
            mobileDirectory: 'directory/getMobileDirectory'
        })
    },
    methods: {
        onListenClick: function() {
            this.$emit('listen', this.voicemail);
        },
        onCallbackClick: function() {
            this.$emit('callback', this.voicemail);
        },
        onDeleteClick: function() {
            this.$emit('delete', this.voicemail);
        },
        getTitle: function() {
            // Recherche de l'utilisateur dans les contacts mobiles
            const exist = this.mobileDirectory.find(contact => {
                // La seule information que nous retourne W3Tel c'est le numéro en From
                // Il va donc falloir faire une recherche sachant que le numéro est soit :
                // 06 xx xx xx xx
                // +336 xx xx xx xx
                // 421
                let number = parsePhoneNumber(this.voicemail.From, "FR");
                
                if (number) {
                    // Transformation des numéros du contact
                    let tel = contact.tel;
                    if (tel) { tel = parsePhoneNumber(tel, "FR") } 

                    let mob = contact.mob;
                    if (mob) { mob =  parsePhoneNumber(mob, "FR") } 

                    let otherTel = contact.otherTel;
                    if (otherTel) { otherTel =  parsePhoneNumber(otherTel, "FR") }

                    if (tel && tel.nationalNumber == number.nationalNumber) {
                        return contact;
                    }

                    if (mob && mob.nationalNumber == number.nationalNumber) {
                        return contact;
                    }

                    if (otherTel && otherTel.nationalNumber == number.nationalNumber) {
                        return contact;
                    }
                }
            });

            if (exist) {
                let showedTel = "";
                if (exist.tel && exist.tel.length > 0) {
                    showedTel = exist.tel;
                }
                else if (exist.mob && exist.mob.length > 0) {
                    showedTel = exist.mob;
                }
                else if (exist.otherTel && exist.otherTel.length > 0) {
                    showedTel = exist.otherTel;
                }

                return "De " + exist.printName + " (" + showedTel + ")";
            }
            else {
                if (this.voicemail.fromName == "" || this.voicemail.fromName.length <= 0) {
                    return "De " + this.voicemail.From;
                }

                return "De " + this.voicemail.fromName + " (" + this.voicemail.From + ")";
            }
        },
        getSubtitle: function() {
            return this.voicemail.duration + " seconde(s), le " + this.voicemail.date + " a " + this.voicemail.time.replace(':', 'h').split(':')[0]; 
        }
    }
}
</script>
