<template>
  <v-navigation-drawer
    v-model="menu"
    :right="isCordova"
    app
    fixed
    temporary
  >
    <v-list>
      <v-list-item class="px-2">
        <v-list-item-avatar>
          <v-img src="https://extranet.occicom.fr/api/voip/v1/media/default.png"></v-img>
        </v-list-item-avatar>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            {{ this.globalStore.user.info.name }}
          </v-list-item-title>
          <v-list-item-subtitle>{{ this.globalStore.user.info.email }}</v-list-item-subtitle>
          <v-list-item-subtitle>{{ this.globalStore.user.info.accessLevelDescription }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    
    <Dialer :class="$vuetify.breakpoint.lgAndUp ? 'd-none' : ''" :mini="true" />

    <v-divider></v-divider>

    <v-list
      nav
      dense
    >
      <v-list-item link to="/home">
        <v-list-item-icon>
          <v-icon>mdi-home</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Accueil</v-list-item-title>
      </v-list-item>

      <v-list-item link v-if="isChatEnabled" to="/chat">
        <v-list-item-icon>
          <v-icon>mdi-chat-processing</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Tchat</v-list-item-title>
      </v-list-item>

      <v-list-item link to="/voicemail">
        <v-list-item-icon>
          <v-icon>mdi-voicemail</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Messagerie vocale</v-list-item-title>
      </v-list-item>

      <v-list-item link to="/callforwards">
        <v-list-item-icon>
          <v-icon>mdi-phone-forward</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Renvoi d'appel</v-list-item-title>
      </v-list-item>

      <v-list-item link to="/log">
        <v-list-item-icon>
          <v-icon>mdi-phone-log</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Journal</v-list-item-title>
      </v-list-item>
      <v-list-item link to="/directory">
        <v-list-item-icon>
          <v-icon>mdi-account-box</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Annuaire</v-list-item-title>
      </v-list-item>
      <v-list-item link v-if="isExperimental && isAdmin" to="/dialgroups">
        <v-list-item-icon>
          <v-icon>mdi-account-group</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Groupe d'appel</v-list-item-title>
      </v-list-item>
      <v-list-item link to="/settings">
        <v-list-item-icon>
          <v-icon>mdi-cog</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Paramètres</v-list-item-title>
      </v-list-item>

      <v-list-item link to="/help">
        <v-list-item-icon>
          <v-icon>mdi-help-circle-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Aide</v-list-item-title>
      </v-list-item>

      <v-divider></v-divider>

      <!--<v-list-item v-if="globalStore.isExperimental()" @click="globalStore.goToExtranet()">
        <v-list-item-icon>
          <v-icon>mdi-home</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Extranet</v-list-item-title>
      </v-list-item>-->

      <v-list-item @click="logout">
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Déconnexion</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { EventBus } from '@/event-bus';
import { mapGetters } from 'vuex'

import Dialer from './Dialer';

export default {
  name: "Navigation",
  components: { Dialer },

  computed: {
      ...mapGetters({
          getMenu: 'app/getMenu',
          getUser: 'app/getUser',
          isChatEnabled: 'app/isChatEnabled',
          isCordova: 'app/isCordova'
      }),
      menu: {
        get() {
          return this.getMenu;
        },
        set(value) {
          this.$store.commit('app/setMenu', value);
        }
      },
      isAdmin: function() {
        return this.getUser.info.accessLevel == 10;
      }
  },
  data: () => ({
    isExperimental: localStorage.getItem('experimental') == "true" ? true : false,
    show: false
  }),
  methods: {
    logout: function() {
      EventBus.$emit('logout');
    },
  }
};
</script>